import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** A country code as defined by ISO 3166-1 alpha-2 */
  CountryCode: { input: string; output: string; }
  CronExpression: { input: any; output: any; }
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: { input: Date | string; output: Date | string; }
  /** An arbitrary-precision Decimal type */
  Decimal: { input: number; output: number; }
  /** A field whose value conforms to the standard internet email address format as specified in HTML Spec: https://html.spec.whatwg.org/multipage/input.html#valid-e-mail-address. */
  EmailAddress: { input: string; output: string; }
  /** A field whose value is a valid decimal degrees latitude number (53.471): https://en.wikipedia.org/wiki/Latitude */
  Latitude: { input: string | number; output: string | number; }
  /** A field whose value is a valid decimal degrees longitude number (53.471): https://en.wikipedia.org/wiki/Longitude */
  Longitude: { input: string | number; output: string | number; }
  /** A field whose value conforms to the standard postal code formats for United States, United Kingdom, Germany, Canada, France, Italy, Australia, Netherlands, Spain, Denmark, Sweden, Belgium, India, Austria, Portugal, Switzerland or Luxembourg. */
  PostalCode: { input: string; output: string; }
  /** A field whose value exists in the standard IANA Time Zone Database: https://www.iana.org/time-zones */
  TimeZone: { input: string; output: string; }
  /** A field whose value is a generic Universally Unique Identifier: https://en.wikipedia.org/wiki/Universally_unique_identifier. */
  UUID: { input: string; output: string; }
  /** The `Upload` scalar type represents a file upload. */
  Upload: { input: File; output: File; }
};

/** Information about this GraphQL API */
export type AboutModel = {
  __typename: 'AboutModel';
  author: Scalars['String']['output'];
  contact: Scalars['String']['output'];
  version: Scalars['String']['output'];
};

/** Input for the information about the business-owner account to create */
export type AccountInformationInput = {
  /** The email address of the business owner */
  email: Scalars['EmailAddress']['input'];
  /** First name of the business owner */
  firstName: Scalars['String']['input'];
  /** The last name of the business owner */
  lastName: Scalars['String']['input'];
  /** The password for the created account */
  password: Scalars['String']['input'];
};

/** Filter options to find active coupons */
export type ActiveCouponFilterInput = {
  /** The ID of a branch office */
  branchOfficeId?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the company, the coupons should be fetched for */
  companyId: Scalars['String']['input'];
};

/** Model for only active coupons */
export type ActiveCouponModel = {
  __typename: 'ActiveCouponModel';
  /** All currently active coupons */
  activeCoupons?: Maybe<Array<CouponWithPeriodModel>>;
  /** All coupons that are active in future */
  futureActiveCoupons?: Maybe<Array<CouponWithPeriodModel>>;
};

export type AffectedRows = {
  __typename: 'AffectedRows';
  count: Scalars['Int']['output'];
};

export type AggregateReceivedPoint = {
  __typename: 'AggregateReceivedPoint';
  _avg?: Maybe<ReceivedPointAvgAggregate>;
  _count?: Maybe<ReceivedPointCountAggregate>;
  _max?: Maybe<ReceivedPointMaxAggregate>;
  _min?: Maybe<ReceivedPointMinAggregate>;
  _sum?: Maybe<ReceivedPointSumAggregate>;
};

export type Balance = {
  __typename: 'Balance';
  company: Company;
  companyId: Scalars['String']['output'];
  count: Scalars['Int']['output'];
  createdAt: Scalars['DateTime']['output'];
  customer: Customer;
  customerId: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type BalanceAvgAggregate = {
  __typename: 'BalanceAvgAggregate';
  count?: Maybe<Scalars['Float']['output']>;
};

export type BalanceCompanyIdCustomerIdCompoundUniqueInput = {
  companyId: Scalars['String']['input'];
  customerId: Scalars['String']['input'];
};

export type BalanceCountAggregate = {
  __typename: 'BalanceCountAggregate';
  _all: Scalars['Int']['output'];
  companyId: Scalars['Int']['output'];
  count: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  customerId: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
};

export type BalanceCreateNestedManyWithoutCustomerInput = {
  connect?: InputMaybe<Array<BalanceWhereUniqueInput>>;
};

export type BalanceListRelationFilter = {
  every?: InputMaybe<BalanceWhereInput>;
  none?: InputMaybe<BalanceWhereInput>;
  some?: InputMaybe<BalanceWhereInput>;
};

export type BalanceMaxAggregate = {
  __typename: 'BalanceMaxAggregate';
  companyId?: Maybe<Scalars['String']['output']>;
  count?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  customerId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type BalanceMinAggregate = {
  __typename: 'BalanceMinAggregate';
  companyId?: Maybe<Scalars['String']['output']>;
  count?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  customerId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type BalanceOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type BalanceOrderByWithRelationInput = {
  company?: InputMaybe<CompanyOrderByWithRelationInput>;
  companyId?: InputMaybe<SortOrder>;
  count?: InputMaybe<SortOrder>;
  customer?: InputMaybe<CustomerOrderByWithRelationInput>;
  customerId?: InputMaybe<SortOrder>;
};

export enum BalanceScalarFieldEnum {
  CompanyId = 'companyId',
  Count = 'count',
  CreatedAt = 'createdAt',
  CustomerId = 'customerId',
  UpdatedAt = 'updatedAt'
}

export type BalanceScalarWhereInput = {
  AND?: InputMaybe<Array<BalanceScalarWhereInput>>;
  NOT?: InputMaybe<Array<BalanceScalarWhereInput>>;
  OR?: InputMaybe<Array<BalanceScalarWhereInput>>;
  companyId?: InputMaybe<StringFilter>;
  count?: InputMaybe<IntFilter>;
  customerId?: InputMaybe<StringFilter>;
};

export type BalanceSumAggregate = {
  __typename: 'BalanceSumAggregate';
  count?: Maybe<Scalars['Int']['output']>;
};

export type BalanceUpdateManyMutationInput = {
  count?: InputMaybe<Scalars['Int']['input']>;
};

export type BalanceUpdateManyWithWhereWithoutCustomerInput = {
  data: BalanceUpdateManyMutationInput;
  where: BalanceScalarWhereInput;
};

export type BalanceUpdateManyWithoutCustomerNestedInput = {
  connect?: InputMaybe<Array<BalanceWhereUniqueInput>>;
  delete?: InputMaybe<Array<BalanceWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<BalanceScalarWhereInput>>;
  disconnect?: InputMaybe<Array<BalanceWhereUniqueInput>>;
  set?: InputMaybe<Array<BalanceWhereUniqueInput>>;
  updateMany?: InputMaybe<Array<BalanceUpdateManyWithWhereWithoutCustomerInput>>;
};

export type BalanceWhereInput = {
  AND?: InputMaybe<Array<BalanceWhereInput>>;
  NOT?: InputMaybe<Array<BalanceWhereInput>>;
  OR?: InputMaybe<Array<BalanceWhereInput>>;
  company?: InputMaybe<CompanyRelationFilter>;
  companyId?: InputMaybe<StringFilter>;
  count?: InputMaybe<IntFilter>;
  customer?: InputMaybe<CustomerRelationFilter>;
  customerId?: InputMaybe<StringFilter>;
};

export type BalanceWhereUniqueInput = {
  AND?: InputMaybe<Array<BalanceWhereInput>>;
  NOT?: InputMaybe<Array<BalanceWhereInput>>;
  OR?: InputMaybe<Array<BalanceWhereInput>>;
  company?: InputMaybe<CompanyRelationFilter>;
  companyId?: InputMaybe<StringFilter>;
  companyId_customerId?: InputMaybe<BalanceCompanyIdCustomerIdCompoundUniqueInput>;
  count?: InputMaybe<IntFilter>;
  customer?: InputMaybe<CustomerRelationFilter>;
  customerId?: InputMaybe<StringFilter>;
};

export type BankAccount = {
  __typename: 'BankAccount';
  accountHolder: Scalars['String']['output'];
  bankName: Scalars['String']['output'];
  bic: Scalars['String']['output'];
  iban: Scalars['String']['output'];
};

export type BoolFilter = {
  equals?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<NestedBoolFilter>;
};

export type BranchOffice = {
  __typename: 'BranchOffice';
  _count: BranchOfficeCount;
  city: Scalars['String']['output'];
  company: Company;
  companyId: Scalars['String']['output'];
  countryCode: Scalars['CountryCode']['output'];
  coupons?: Maybe<Array<Coupon>>;
  createdAt: Scalars['DateTime']['output'];
  crmApiId: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  houseNumber: Scalars['String']['output'];
  id: Scalars['UUID']['output'];
  latitude: Scalars['Decimal']['output'];
  longitude: Scalars['Decimal']['output'];
  name: Scalars['String']['output'];
  postalCode: Scalars['PostalCode']['output'];
  state: Scalars['String']['output'];
  street: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type BranchOfficeAvgAggregate = {
  __typename: 'BranchOfficeAvgAggregate';
  latitude?: Maybe<Scalars['Decimal']['output']>;
  longitude?: Maybe<Scalars['Decimal']['output']>;
};

export type BranchOfficeCount = {
  __typename: 'BranchOfficeCount';
  coupons: Scalars['Int']['output'];
};

export type BranchOfficeCountAggregate = {
  __typename: 'BranchOfficeCountAggregate';
  _all: Scalars['Int']['output'];
  city: Scalars['Int']['output'];
  companyId: Scalars['Int']['output'];
  countryCode: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  crmApiId: Scalars['Int']['output'];
  description: Scalars['Int']['output'];
  houseNumber: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  latitude: Scalars['Int']['output'];
  longitude: Scalars['Int']['output'];
  name: Scalars['Int']['output'];
  postalCode: Scalars['Int']['output'];
  state: Scalars['Int']['output'];
  street: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
};

/** Input for creating a branch office */
export type BranchOfficeCreateInput = {
  city: Scalars['String']['input'];
  /** Optionally connect the branch office to a specific company */
  company?: InputMaybe<CompanyCreateNestedOneWithoutBranchOfficesInput>;
  countryCode: Scalars['CountryCode']['input'];
  crmApiId: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  houseNumber: Scalars['String']['input'];
  latitude: Scalars['Decimal']['input'];
  longitude: Scalars['Decimal']['input'];
  name: Scalars['String']['input'];
  postalCode: Scalars['PostalCode']['input'];
  state: Scalars['String']['input'];
  street: Scalars['String']['input'];
};

export type BranchOfficeListRelationFilter = {
  every?: InputMaybe<BranchOfficeWhereInput>;
  none?: InputMaybe<BranchOfficeWhereInput>;
  some?: InputMaybe<BranchOfficeWhereInput>;
};

export type BranchOfficeLocationCompoundUniqueInput = {
  city: Scalars['String']['input'];
  countryCode: Scalars['CountryCode']['input'];
  houseNumber: Scalars['String']['input'];
  name: Scalars['String']['input'];
  postalCode: Scalars['PostalCode']['input'];
  state: Scalars['String']['input'];
  street: Scalars['String']['input'];
};

export type BranchOfficeMaxAggregate = {
  __typename: 'BranchOfficeMaxAggregate';
  city?: Maybe<Scalars['String']['output']>;
  companyId?: Maybe<Scalars['String']['output']>;
  countryCode?: Maybe<Scalars['CountryCode']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  crmApiId?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  houseNumber?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['UUID']['output']>;
  latitude?: Maybe<Scalars['Decimal']['output']>;
  longitude?: Maybe<Scalars['Decimal']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  postalCode?: Maybe<Scalars['PostalCode']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  street?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type BranchOfficeMinAggregate = {
  __typename: 'BranchOfficeMinAggregate';
  city?: Maybe<Scalars['String']['output']>;
  companyId?: Maybe<Scalars['String']['output']>;
  countryCode?: Maybe<Scalars['CountryCode']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  crmApiId?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  houseNumber?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['UUID']['output']>;
  latitude?: Maybe<Scalars['Decimal']['output']>;
  longitude?: Maybe<Scalars['Decimal']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  postalCode?: Maybe<Scalars['PostalCode']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  street?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type BranchOfficeOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type BranchOfficeOrderByWithRelationInput = {
  city?: InputMaybe<SortOrder>;
  company?: InputMaybe<CompanyOrderByWithRelationInput>;
  companyId?: InputMaybe<SortOrder>;
  countryCode?: InputMaybe<SortOrder>;
  coupons?: InputMaybe<CouponOrderByRelationAggregateInput>;
  crmApiId?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrderInput>;
  houseNumber?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  latitude?: InputMaybe<SortOrder>;
  longitude?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  postalCode?: InputMaybe<SortOrder>;
  state?: InputMaybe<SortOrder>;
  street?: InputMaybe<SortOrder>;
};

export enum BranchOfficeScalarFieldEnum {
  City = 'city',
  CompanyId = 'companyId',
  CountryCode = 'countryCode',
  CreatedAt = 'createdAt',
  CrmApiId = 'crmApiId',
  Description = 'description',
  HouseNumber = 'houseNumber',
  Id = 'id',
  Latitude = 'latitude',
  Longitude = 'longitude',
  Name = 'name',
  PostalCode = 'postalCode',
  State = 'state',
  Street = 'street',
  UpdatedAt = 'updatedAt'
}

export type BranchOfficeScalarWhereInput = {
  AND?: InputMaybe<Array<BranchOfficeScalarWhereInput>>;
  NOT?: InputMaybe<Array<BranchOfficeScalarWhereInput>>;
  OR?: InputMaybe<Array<BranchOfficeScalarWhereInput>>;
  city?: InputMaybe<StringFilter>;
  companyId?: InputMaybe<StringFilter>;
  countryCode?: InputMaybe<StringFilter>;
  crmApiId?: InputMaybe<StringFilter>;
  description?: InputMaybe<StringNullableFilter>;
  houseNumber?: InputMaybe<StringFilter>;
  id?: InputMaybe<UuidFilter>;
  latitude?: InputMaybe<DecimalFilter>;
  longitude?: InputMaybe<DecimalFilter>;
  name?: InputMaybe<StringFilter>;
  postalCode?: InputMaybe<StringFilter>;
  state?: InputMaybe<StringFilter>;
  street?: InputMaybe<StringFilter>;
};

export type BranchOfficeSumAggregate = {
  __typename: 'BranchOfficeSumAggregate';
  latitude?: Maybe<Scalars['Decimal']['output']>;
  longitude?: Maybe<Scalars['Decimal']['output']>;
};

/** Custom input type for unique identifier of branch offices */
export type BranchOfficeUniqueInput = {
  id?: InputMaybe<Scalars['UUID']['input']>;
};

export type BranchOfficeUpdateInput = {
  city?: InputMaybe<Scalars['String']['input']>;
  company?: InputMaybe<CompanyUpdateOneRequiredWithoutBranchOfficesNestedInput>;
  countryCode?: InputMaybe<Scalars['CountryCode']['input']>;
  coupons?: InputMaybe<CouponUpdateManyWithoutBranchOfficesNestedInput>;
  crmApiId?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  houseNumber?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['UUID']['input']>;
  latitude?: InputMaybe<Scalars['Decimal']['input']>;
  longitude?: InputMaybe<Scalars['Decimal']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  postalCode?: InputMaybe<Scalars['PostalCode']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  street?: InputMaybe<Scalars['String']['input']>;
};

export type BranchOfficeUpdateManyMutationInput = {
  city?: InputMaybe<Scalars['String']['input']>;
  countryCode?: InputMaybe<Scalars['CountryCode']['input']>;
  crmApiId?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  houseNumber?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['UUID']['input']>;
  latitude?: InputMaybe<Scalars['Decimal']['input']>;
  longitude?: InputMaybe<Scalars['Decimal']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  postalCode?: InputMaybe<Scalars['PostalCode']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  street?: InputMaybe<Scalars['String']['input']>;
};

export type BranchOfficeUpdateManyWithWhereWithoutCouponsInput = {
  data: BranchOfficeUpdateManyMutationInput;
  where: BranchOfficeScalarWhereInput;
};

export type BranchOfficeUpdateManyWithoutCouponsNestedInput = {
  connect?: InputMaybe<Array<BranchOfficeWhereUniqueInput>>;
  delete?: InputMaybe<Array<BranchOfficeWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<BranchOfficeScalarWhereInput>>;
  disconnect?: InputMaybe<Array<BranchOfficeWhereUniqueInput>>;
  set?: InputMaybe<Array<BranchOfficeWhereUniqueInput>>;
  updateMany?: InputMaybe<Array<BranchOfficeUpdateManyWithWhereWithoutCouponsInput>>;
};

export type BranchOfficeWhereInput = {
  AND?: InputMaybe<Array<BranchOfficeWhereInput>>;
  NOT?: InputMaybe<Array<BranchOfficeWhereInput>>;
  OR?: InputMaybe<Array<BranchOfficeWhereInput>>;
  city?: InputMaybe<StringFilter>;
  company?: InputMaybe<CompanyRelationFilter>;
  companyId?: InputMaybe<StringFilter>;
  countryCode?: InputMaybe<StringFilter>;
  coupons?: InputMaybe<CouponListRelationFilter>;
  crmApiId?: InputMaybe<StringFilter>;
  description?: InputMaybe<StringNullableFilter>;
  houseNumber?: InputMaybe<StringFilter>;
  id?: InputMaybe<UuidFilter>;
  latitude?: InputMaybe<DecimalFilter>;
  longitude?: InputMaybe<DecimalFilter>;
  name?: InputMaybe<StringFilter>;
  postalCode?: InputMaybe<StringFilter>;
  state?: InputMaybe<StringFilter>;
  street?: InputMaybe<StringFilter>;
};

export type BranchOfficeWhereUniqueInput = {
  AND?: InputMaybe<Array<BranchOfficeWhereInput>>;
  NOT?: InputMaybe<Array<BranchOfficeWhereInput>>;
  OR?: InputMaybe<Array<BranchOfficeWhereInput>>;
  city?: InputMaybe<StringFilter>;
  company?: InputMaybe<CompanyRelationFilter>;
  companyId?: InputMaybe<StringFilter>;
  countryCode?: InputMaybe<StringFilter>;
  coupons?: InputMaybe<CouponListRelationFilter>;
  crmApiId?: InputMaybe<StringFilter>;
  description?: InputMaybe<StringNullableFilter>;
  houseNumber?: InputMaybe<StringFilter>;
  id?: InputMaybe<Scalars['UUID']['input']>;
  latitude?: InputMaybe<DecimalFilter>;
  location?: InputMaybe<BranchOfficeLocationCompoundUniqueInput>;
  longitude?: InputMaybe<DecimalFilter>;
  name?: InputMaybe<StringFilter>;
  postalCode?: InputMaybe<StringFilter>;
  state?: InputMaybe<StringFilter>;
  street?: InputMaybe<StringFilter>;
};

export type CalculationRule = {
  __typename: 'CalculationRule';
  company: Company;
  companyId: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['UUID']['output'];
  points: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
  value: Scalars['Float']['output'];
};

export type CalculationRuleAvgAggregate = {
  __typename: 'CalculationRuleAvgAggregate';
  points?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

export type CalculationRuleCompanyIdPointsValueCompoundUniqueInput = {
  points: Scalars['Int']['input'];
  value: Scalars['Float']['input'];
};

export type CalculationRuleCountAggregate = {
  __typename: 'CalculationRuleCountAggregate';
  _all: Scalars['Int']['output'];
  companyId: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  points: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
  value: Scalars['Int']['output'];
};

export type CalculationRuleListRelationFilter = {
  every?: InputMaybe<CalculationRuleWhereInput>;
  none?: InputMaybe<CalculationRuleWhereInput>;
  some?: InputMaybe<CalculationRuleWhereInput>;
};

export type CalculationRuleMaxAggregate = {
  __typename: 'CalculationRuleMaxAggregate';
  companyId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['UUID']['output']>;
  points?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

export type CalculationRuleMinAggregate = {
  __typename: 'CalculationRuleMinAggregate';
  companyId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['UUID']['output']>;
  points?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

export type CalculationRuleOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type CalculationRuleOrderByWithRelationInput = {
  company?: InputMaybe<CompanyOrderByWithRelationInput>;
  id?: InputMaybe<SortOrder>;
  points?: InputMaybe<SortOrder>;
  value?: InputMaybe<SortOrder>;
};

export enum CalculationRuleScalarFieldEnum {
  CompanyId = 'companyId',
  CreatedAt = 'createdAt',
  Id = 'id',
  Points = 'points',
  UpdatedAt = 'updatedAt',
  Value = 'value'
}

export type CalculationRuleSumAggregate = {
  __typename: 'CalculationRuleSumAggregate';
  points?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

export type CalculationRuleWhereInput = {
  AND?: InputMaybe<Array<CalculationRuleWhereInput>>;
  NOT?: InputMaybe<Array<CalculationRuleWhereInput>>;
  OR?: InputMaybe<Array<CalculationRuleWhereInput>>;
  company?: InputMaybe<CompanyRelationFilter>;
  id?: InputMaybe<UuidFilter>;
  points?: InputMaybe<IntFilter>;
  value?: InputMaybe<FloatFilter>;
};

export type CalculationRuleWhereUniqueInput = {
  AND?: InputMaybe<Array<CalculationRuleWhereInput>>;
  NOT?: InputMaybe<Array<CalculationRuleWhereInput>>;
  OR?: InputMaybe<Array<CalculationRuleWhereInput>>;
  company?: InputMaybe<CompanyRelationFilter>;
  companyId_points_value?: InputMaybe<CalculationRuleCompanyIdPointsValueCompoundUniqueInput>;
  id?: InputMaybe<Scalars['UUID']['input']>;
  points?: InputMaybe<IntFilter>;
  value?: InputMaybe<FloatFilter>;
};

export type Challenge = {
  __typename: 'Challenge';
  _count: ChallengeCount;
  active: Scalars['Boolean']['output'];
  company: Company;
  companyId: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  customerChallenges?: Maybe<Array<CustomerChallenge>>;
  id: Scalars['UUID']['output'];
  imageUrl?: Maybe<Scalars['String']['output']>;
  /** The indicator of whether the challenge can still be joined or not */
  joinable: Scalars['Boolean']['output'];
  /** The maximum number of participants */
  maxParticipants?: Maybe<Scalars['Int']['output']>;
  periods?: Maybe<Array<ChallengePeriod>>;
  pointsCredit: Scalars['Int']['output'];
  trainingGoal: Scalars['Int']['output'];
  /** The type of challenge. (COMMUNITY_CHALLENGE or PASSIVE_POINTS_CHALLENGE) */
  type: ChallengeType;
  updatedAt: Scalars['DateTime']['output'];
};


export type ChallengeCustomerChallengesArgs = {
  cursor?: InputMaybe<CustomerChallengeWhereUniqueInput>;
  distinct?: InputMaybe<Array<CustomerChallengeScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CustomerChallengeOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CustomerChallengeWhereInput>;
};


export type ChallengePeriodsArgs = {
  cursor?: InputMaybe<ChallengePeriodWhereUniqueInput>;
  distinct?: InputMaybe<Array<ChallengePeriodScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ChallengePeriodOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ChallengePeriodWhereInput>;
};

export type ChallengeAvgAggregate = {
  __typename: 'ChallengeAvgAggregate';
  maxParticipants?: Maybe<Scalars['Float']['output']>;
  pointsCredit?: Maybe<Scalars['Float']['output']>;
  trainingGoal?: Maybe<Scalars['Float']['output']>;
};

export type ChallengeCount = {
  __typename: 'ChallengeCount';
  customerChallenges: Scalars['Int']['output'];
  periods: Scalars['Int']['output'];
};

export type ChallengeCountAggregate = {
  __typename: 'ChallengeCountAggregate';
  _all: Scalars['Int']['output'];
  active: Scalars['Int']['output'];
  companyId: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  imageUrl: Scalars['Int']['output'];
  joinable: Scalars['Int']['output'];
  maxParticipants: Scalars['Int']['output'];
  pointsCredit: Scalars['Int']['output'];
  trainingGoal: Scalars['Int']['output'];
  type: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
};

/** Input for challenge creation */
export type ChallengeCreateInput = {
  /** Optional parameter to configure challenge for a specific company */
  companyId?: InputMaybe<Scalars['String']['input']>;
  /** Configure the challenge image by uploading an image or specify and image URL */
  image?: InputMaybe<ChallengeImageInput>;
  maxParticipants?: InputMaybe<Scalars['Int']['input']>;
  /** The period the challenge is active in. Needs to be configured for challenges of type COMMUNITY_CHALLENGE. Challenges of type PASSIVE_POINTS_CHALLENGE starts at creation date and ends at the end of month. */
  period?: InputMaybe<CreateChallengePeriodInput>;
  pointsCredit: Scalars['Int']['input'];
  trainingGoal: Scalars['Int']['input'];
  /** The type of challenge */
  type?: ChallengeType;
};

export type ChallengeCreateNestedOneWithoutPeriodsInput = {
  connect?: InputMaybe<ChallengeWhereUniqueInput>;
};

/** Input for the challenge image */
export type ChallengeImageInput = {
  /** Upload a new challenge image */
  fileUpload?: InputMaybe<Scalars['Upload']['input']>;
  /** The URL of an image */
  imageUrl?: InputMaybe<Scalars['String']['input']>;
};

export type ChallengeListRelationFilter = {
  every?: InputMaybe<ChallengeWhereInput>;
  none?: InputMaybe<ChallengeWhereInput>;
  some?: InputMaybe<ChallengeWhereInput>;
};

export type ChallengeMaxAggregate = {
  __typename: 'ChallengeMaxAggregate';
  active?: Maybe<Scalars['Boolean']['output']>;
  companyId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['UUID']['output']>;
  imageUrl?: Maybe<Scalars['String']['output']>;
  joinable?: Maybe<Scalars['Boolean']['output']>;
  maxParticipants?: Maybe<Scalars['Int']['output']>;
  pointsCredit?: Maybe<Scalars['Int']['output']>;
  trainingGoal?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<ChallengeType>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type ChallengeMinAggregate = {
  __typename: 'ChallengeMinAggregate';
  active?: Maybe<Scalars['Boolean']['output']>;
  companyId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['UUID']['output']>;
  imageUrl?: Maybe<Scalars['String']['output']>;
  joinable?: Maybe<Scalars['Boolean']['output']>;
  maxParticipants?: Maybe<Scalars['Int']['output']>;
  pointsCredit?: Maybe<Scalars['Int']['output']>;
  trainingGoal?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<ChallengeType>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type ChallengeOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type ChallengeOrderByWithRelationInput = {
  active?: InputMaybe<SortOrder>;
  company?: InputMaybe<CompanyOrderByWithRelationInput>;
  customerChallenges?: InputMaybe<CustomerChallengeOrderByRelationAggregateInput>;
  id?: InputMaybe<SortOrder>;
  imageUrl?: InputMaybe<SortOrderInput>;
  joinable?: InputMaybe<SortOrder>;
  maxParticipants?: InputMaybe<SortOrderInput>;
  periods?: InputMaybe<ChallengePeriodOrderByRelationAggregateInput>;
  pointsCredit?: InputMaybe<SortOrder>;
  trainingGoal?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
};

export type ChallengePeriod = {
  __typename: 'ChallengePeriod';
  _count: ChallengePeriodCount;
  activeFrom: Scalars['DateTime']['output'];
  activeUntil: Scalars['DateTime']['output'];
  challenge: Challenge;
  challengeId: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['UUID']['output'];
  /** The current number of joined participants for the challenge at this period */
  participants: Scalars['Int']['output'];
  redemptions?: Maybe<Array<CustomerChallenge>>;
  updatedAt: Scalars['DateTime']['output'];
};

export type ChallengePeriodAvgAggregate = {
  __typename: 'ChallengePeriodAvgAggregate';
  participants?: Maybe<Scalars['Float']['output']>;
};

export type ChallengePeriodCount = {
  __typename: 'ChallengePeriodCount';
  redemptions: Scalars['Int']['output'];
};

export type ChallengePeriodCountAggregate = {
  __typename: 'ChallengePeriodCountAggregate';
  _all: Scalars['Int']['output'];
  activeFrom: Scalars['Int']['output'];
  activeUntil: Scalars['Int']['output'];
  challengeId: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  participants: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
};

/** Input for creating challenge periods */
export type ChallengePeriodCreateInput = {
  activeFrom: Scalars['DateTime']['input'];
  activeUntil: Scalars['DateTime']['input'];
  challenge: ChallengeCreateNestedOneWithoutPeriodsInput;
  participants?: InputMaybe<Scalars['Int']['input']>;
  redemptions?: InputMaybe<CustomerChallengeCreateNestedManyWithoutPeriodInput>;
};

export type ChallengePeriodListRelationFilter = {
  every?: InputMaybe<ChallengePeriodWhereInput>;
  none?: InputMaybe<ChallengePeriodWhereInput>;
  some?: InputMaybe<ChallengePeriodWhereInput>;
};

export type ChallengePeriodMaxAggregate = {
  __typename: 'ChallengePeriodMaxAggregate';
  activeFrom?: Maybe<Scalars['DateTime']['output']>;
  activeUntil?: Maybe<Scalars['DateTime']['output']>;
  challengeId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['UUID']['output']>;
  participants?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type ChallengePeriodMinAggregate = {
  __typename: 'ChallengePeriodMinAggregate';
  activeFrom?: Maybe<Scalars['DateTime']['output']>;
  activeUntil?: Maybe<Scalars['DateTime']['output']>;
  challengeId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['UUID']['output']>;
  participants?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type ChallengePeriodOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type ChallengePeriodOrderByWithRelationInput = {
  activeFrom?: InputMaybe<SortOrder>;
  activeUntil?: InputMaybe<SortOrder>;
  challenge?: InputMaybe<ChallengeOrderByWithRelationInput>;
  challengeId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  participants?: InputMaybe<SortOrder>;
  redemptions?: InputMaybe<CustomerChallengeOrderByRelationAggregateInput>;
};

export type ChallengePeriodRelationFilter = {
  is?: InputMaybe<ChallengePeriodWhereInput>;
  isNot?: InputMaybe<ChallengePeriodWhereInput>;
};

export enum ChallengePeriodScalarFieldEnum {
  ActiveFrom = 'activeFrom',
  ActiveUntil = 'activeUntil',
  ChallengeId = 'challengeId',
  CreatedAt = 'createdAt',
  Id = 'id',
  Participants = 'participants',
  UpdatedAt = 'updatedAt'
}

export type ChallengePeriodSumAggregate = {
  __typename: 'ChallengePeriodSumAggregate';
  participants?: Maybe<Scalars['Int']['output']>;
};

export type ChallengePeriodUpdateInput = {
  activeFrom?: InputMaybe<Scalars['DateTime']['input']>;
  activeUntil?: InputMaybe<Scalars['DateTime']['input']>;
  challenge?: InputMaybe<ChallengeUpdateOneRequiredWithoutPeriodsNestedInput>;
  id?: InputMaybe<Scalars['UUID']['input']>;
  participants?: InputMaybe<Scalars['Int']['input']>;
  redemptions?: InputMaybe<CustomerChallengeUpdateManyWithoutPeriodNestedInput>;
};

export type ChallengePeriodWhereInput = {
  AND?: InputMaybe<Array<ChallengePeriodWhereInput>>;
  NOT?: InputMaybe<Array<ChallengePeriodWhereInput>>;
  OR?: InputMaybe<Array<ChallengePeriodWhereInput>>;
  activeFrom?: InputMaybe<DateTimeFilter>;
  activeUntil?: InputMaybe<DateTimeFilter>;
  challenge?: InputMaybe<ChallengeRelationFilter>;
  challengeId?: InputMaybe<UuidFilter>;
  id?: InputMaybe<UuidFilter>;
  participants?: InputMaybe<IntFilter>;
  redemptions?: InputMaybe<CustomerChallengeListRelationFilter>;
};

export type ChallengePeriodWhereUniqueInput = {
  AND?: InputMaybe<Array<ChallengePeriodWhereInput>>;
  NOT?: InputMaybe<Array<ChallengePeriodWhereInput>>;
  OR?: InputMaybe<Array<ChallengePeriodWhereInput>>;
  activeFrom?: InputMaybe<DateTimeFilter>;
  activeUntil?: InputMaybe<DateTimeFilter>;
  challenge?: InputMaybe<ChallengeRelationFilter>;
  challengeId?: InputMaybe<UuidFilter>;
  id?: InputMaybe<Scalars['UUID']['input']>;
  participants?: InputMaybe<IntFilter>;
  redemptions?: InputMaybe<CustomerChallengeListRelationFilter>;
};

export type ChallengeRelationFilter = {
  is?: InputMaybe<ChallengeWhereInput>;
  isNot?: InputMaybe<ChallengeWhereInput>;
};

export enum ChallengeScalarFieldEnum {
  Active = 'active',
  CompanyId = 'companyId',
  CreatedAt = 'createdAt',
  Id = 'id',
  ImageUrl = 'imageUrl',
  Joinable = 'joinable',
  MaxParticipants = 'maxParticipants',
  PointsCredit = 'pointsCredit',
  TrainingGoal = 'trainingGoal',
  Type = 'type',
  UpdatedAt = 'updatedAt'
}

export enum ChallengeStatus {
  Active = 'ACTIVE',
  Expired = 'EXPIRED'
}

export type ChallengeSumAggregate = {
  __typename: 'ChallengeSumAggregate';
  maxParticipants?: Maybe<Scalars['Int']['output']>;
  pointsCredit?: Maybe<Scalars['Int']['output']>;
  trainingGoal?: Maybe<Scalars['Int']['output']>;
};

export enum ChallengeType {
  CommunityChallenge = 'COMMUNITY_CHALLENGE',
  PassivePointsChallenge = 'PASSIVE_POINTS_CHALLENGE'
}

export type ChallengeUpdateInput = {
  pointsCredit?: InputMaybe<Scalars['Int']['input']>;
  trainingGoal?: InputMaybe<Scalars['Int']['input']>;
};

export type ChallengeUpdateOneRequiredWithoutPeriodsNestedInput = {
  connect?: InputMaybe<ChallengeWhereUniqueInput>;
};

export type ChallengeWhereInput = {
  AND?: InputMaybe<Array<ChallengeWhereInput>>;
  NOT?: InputMaybe<Array<ChallengeWhereInput>>;
  OR?: InputMaybe<Array<ChallengeWhereInput>>;
  active?: InputMaybe<BoolFilter>;
  company?: InputMaybe<CompanyRelationFilter>;
  customerChallenges?: InputMaybe<CustomerChallengeListRelationFilter>;
  id?: InputMaybe<UuidFilter>;
  imageUrl?: InputMaybe<StringNullableFilter>;
  joinable?: InputMaybe<BoolFilter>;
  maxParticipants?: InputMaybe<IntNullableFilter>;
  periods?: InputMaybe<ChallengePeriodListRelationFilter>;
  pointsCredit?: InputMaybe<IntFilter>;
  trainingGoal?: InputMaybe<IntFilter>;
  type?: InputMaybe<EnumChallengeTypeFilter>;
};

export type ChallengeWhereUniqueInput = {
  AND?: InputMaybe<Array<ChallengeWhereInput>>;
  NOT?: InputMaybe<Array<ChallengeWhereInput>>;
  OR?: InputMaybe<Array<ChallengeWhereInput>>;
  active?: InputMaybe<BoolFilter>;
  company?: InputMaybe<CompanyRelationFilter>;
  customerChallenges?: InputMaybe<CustomerChallengeListRelationFilter>;
  id?: InputMaybe<Scalars['UUID']['input']>;
  imageUrl?: InputMaybe<StringNullableFilter>;
  joinable?: InputMaybe<BoolFilter>;
  maxParticipants?: InputMaybe<IntNullableFilter>;
  periods?: InputMaybe<ChallengePeriodListRelationFilter>;
  pointsCredit?: InputMaybe<IntFilter>;
  trainingGoal?: InputMaybe<IntFilter>;
  type?: InputMaybe<EnumChallengeTypeFilter>;
};

/** Custom type for changing point system of company */
export type ChangePointSystemInput = {
  id: Scalars['String']['input'];
  /** Must be set to change from VISIT BASED to VALUE BASED */
  valueBasedInput?: InputMaybe<ChangeToValueBasedInput>;
  /** Must be set to change from VALUE BASED to VISIT BASED */
  visitBasedInput?: InputMaybe<ChangeToVisitBasedInput>;
};

/** Custom input to change system type to PERCENTAGE */
export type ChangeToPercentageInput = {
  calculationRule: CustomCalculationRuleInput;
};

/** Custom input to change system type to STAGGERED */
export type ChangeToStaggeredInput = {
  /** The new calculation rules for the new system type STAGGERED (up to 5 rules) */
  calculationRules: Array<CustomCalculationRuleInput>;
};

/** Input to change to value based system type (PERCENTAGE or STAGGERED). Only one of PERCENTAGE or STAGGERED input can be set! */
export type ChangeToValueBasedInput = {
  /** Must be set for change from VISIT BASED or VALUE BASED (STAGGERED) to VALUE BASED (PERCENTAGE) system type */
  percentageInput?: InputMaybe<ChangeToPercentageInput>;
  /** Must be set for change to STAGGERED system type */
  staggeredInput?: InputMaybe<ChangeToStaggeredInput>;
};

/** Custom input to change system type to VISIT BASED */
export type ChangeToVisitBasedInput = {
  /** Point amount per visit */
  pointsPerVisit?: Scalars['Float']['input'];
  scanLimit?: InputMaybe<Scalars['Int']['input']>;
};

export type Company = {
  __typename: 'Company';
  _count: CompanyCount;
  balances?: Maybe<Array<Balance>>;
  branchOffices?: Maybe<Array<BranchOffice>>;
  calculationRules?: Maybe<Array<CalculationRule>>;
  challenges?: Maybe<Array<Challenge>>;
  city: Scalars['String']['output'];
  countryCode: Scalars['CountryCode']['output'];
  coupons?: Maybe<Array<Coupon>>;
  createdAt: Scalars['DateTime']['output'];
  crmConfig?: Maybe<CompanyCrmConfig>;
  customers?: Maybe<Array<Customer>>;
  description?: Maybe<Scalars['String']['output']>;
  email: Scalars['EmailAddress']['output'];
  employees?: Maybe<Array<Employee>>;
  houseNumber: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  /** THe latitude of the company */
  latitude?: Maybe<Scalars['Decimal']['output']>;
  logoUrl?: Maybe<Scalars['String']['output']>;
  /** The longitude of the company */
  longitude?: Maybe<Scalars['Decimal']['output']>;
  name: Scalars['String']['output'];
  organizationName: Scalars['String']['output'];
  pointSystem?: Maybe<PointSystem>;
  postalCode: Scalars['PostalCode']['output'];
  promotions?: Maybe<Array<Promotion>>;
  pushNotifications?: Maybe<Array<PushNotification>>;
  receivedPoints?: Maybe<Array<ReceivedPoint>>;
  rewards?: Maybe<Array<Reward>>;
  state: Scalars['String']['output'];
  street: Scalars['String']['output'];
  /** The timezone of the company */
  timeZone: Scalars['TimeZone']['output'];
  updatedAt: Scalars['DateTime']['output'];
  visible: Scalars['Boolean']['output'];
};


export type Company_CountArgs = {
  onlyActiveRewards?: InputMaybe<Scalars['Boolean']['input']>;
};


export type CompanyCouponsArgs = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  type?: InputMaybe<CouponType>;
};


export type CompanyPromotionsArgs = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  filterInput?: InputMaybe<LocationFilterInput>;
};


export type CompanyRewardsArgs = {
  sortOrder?: InputMaybe<SortOrder>;
  status?: InputMaybe<RewardStatus>;
};

export type CompanyAvgAggregate = {
  __typename: 'CompanyAvgAggregate';
  latitude?: Maybe<Scalars['Decimal']['output']>;
  longitude?: Maybe<Scalars['Decimal']['output']>;
};

export type CompanyCount = {
  __typename: 'CompanyCount';
  balances: Scalars['Int']['output'];
  branchOffices: Scalars['Int']['output'];
  calculationRules: Scalars['Int']['output'];
  challenges: Scalars['Int']['output'];
  coupons: Scalars['Int']['output'];
  customers: Scalars['Int']['output'];
  employees: Scalars['Int']['output'];
  promotions: Scalars['Int']['output'];
  pushNotifications: Scalars['Int']['output'];
  receivedPoints: Scalars['Int']['output'];
  rewards: Scalars['Int']['output'];
};

export type CompanyCountAggregate = {
  __typename: 'CompanyCountAggregate';
  _all: Scalars['Int']['output'];
  city: Scalars['Int']['output'];
  countryCode: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  description: Scalars['Int']['output'];
  email: Scalars['Int']['output'];
  houseNumber: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  latitude: Scalars['Int']['output'];
  logoUrl: Scalars['Int']['output'];
  longitude: Scalars['Int']['output'];
  name: Scalars['Int']['output'];
  organizationName: Scalars['Int']['output'];
  postalCode: Scalars['Int']['output'];
  state: Scalars['Int']['output'];
  street: Scalars['Int']['output'];
  timeZone: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
  visible: Scalars['Int']['output'];
};

export type CompanyCreateNestedOneWithoutBranchOfficesInput = {
  connect?: InputMaybe<CompanyWhereUniqueInput>;
};

export type CompanyCreateNestedOneWithoutCouponsInput = {
  connect: CompanyWhereUniqueInput;
};

/** Non nullable company connect type */
export type CompanyCreateNestedOneWithoutCrmConfigInput = {
  connect: CompanyWhereUniqueInput;
};

export type CompanyCreateNestedOneWithoutCustomersInput = {
  connect?: InputMaybe<CompanyWhereUniqueInput>;
};

/** Non nullable company connect input */
export type CompanyCreateNestedOneWithoutPushNotificationsInput = {
  connect: CompanyWhereUniqueInput;
};

export type CompanyCrmConfig = {
  __typename: 'CompanyCrmConfig';
  /** The API URL of the crm system */
  apiUrl: Scalars['String']['output'];
  company: Company;
  companyId: Scalars['String']['output'];
  /** The CRM tenant name */
  crmTenant: Scalars['String']['output'];
};

export type CompanyCrmConfigCountAggregate = {
  __typename: 'CompanyCrmConfigCountAggregate';
  _all: Scalars['Int']['output'];
  apiUrl: Scalars['Int']['output'];
  companyId: Scalars['Int']['output'];
  crmTenant: Scalars['Int']['output'];
};

/** Create input for company crm config */
export type CompanyCrmConfigCreateInput = {
  /** The api key for magicline */
  apiKey: Scalars['String']['input'];
  apiUrl: Scalars['String']['input'];
  company: CompanyCreateNestedOneWithoutCrmConfigInput;
  crmTenant: Scalars['String']['input'];
};

export type CompanyCrmConfigCrmTenantCompanyIdCompoundUniqueInput = {
  companyId: Scalars['String']['input'];
  crmTenant: Scalars['String']['input'];
};

export type CompanyCrmConfigMaxAggregate = {
  __typename: 'CompanyCrmConfigMaxAggregate';
  apiUrl?: Maybe<Scalars['String']['output']>;
  companyId?: Maybe<Scalars['String']['output']>;
  crmTenant?: Maybe<Scalars['String']['output']>;
};

export type CompanyCrmConfigMinAggregate = {
  __typename: 'CompanyCrmConfigMinAggregate';
  apiUrl?: Maybe<Scalars['String']['output']>;
  companyId?: Maybe<Scalars['String']['output']>;
  crmTenant?: Maybe<Scalars['String']['output']>;
};

export type CompanyCrmConfigNullableRelationFilter = {
  is?: InputMaybe<CompanyCrmConfigWhereInput>;
  isNot?: InputMaybe<CompanyCrmConfigWhereInput>;
};

export type CompanyCrmConfigOrderByWithRelationInput = {
  apiUrl?: InputMaybe<SortOrder>;
  company?: InputMaybe<CompanyOrderByWithRelationInput>;
  companyId?: InputMaybe<SortOrder>;
  crmTenant?: InputMaybe<SortOrder>;
};

export enum CompanyCrmConfigScalarFieldEnum {
  ApiUrl = 'apiUrl',
  CompanyId = 'companyId',
  CrmTenant = 'crmTenant'
}

/** Update input for company crm config */
export type CompanyCrmConfigUpdateInput = {
  apiUrl?: InputMaybe<Scalars['String']['input']>;
  crmTenant?: InputMaybe<Scalars['String']['input']>;
};

export type CompanyCrmConfigWhereInput = {
  AND?: InputMaybe<Array<CompanyCrmConfigWhereInput>>;
  NOT?: InputMaybe<Array<CompanyCrmConfigWhereInput>>;
  OR?: InputMaybe<Array<CompanyCrmConfigWhereInput>>;
  apiUrl?: InputMaybe<StringFilter>;
  company?: InputMaybe<CompanyRelationFilter>;
  companyId?: InputMaybe<StringFilter>;
  crmTenant?: InputMaybe<StringFilter>;
};

export type CompanyCrmConfigWhereUniqueInput = {
  AND?: InputMaybe<Array<CompanyCrmConfigWhereInput>>;
  NOT?: InputMaybe<Array<CompanyCrmConfigWhereInput>>;
  OR?: InputMaybe<Array<CompanyCrmConfigWhereInput>>;
  apiUrl?: InputMaybe<StringFilter>;
  company?: InputMaybe<CompanyRelationFilter>;
  companyId?: InputMaybe<Scalars['String']['input']>;
  crmTenant?: InputMaybe<StringFilter>;
  crmTenant_companyId?: InputMaybe<CompanyCrmConfigCrmTenantCompanyIdCompoundUniqueInput>;
};

/** Input for the company information */
export type CompanyInformationInput = {
  city: Scalars['String']['input'];
  countryCode: Scalars['CountryCode']['input'];
  email: Scalars['EmailAddress']['input'];
  houseNumber: Scalars['String']['input'];
  /** The industry id of the company */
  industryId: Scalars['String']['input'];
  name: Scalars['String']['input'];
  postalCode: Scalars['PostalCode']['input'];
  state: Scalars['String']['input'];
  street: Scalars['String']['input'];
};

export type CompanyLocationCompoundUniqueInput = {
  city: Scalars['String']['input'];
  countryCode: Scalars['CountryCode']['input'];
  houseNumber: Scalars['String']['input'];
  name: Scalars['String']['input'];
  postalCode: Scalars['PostalCode']['input'];
  state: Scalars['String']['input'];
  street: Scalars['String']['input'];
};

/** Input for company logo. */
export type CompanyLogoInput = {
  /** Upload a new logo as file */
  fileUpload?: InputMaybe<Scalars['Upload']['input']>;
  /** The url of an existing hosted logo */
  fileUrl?: InputMaybe<Scalars['String']['input']>;
};

export type CompanyMaxAggregate = {
  __typename: 'CompanyMaxAggregate';
  city?: Maybe<Scalars['String']['output']>;
  countryCode?: Maybe<Scalars['CountryCode']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['EmailAddress']['output']>;
  houseNumber?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  latitude?: Maybe<Scalars['Decimal']['output']>;
  logoUrl?: Maybe<Scalars['String']['output']>;
  longitude?: Maybe<Scalars['Decimal']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  organizationName?: Maybe<Scalars['String']['output']>;
  postalCode?: Maybe<Scalars['PostalCode']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  street?: Maybe<Scalars['String']['output']>;
  timeZone?: Maybe<Scalars['TimeZone']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  visible?: Maybe<Scalars['Boolean']['output']>;
};

export type CompanyMinAggregate = {
  __typename: 'CompanyMinAggregate';
  city?: Maybe<Scalars['String']['output']>;
  countryCode?: Maybe<Scalars['CountryCode']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['EmailAddress']['output']>;
  houseNumber?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  latitude?: Maybe<Scalars['Decimal']['output']>;
  logoUrl?: Maybe<Scalars['String']['output']>;
  longitude?: Maybe<Scalars['Decimal']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  organizationName?: Maybe<Scalars['String']['output']>;
  postalCode?: Maybe<Scalars['PostalCode']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  street?: Maybe<Scalars['String']['output']>;
  timeZone?: Maybe<Scalars['TimeZone']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  visible?: Maybe<Scalars['Boolean']['output']>;
};

export type CompanyOrderByWithRelationInput = {
  balances?: InputMaybe<BalanceOrderByRelationAggregateInput>;
  branchOffices?: InputMaybe<BranchOfficeOrderByRelationAggregateInput>;
  calculationRules?: InputMaybe<CalculationRuleOrderByRelationAggregateInput>;
  challenges?: InputMaybe<ChallengeOrderByRelationAggregateInput>;
  city?: InputMaybe<SortOrder>;
  countryCode?: InputMaybe<SortOrder>;
  coupons?: InputMaybe<CouponOrderByRelationAggregateInput>;
  crmConfig?: InputMaybe<CompanyCrmConfigOrderByWithRelationInput>;
  customers?: InputMaybe<CustomerOrderByRelationAggregateInput>;
  description?: InputMaybe<SortOrderInput>;
  email?: InputMaybe<SortOrder>;
  employees?: InputMaybe<EmployeeOrderByRelationAggregateInput>;
  houseNumber?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  latitude?: InputMaybe<SortOrderInput>;
  logoUrl?: InputMaybe<SortOrderInput>;
  longitude?: InputMaybe<SortOrderInput>;
  name?: InputMaybe<SortOrder>;
  organizationName?: InputMaybe<SortOrder>;
  pointSystem?: InputMaybe<PointSystemOrderByWithRelationInput>;
  postalCode?: InputMaybe<SortOrder>;
  promotions?: InputMaybe<PromotionOrderByRelationAggregateInput>;
  pushNotifications?: InputMaybe<PushNotificationOrderByRelationAggregateInput>;
  receivedPoints?: InputMaybe<ReceivedPointOrderByRelationAggregateInput>;
  rewards?: InputMaybe<RewardOrderByRelationAggregateInput>;
  state?: InputMaybe<SortOrder>;
  street?: InputMaybe<SortOrder>;
  timeZone?: InputMaybe<SortOrder>;
  visible?: InputMaybe<SortOrder>;
};

export type CompanyRelationFilter = {
  is?: InputMaybe<CompanyWhereInput>;
  isNot?: InputMaybe<CompanyWhereInput>;
};

export enum CompanyScalarFieldEnum {
  City = 'city',
  CountryCode = 'countryCode',
  CreatedAt = 'createdAt',
  Description = 'description',
  Email = 'email',
  HouseNumber = 'houseNumber',
  Id = 'id',
  Latitude = 'latitude',
  LogoUrl = 'logoUrl',
  Longitude = 'longitude',
  Name = 'name',
  OrganizationName = 'organizationName',
  PostalCode = 'postalCode',
  State = 'state',
  Street = 'street',
  TimeZone = 'timeZone',
  UpdatedAt = 'updatedAt',
  Visible = 'visible'
}

export type CompanySumAggregate = {
  __typename: 'CompanySumAggregate';
  latitude?: Maybe<Scalars['Decimal']['output']>;
  longitude?: Maybe<Scalars['Decimal']['output']>;
};

/** Unique identifier of company */
export type CompanyUniqueInput = {
  /** The unique identifier of the company */
  id: Scalars['String']['input'];
};

export type CompanyUpdateOneRequiredWithoutBranchOfficesNestedInput = {
  connect?: InputMaybe<CompanyWhereUniqueInput>;
};

export type CompanyUpdateOneRequiredWithoutCouponsNestedInput = {
  connect?: InputMaybe<CompanyWhereUniqueInput>;
};

export type CompanyUpdateOneRequiredWithoutCustomersNestedInput = {
  connect?: InputMaybe<CompanyWhereUniqueInput>;
};

export type CompanyUpdateOneRequiredWithoutPointSystemNestedInput = {
  connect?: InputMaybe<CompanyWhereUniqueInput>;
};

export type CompanyUpdateOneRequiredWithoutReceivedPointsNestedInput = {
  connect?: InputMaybe<CompanyWhereUniqueInput>;
};

export type CompanyUpdateOneRequiredWithoutRewardsNestedInput = {
  connect?: InputMaybe<CompanyWhereUniqueInput>;
};

export enum CompanyUserType {
  BusinessOwner = 'BUSINESS_OWNER',
  Employee = 'EMPLOYEE'
}

export type CompanyWhereInput = {
  AND?: InputMaybe<Array<CompanyWhereInput>>;
  NOT?: InputMaybe<Array<CompanyWhereInput>>;
  OR?: InputMaybe<Array<CompanyWhereInput>>;
  balances?: InputMaybe<BalanceListRelationFilter>;
  branchOffices?: InputMaybe<BranchOfficeListRelationFilter>;
  calculationRules?: InputMaybe<CalculationRuleListRelationFilter>;
  challenges?: InputMaybe<ChallengeListRelationFilter>;
  city?: InputMaybe<StringFilter>;
  countryCode?: InputMaybe<StringFilter>;
  coupons?: InputMaybe<CouponListRelationFilter>;
  crmConfig?: InputMaybe<CompanyCrmConfigNullableRelationFilter>;
  customers?: InputMaybe<CustomerListRelationFilter>;
  description?: InputMaybe<StringNullableFilter>;
  email?: InputMaybe<StringFilter>;
  employees?: InputMaybe<EmployeeListRelationFilter>;
  houseNumber?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  latitude?: InputMaybe<DecimalNullableFilter>;
  logoUrl?: InputMaybe<StringNullableFilter>;
  longitude?: InputMaybe<DecimalNullableFilter>;
  name?: InputMaybe<StringFilter>;
  organizationName?: InputMaybe<StringFilter>;
  pointSystem?: InputMaybe<PointSystemNullableRelationFilter>;
  postalCode?: InputMaybe<StringFilter>;
  promotions?: InputMaybe<PromotionListRelationFilter>;
  pushNotifications?: InputMaybe<PushNotificationListRelationFilter>;
  receivedPoints?: InputMaybe<ReceivedPointListRelationFilter>;
  rewards?: InputMaybe<RewardListRelationFilter>;
  state?: InputMaybe<StringFilter>;
  street?: InputMaybe<StringFilter>;
  timeZone?: InputMaybe<StringFilter>;
  visible?: InputMaybe<BoolFilter>;
};

export type CompanyWhereUniqueInput = {
  AND?: InputMaybe<Array<CompanyWhereInput>>;
  NOT?: InputMaybe<Array<CompanyWhereInput>>;
  OR?: InputMaybe<Array<CompanyWhereInput>>;
  balances?: InputMaybe<BalanceListRelationFilter>;
  branchOffices?: InputMaybe<BranchOfficeListRelationFilter>;
  calculationRules?: InputMaybe<CalculationRuleListRelationFilter>;
  challenges?: InputMaybe<ChallengeListRelationFilter>;
  city?: InputMaybe<StringFilter>;
  countryCode?: InputMaybe<StringFilter>;
  coupons?: InputMaybe<CouponListRelationFilter>;
  crmConfig?: InputMaybe<CompanyCrmConfigNullableRelationFilter>;
  customers?: InputMaybe<CustomerListRelationFilter>;
  description?: InputMaybe<StringNullableFilter>;
  email?: InputMaybe<StringFilter>;
  employees?: InputMaybe<EmployeeListRelationFilter>;
  houseNumber?: InputMaybe<StringFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  latitude?: InputMaybe<DecimalNullableFilter>;
  location?: InputMaybe<CompanyLocationCompoundUniqueInput>;
  logoUrl?: InputMaybe<StringNullableFilter>;
  longitude?: InputMaybe<DecimalNullableFilter>;
  name?: InputMaybe<StringFilter>;
  organizationName?: InputMaybe<Scalars['String']['input']>;
  pointSystem?: InputMaybe<PointSystemNullableRelationFilter>;
  postalCode?: InputMaybe<StringFilter>;
  promotions?: InputMaybe<PromotionListRelationFilter>;
  pushNotifications?: InputMaybe<PushNotificationListRelationFilter>;
  receivedPoints?: InputMaybe<ReceivedPointListRelationFilter>;
  rewards?: InputMaybe<RewardListRelationFilter>;
  state?: InputMaybe<StringFilter>;
  street?: InputMaybe<StringFilter>;
  timeZone?: InputMaybe<StringFilter>;
  visible?: InputMaybe<BoolFilter>;
};

export type Contact = {
  __typename: 'Contact';
  id: Scalars['ID']['output'];
  requestId?: Maybe<Scalars['String']['output']>;
};

export type ContactSupportInput = {
  error?: InputMaybe<Scalars['String']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  message: Scalars['String']['input'];
  subject: Scalars['String']['input'];
};

export type Coupon = {
  __typename: 'Coupon';
  _count: CouponCount;
  active: Scalars['Boolean']['output'];
  branchOffices?: Maybe<Array<BranchOffice>>;
  company: Company;
  /** The */
  companyId: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['UUID']['output'];
  imageTemplate?: Maybe<ImageTemplate>;
  imageTemplateId?: Maybe<Scalars['UUID']['output']>;
  /** The maximum number of possible redemptions for the coupon */
  maxRedemptions?: Maybe<Scalars['Int']['output']>;
  name: Scalars['String']['output'];
  periods?: Maybe<Array<CouponPeriod>>;
  pointsCredit?: Maybe<Scalars['Int']['output']>;
  /** The indicator of whether the coupon can still be redeemd or not */
  redeemable: Scalars['Boolean']['output'];
  redemptions?: Maybe<Array<RedeemedCoupon>>;
  /** The method that is used to transmission the reward redemtion to the company */
  transmissionMethod?: Maybe<TransmissionMethod>;
  type: CouponType;
  updatedAt: Scalars['DateTime']['output'];
};

export type CouponAvgAggregate = {
  __typename: 'CouponAvgAggregate';
  maxRedemptions?: Maybe<Scalars['Float']['output']>;
  pointsCredit?: Maybe<Scalars['Float']['output']>;
};

export type CouponCount = {
  __typename: 'CouponCount';
  branchOffices: Scalars['Int']['output'];
  periods: Scalars['Int']['output'];
  redemptions: Scalars['Int']['output'];
};

export type CouponCountAggregate = {
  __typename: 'CouponCountAggregate';
  _all: Scalars['Int']['output'];
  active: Scalars['Int']['output'];
  companyId: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  description: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  imageTemplateId: Scalars['Int']['output'];
  maxRedemptions: Scalars['Int']['output'];
  name: Scalars['Int']['output'];
  pointsCredit: Scalars['Int']['output'];
  redeemable: Scalars['Int']['output'];
  transmissionMethod: Scalars['Int']['output'];
  type: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
};

/** Input for creation of addition coupons */
export type CouponCreateInput = {
  /** The ID's of the branch offices this coupon should be configured for. If not set, the coupon is available for all branch offices of the company */
  branchOfficeIds?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Optional parameter to configure coupon for specific company */
  companyId?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  imageTemplate?: InputMaybe<ImageTemplateCreateNestedOneWithoutCouponsInput>;
  maxRedemptions?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  /** The period the coupon is active in */
  period: CreateCouponPeriodInput;
  pointsCredit?: InputMaybe<Scalars['Int']['input']>;
  transmissionMethod?: InputMaybe<TransmissionMethod>;
  /** The type of the coupon (ADDITION, MULTIPLICATION) */
  type: LimitedCouponType;
};

export type CouponCreateNestedManyWithoutImageTemplateInput = {
  connect?: InputMaybe<Array<CouponWhereUniqueInput>>;
};

export type CouponCreateNestedOneWithoutPeriodsInput = {
  connect?: InputMaybe<CouponWhereUniqueInput>;
};

export type CouponListRelationFilter = {
  every?: InputMaybe<CouponWhereInput>;
  none?: InputMaybe<CouponWhereInput>;
  some?: InputMaybe<CouponWhereInput>;
};

export type CouponMaxAggregate = {
  __typename: 'CouponMaxAggregate';
  active?: Maybe<Scalars['Boolean']['output']>;
  companyId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['UUID']['output']>;
  imageTemplateId?: Maybe<Scalars['UUID']['output']>;
  maxRedemptions?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  pointsCredit?: Maybe<Scalars['Int']['output']>;
  redeemable?: Maybe<Scalars['Boolean']['output']>;
  transmissionMethod?: Maybe<TransmissionMethod>;
  type?: Maybe<CouponType>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type CouponMinAggregate = {
  __typename: 'CouponMinAggregate';
  active?: Maybe<Scalars['Boolean']['output']>;
  companyId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['UUID']['output']>;
  imageTemplateId?: Maybe<Scalars['UUID']['output']>;
  maxRedemptions?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  pointsCredit?: Maybe<Scalars['Int']['output']>;
  redeemable?: Maybe<Scalars['Boolean']['output']>;
  transmissionMethod?: Maybe<TransmissionMethod>;
  type?: Maybe<CouponType>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type CouponOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type CouponOrderByWithRelationInput = {
  active?: InputMaybe<SortOrder>;
  branchOffices?: InputMaybe<BranchOfficeOrderByRelationAggregateInput>;
  company?: InputMaybe<CompanyOrderByWithRelationInput>;
  description?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  imageTemplate?: InputMaybe<ImageTemplateOrderByWithRelationInput>;
  maxRedemptions?: InputMaybe<SortOrderInput>;
  name?: InputMaybe<SortOrder>;
  periods?: InputMaybe<CouponPeriodOrderByRelationAggregateInput>;
  pointsCredit?: InputMaybe<SortOrderInput>;
  redeemable?: InputMaybe<SortOrder>;
  redemptions?: InputMaybe<RedeemedCouponOrderByRelationAggregateInput>;
  transmissionMethod?: InputMaybe<SortOrderInput>;
  type?: InputMaybe<SortOrder>;
};

export type CouponPeriod = {
  __typename: 'CouponPeriod';
  _count: CouponPeriodCount;
  activeFrom: Scalars['DateTime']['output'];
  activeUntil: Scalars['DateTime']['output'];
  coupon: Coupon;
  couponId: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['UUID']['output'];
  /** The number of redemptions of an coupon at this period */
  redemptionCount: Scalars['Int']['output'];
  redemptions?: Maybe<Array<RedeemedCoupon>>;
  updatedAt: Scalars['DateTime']['output'];
};

export type CouponPeriodAvgAggregate = {
  __typename: 'CouponPeriodAvgAggregate';
  redemptionCount?: Maybe<Scalars['Float']['output']>;
};

export type CouponPeriodCount = {
  __typename: 'CouponPeriodCount';
  redemptions: Scalars['Int']['output'];
};

export type CouponPeriodCountAggregate = {
  __typename: 'CouponPeriodCountAggregate';
  _all: Scalars['Int']['output'];
  activeFrom: Scalars['Int']['output'];
  activeUntil: Scalars['Int']['output'];
  couponId: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  redemptionCount: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
};

/** Input for creating coupon periods */
export type CouponPeriodCreateInput = {
  activeFrom: Scalars['DateTime']['input'];
  activeUntil: Scalars['DateTime']['input'];
  coupon: CouponCreateNestedOneWithoutPeriodsInput;
  redemptionCount?: InputMaybe<Scalars['Int']['input']>;
  redemptions?: InputMaybe<RedeemedCouponCreateNestedManyWithoutPeriodInput>;
};

export type CouponPeriodListRelationFilter = {
  every?: InputMaybe<CouponPeriodWhereInput>;
  none?: InputMaybe<CouponPeriodWhereInput>;
  some?: InputMaybe<CouponPeriodWhereInput>;
};

export type CouponPeriodMaxAggregate = {
  __typename: 'CouponPeriodMaxAggregate';
  activeFrom?: Maybe<Scalars['DateTime']['output']>;
  activeUntil?: Maybe<Scalars['DateTime']['output']>;
  couponId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['UUID']['output']>;
  redemptionCount?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type CouponPeriodMinAggregate = {
  __typename: 'CouponPeriodMinAggregate';
  activeFrom?: Maybe<Scalars['DateTime']['output']>;
  activeUntil?: Maybe<Scalars['DateTime']['output']>;
  couponId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['UUID']['output']>;
  redemptionCount?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type CouponPeriodNullableRelationFilter = {
  is?: InputMaybe<CouponPeriodWhereInput>;
  isNot?: InputMaybe<CouponPeriodWhereInput>;
};

export type CouponPeriodOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type CouponPeriodOrderByWithRelationInput = {
  activeFrom?: InputMaybe<SortOrder>;
  activeUntil?: InputMaybe<SortOrder>;
  coupon?: InputMaybe<CouponOrderByWithRelationInput>;
  couponId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  redemptionCount?: InputMaybe<SortOrder>;
  redemptions?: InputMaybe<RedeemedCouponOrderByRelationAggregateInput>;
};

export enum CouponPeriodScalarFieldEnum {
  ActiveFrom = 'activeFrom',
  ActiveUntil = 'activeUntil',
  CouponId = 'couponId',
  CreatedAt = 'createdAt',
  Id = 'id',
  RedemptionCount = 'redemptionCount',
  UpdatedAt = 'updatedAt'
}

export type CouponPeriodScalarWhereInput = {
  AND?: InputMaybe<Array<CouponPeriodScalarWhereInput>>;
  NOT?: InputMaybe<Array<CouponPeriodScalarWhereInput>>;
  OR?: InputMaybe<Array<CouponPeriodScalarWhereInput>>;
  activeFrom?: InputMaybe<DateTimeFilter>;
  activeUntil?: InputMaybe<DateTimeFilter>;
  couponId?: InputMaybe<UuidFilter>;
  id?: InputMaybe<UuidFilter>;
  redemptionCount?: InputMaybe<IntFilter>;
};

export type CouponPeriodSumAggregate = {
  __typename: 'CouponPeriodSumAggregate';
  redemptionCount?: Maybe<Scalars['Int']['output']>;
};

/** Input for updating a coupon period */
export type CouponPeriodUpdateInput = {
  activeFrom?: InputMaybe<Scalars['DateTime']['input']>;
  activeUntil?: InputMaybe<Scalars['DateTime']['input']>;
  redemptionCount?: InputMaybe<Scalars['Int']['input']>;
  redemptions?: InputMaybe<RedeemedCouponUpdateManyWithoutPeriodNestedInput>;
};

export type CouponPeriodUpdateManyMutationInput = {
  activeFrom?: InputMaybe<Scalars['DateTime']['input']>;
  activeUntil?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['UUID']['input']>;
  redemptionCount?: InputMaybe<Scalars['Int']['input']>;
};

export type CouponPeriodUpdateManyWithWhereWithoutCouponInput = {
  data: CouponPeriodUpdateManyMutationInput;
  where: CouponPeriodScalarWhereInput;
};

export type CouponPeriodUpdateManyWithoutCouponNestedInput = {
  connect?: InputMaybe<Array<CouponPeriodWhereUniqueInput>>;
  delete?: InputMaybe<Array<CouponPeriodWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CouponPeriodScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CouponPeriodWhereUniqueInput>>;
  set?: InputMaybe<Array<CouponPeriodWhereUniqueInput>>;
  updateMany?: InputMaybe<Array<CouponPeriodUpdateManyWithWhereWithoutCouponInput>>;
};

export type CouponPeriodWhereInput = {
  AND?: InputMaybe<Array<CouponPeriodWhereInput>>;
  NOT?: InputMaybe<Array<CouponPeriodWhereInput>>;
  OR?: InputMaybe<Array<CouponPeriodWhereInput>>;
  activeFrom?: InputMaybe<DateTimeFilter>;
  activeUntil?: InputMaybe<DateTimeFilter>;
  coupon?: InputMaybe<CouponRelationFilter>;
  couponId?: InputMaybe<UuidFilter>;
  id?: InputMaybe<UuidFilter>;
  redemptionCount?: InputMaybe<IntFilter>;
  redemptions?: InputMaybe<RedeemedCouponListRelationFilter>;
};

export type CouponPeriodWhereUniqueInput = {
  AND?: InputMaybe<Array<CouponPeriodWhereInput>>;
  NOT?: InputMaybe<Array<CouponPeriodWhereInput>>;
  OR?: InputMaybe<Array<CouponPeriodWhereInput>>;
  activeFrom?: InputMaybe<DateTimeFilter>;
  activeUntil?: InputMaybe<DateTimeFilter>;
  coupon?: InputMaybe<CouponRelationFilter>;
  couponId?: InputMaybe<UuidFilter>;
  id?: InputMaybe<Scalars['UUID']['input']>;
  redemptionCount?: InputMaybe<IntFilter>;
  redemptions?: InputMaybe<RedeemedCouponListRelationFilter>;
};

export type CouponRelationFilter = {
  is?: InputMaybe<CouponWhereInput>;
  isNot?: InputMaybe<CouponWhereInput>;
};

export enum CouponScalarFieldEnum {
  Active = 'active',
  CompanyId = 'companyId',
  CreatedAt = 'createdAt',
  Description = 'description',
  Id = 'id',
  ImageTemplateId = 'imageTemplateId',
  MaxRedemptions = 'maxRedemptions',
  Name = 'name',
  PointsCredit = 'pointsCredit',
  Redeemable = 'redeemable',
  TransmissionMethod = 'transmissionMethod',
  Type = 'type',
  UpdatedAt = 'updatedAt'
}

export type CouponScalarWhereInput = {
  AND?: InputMaybe<Array<CouponScalarWhereInput>>;
  NOT?: InputMaybe<Array<CouponScalarWhereInput>>;
  OR?: InputMaybe<Array<CouponScalarWhereInput>>;
  active?: InputMaybe<BoolFilter>;
  description?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<UuidFilter>;
  maxRedemptions?: InputMaybe<IntNullableFilter>;
  name?: InputMaybe<StringFilter>;
  pointsCredit?: InputMaybe<IntNullableFilter>;
  redeemable?: InputMaybe<BoolFilter>;
  transmissionMethod?: InputMaybe<EnumTransmissionMethodNullableFilter>;
  type?: InputMaybe<EnumCouponTypeFilter>;
};

/** Enum of status of coupons  */
export enum CouponState {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

export enum CouponStatus {
  Active = 'ACTIVE',
  Used = 'USED'
}

export type CouponSumAggregate = {
  __typename: 'CouponSumAggregate';
  maxRedemptions?: Maybe<Scalars['Int']['output']>;
  pointsCredit?: Maybe<Scalars['Int']['output']>;
};

export enum CouponType {
  Addition = 'ADDITION',
  Multiplication = 'MULTIPLICATION',
  Promotional = 'PROMOTIONAL',
  Welcome = 'WELCOME'
}

/** Input of unique identifier of a coupon */
export type CouponUniqueInput = {
  id: Scalars['String']['input'];
};

export type CouponUpdateInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  branchOffices?: InputMaybe<BranchOfficeUpdateManyWithoutCouponsNestedInput>;
  company?: InputMaybe<CompanyUpdateOneRequiredWithoutCouponsNestedInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['UUID']['input']>;
  imageTemplate?: InputMaybe<ImageTemplateUpdateOneWithoutCouponsNestedInput>;
  maxRedemptions?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  periods?: InputMaybe<CouponPeriodUpdateManyWithoutCouponNestedInput>;
  pointsCredit?: InputMaybe<Scalars['Int']['input']>;
  redeemable?: InputMaybe<Scalars['Boolean']['input']>;
  redemptions?: InputMaybe<RedeemedCouponUpdateManyWithoutCouponNestedInput>;
  transmissionMethod?: InputMaybe<TransmissionMethod>;
  type?: InputMaybe<CouponType>;
};

export type CouponUpdateManyMutationInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['UUID']['input']>;
  maxRedemptions?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  pointsCredit?: InputMaybe<Scalars['Int']['input']>;
  redeemable?: InputMaybe<Scalars['Boolean']['input']>;
  transmissionMethod?: InputMaybe<TransmissionMethod>;
  type?: InputMaybe<CouponType>;
};

export type CouponUpdateManyWithWhereWithoutBranchOfficesInput = {
  data: CouponUpdateManyMutationInput;
  where: CouponScalarWhereInput;
};

export type CouponUpdateManyWithWhereWithoutImageTemplateInput = {
  data: CouponUpdateManyMutationInput;
  where: CouponScalarWhereInput;
};

export type CouponUpdateManyWithoutBranchOfficesNestedInput = {
  connect?: InputMaybe<Array<CouponWhereUniqueInput>>;
  delete?: InputMaybe<Array<CouponWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CouponScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CouponWhereUniqueInput>>;
  set?: InputMaybe<Array<CouponWhereUniqueInput>>;
  updateMany?: InputMaybe<Array<CouponUpdateManyWithWhereWithoutBranchOfficesInput>>;
};

export type CouponUpdateManyWithoutImageTemplateNestedInput = {
  connect?: InputMaybe<Array<CouponWhereUniqueInput>>;
  delete?: InputMaybe<Array<CouponWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CouponScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CouponWhereUniqueInput>>;
  set?: InputMaybe<Array<CouponWhereUniqueInput>>;
  updateMany?: InputMaybe<Array<CouponUpdateManyWithWhereWithoutImageTemplateInput>>;
};

export type CouponWhereInput = {
  AND?: InputMaybe<Array<CouponWhereInput>>;
  NOT?: InputMaybe<Array<CouponWhereInput>>;
  OR?: InputMaybe<Array<CouponWhereInput>>;
  active?: InputMaybe<BoolFilter>;
  branchOffices?: InputMaybe<BranchOfficeListRelationFilter>;
  company?: InputMaybe<CompanyRelationFilter>;
  description?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<UuidFilter>;
  imageTemplate?: InputMaybe<ImageTemplateNullableRelationFilter>;
  maxRedemptions?: InputMaybe<IntNullableFilter>;
  name?: InputMaybe<StringFilter>;
  periods?: InputMaybe<CouponPeriodListRelationFilter>;
  pointsCredit?: InputMaybe<IntNullableFilter>;
  redeemable?: InputMaybe<BoolFilter>;
  redemptions?: InputMaybe<RedeemedCouponListRelationFilter>;
  transmissionMethod?: InputMaybe<EnumTransmissionMethodNullableFilter>;
  type?: InputMaybe<EnumCouponTypeFilter>;
};

export type CouponWhereUniqueInput = {
  AND?: InputMaybe<Array<CouponWhereInput>>;
  NOT?: InputMaybe<Array<CouponWhereInput>>;
  OR?: InputMaybe<Array<CouponWhereInput>>;
  active?: InputMaybe<BoolFilter>;
  branchOffices?: InputMaybe<BranchOfficeListRelationFilter>;
  company?: InputMaybe<CompanyRelationFilter>;
  description?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<Scalars['UUID']['input']>;
  imageTemplate?: InputMaybe<ImageTemplateNullableRelationFilter>;
  maxRedemptions?: InputMaybe<IntNullableFilter>;
  name?: InputMaybe<StringFilter>;
  periods?: InputMaybe<CouponPeriodListRelationFilter>;
  pointsCredit?: InputMaybe<IntNullableFilter>;
  redeemable?: InputMaybe<BoolFilter>;
  redemptions?: InputMaybe<RedeemedCouponListRelationFilter>;
  transmissionMethod?: InputMaybe<EnumTransmissionMethodNullableFilter>;
  type?: InputMaybe<EnumCouponTypeFilter>;
};

/** Custom type representing a coupon with a period */
export type CouponWithPeriodModel = {
  __typename: 'CouponWithPeriodModel';
  _count: CouponCount;
  active: Scalars['Boolean']['output'];
  branchOffices?: Maybe<Array<BranchOffice>>;
  company: Company;
  /** The */
  companyId: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['UUID']['output'];
  imageTemplate?: Maybe<ImageTemplate>;
  imageTemplateId?: Maybe<Scalars['UUID']['output']>;
  /** The maximum number of possible redemptions for the coupon */
  maxRedemptions?: Maybe<Scalars['Int']['output']>;
  name: Scalars['String']['output'];
  period?: Maybe<CouponPeriod>;
  pointsCredit?: Maybe<Scalars['Int']['output']>;
  /** The indicator of whether the coupon can still be redeemd or not */
  redeemable: Scalars['Boolean']['output'];
  redemptions?: Maybe<Array<RedeemedCoupon>>;
  /** The method that is used to transmission the reward redemtion to the company */
  transmissionMethod?: Maybe<TransmissionMethod>;
  type: CouponType;
  updatedAt: Scalars['DateTime']['output'];
};

/** Create input for a challenge period */
export type CreateChallengePeriodInput = {
  activeFrom: Scalars['DateTime']['input'];
  activeUntil: Scalars['DateTime']['input'];
};

/** Input for creating coupon periods */
export type CreateCouponPeriodInput = {
  activeFrom: Scalars['DateTime']['input'];
  activeUntil: Scalars['DateTime']['input'];
};

export type CreateOrChangeCalculationRuleInput = {
  /** Create a VALUE BASED calculation rule. */
  valueBasedInput?: InputMaybe<ChangeToValueBasedInput>;
  /** Create a VISIT BASED calculation rule. */
  visitBasedInput?: InputMaybe<ChangeToVisitBasedInput>;
};

/** Input for creating promotion period */
export type CreatePromotionPeriodInput = {
  activeFrom: Scalars['DateTime']['input'];
  activeUntil: Scalars['DateTime']['input'];
};

/** Custom input to create rewards */
export type CreateRewardInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  imageKey?: InputMaybe<Scalars['String']['input']>;
  imageTemplate?: InputMaybe<ImageTemplateCreateNestedOneWithoutRewardsInput>;
  name: Scalars['String']['input'];
  requiredPoints: Scalars['Int']['input'];
  saving?: InputMaybe<Scalars['Float']['input']>;
  transmissionMethod?: InputMaybe<TransmissionMethod>;
};

export enum CronInterval {
  Daily = 'DAILY',
  Monthly = 'MONTHLY',
  Weekly = 'WEEKLY',
  Yearly = 'YEARLY'
}

/** Input type for calculation rule with picked fields from original model */
export type CustomCalculationRuleInput = {
  points: Scalars['Int']['input'];
  value: Scalars['Float']['input'];
};

export type Customer = {
  __typename: 'Customer';
  _count: CustomerCount;
  balances?: Maybe<Array<Balance>>;
  birthDate?: Maybe<Scalars['DateTime']['output']>;
  challenges?: Maybe<Array<CustomerChallenge>>;
  city?: Maybe<Scalars['String']['output']>;
  company: Company;
  countryCode?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  crmConfig?: Maybe<CustomerCrmConfig>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  email: Scalars['EmailAddress']['output'];
  firstName?: Maybe<Scalars['String']['output']>;
  houseNumber?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  postalCode?: Maybe<Scalars['String']['output']>;
  pushToken?: Maybe<Scalars['String']['output']>;
  receivedPoints?: Maybe<Array<ReceivedPoint>>;
  redeemedCoupons?: Maybe<Array<RedeemedCoupon>>;
  redeemedPoints?: Maybe<Array<RedeemedPoint>>;
  state?: Maybe<Scalars['String']['output']>;
  statistic?: Maybe<CustomerStatistic>;
  street?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};


export type CustomerBalancesArgs = {
  sortOrder?: InputMaybe<SortOrder>;
};

/** Information about customers achievements */
export type CustomerAchievement = {
  __typename: 'CustomerAchievement';
  company: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  /** name of reward or coupon */
  name: Scalars['String']['output'];
};

export type CustomerChallenge = {
  __typename: 'CustomerChallenge';
  challenge: Challenge;
  challengeId: Scalars['UUID']['output'];
  createdAt: Scalars['DateTime']['output'];
  customer: Customer;
  customerId: Scalars['String']['output'];
  id: Scalars['UUID']['output'];
  isFinished: Scalars['Boolean']['output'];
  period: ChallengePeriod;
  periodId: Scalars['String']['output'];
  progress: Scalars['Int']['output'];
  receivedPoint?: Maybe<ReceivedPoint>;
  status: ChallengeStatus;
  updatedAt: Scalars['DateTime']['output'];
};

export type CustomerChallengeAvgAggregate = {
  __typename: 'CustomerChallengeAvgAggregate';
  progress?: Maybe<Scalars['Float']['output']>;
};

export type CustomerChallengeChallengeIdCustomerIdPeriodIdCompoundUniqueInput = {
  challengeId: Scalars['UUID']['input'];
  customerId: Scalars['String']['input'];
  periodId: Scalars['String']['input'];
};

export type CustomerChallengeCountAggregate = {
  __typename: 'CustomerChallengeCountAggregate';
  _all: Scalars['Int']['output'];
  challengeId: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  customerId: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  isFinished: Scalars['Int']['output'];
  periodId: Scalars['Int']['output'];
  progress: Scalars['Int']['output'];
  status: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
};

export type CustomerChallengeCreateNestedManyWithoutCustomerInput = {
  connect?: InputMaybe<Array<CustomerChallengeWhereUniqueInput>>;
};

export type CustomerChallengeCreateNestedManyWithoutPeriodInput = {
  connect?: InputMaybe<Array<CustomerChallengeWhereUniqueInput>>;
};

export type CustomerChallengeListRelationFilter = {
  every?: InputMaybe<CustomerChallengeWhereInput>;
  none?: InputMaybe<CustomerChallengeWhereInput>;
  some?: InputMaybe<CustomerChallengeWhereInput>;
};

export type CustomerChallengeMaxAggregate = {
  __typename: 'CustomerChallengeMaxAggregate';
  challengeId?: Maybe<Scalars['UUID']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  customerId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['UUID']['output']>;
  isFinished?: Maybe<Scalars['Boolean']['output']>;
  periodId?: Maybe<Scalars['String']['output']>;
  progress?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<ChallengeStatus>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type CustomerChallengeMinAggregate = {
  __typename: 'CustomerChallengeMinAggregate';
  challengeId?: Maybe<Scalars['UUID']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  customerId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['UUID']['output']>;
  isFinished?: Maybe<Scalars['Boolean']['output']>;
  periodId?: Maybe<Scalars['String']['output']>;
  progress?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<ChallengeStatus>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type CustomerChallengeNullableRelationFilter = {
  is?: InputMaybe<CustomerChallengeWhereInput>;
  isNot?: InputMaybe<CustomerChallengeWhereInput>;
};

export type CustomerChallengeOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type CustomerChallengeOrderByWithRelationInput = {
  challenge?: InputMaybe<ChallengeOrderByWithRelationInput>;
  challengeId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  customer?: InputMaybe<CustomerOrderByWithRelationInput>;
  customerId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isFinished?: InputMaybe<SortOrder>;
  period?: InputMaybe<ChallengePeriodOrderByWithRelationInput>;
  periodId?: InputMaybe<SortOrder>;
  progress?: InputMaybe<SortOrder>;
  receivedPoint?: InputMaybe<ReceivedPointOrderByWithRelationInput>;
  status?: InputMaybe<SortOrder>;
};

export enum CustomerChallengeScalarFieldEnum {
  ChallengeId = 'challengeId',
  CreatedAt = 'createdAt',
  CustomerId = 'customerId',
  Id = 'id',
  IsFinished = 'isFinished',
  PeriodId = 'periodId',
  Progress = 'progress',
  Status = 'status',
  UpdatedAt = 'updatedAt'
}

export type CustomerChallengeScalarWhereInput = {
  AND?: InputMaybe<Array<CustomerChallengeScalarWhereInput>>;
  NOT?: InputMaybe<Array<CustomerChallengeScalarWhereInput>>;
  OR?: InputMaybe<Array<CustomerChallengeScalarWhereInput>>;
  challengeId?: InputMaybe<UuidFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  customerId?: InputMaybe<StringFilter>;
  id?: InputMaybe<UuidFilter>;
  isFinished?: InputMaybe<BoolFilter>;
  periodId?: InputMaybe<UuidFilter>;
  progress?: InputMaybe<IntFilter>;
  status?: InputMaybe<EnumChallengeStatusFilter>;
};

export type CustomerChallengeSumAggregate = {
  __typename: 'CustomerChallengeSumAggregate';
  progress?: Maybe<Scalars['Int']['output']>;
};

export type CustomerChallengeUpdateManyMutationInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['UUID']['input']>;
  isFinished?: InputMaybe<Scalars['Boolean']['input']>;
  progress?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<ChallengeStatus>;
};

export type CustomerChallengeUpdateManyWithWhereWithoutCustomerInput = {
  data: CustomerChallengeUpdateManyMutationInput;
  where: CustomerChallengeScalarWhereInput;
};

export type CustomerChallengeUpdateManyWithWhereWithoutPeriodInput = {
  data: CustomerChallengeUpdateManyMutationInput;
  where: CustomerChallengeScalarWhereInput;
};

export type CustomerChallengeUpdateManyWithoutCustomerNestedInput = {
  connect?: InputMaybe<Array<CustomerChallengeWhereUniqueInput>>;
  delete?: InputMaybe<Array<CustomerChallengeWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CustomerChallengeScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CustomerChallengeWhereUniqueInput>>;
  set?: InputMaybe<Array<CustomerChallengeWhereUniqueInput>>;
  updateMany?: InputMaybe<Array<CustomerChallengeUpdateManyWithWhereWithoutCustomerInput>>;
};

export type CustomerChallengeUpdateManyWithoutPeriodNestedInput = {
  connect?: InputMaybe<Array<CustomerChallengeWhereUniqueInput>>;
  delete?: InputMaybe<Array<CustomerChallengeWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CustomerChallengeScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CustomerChallengeWhereUniqueInput>>;
  set?: InputMaybe<Array<CustomerChallengeWhereUniqueInput>>;
  updateMany?: InputMaybe<Array<CustomerChallengeUpdateManyWithWhereWithoutPeriodInput>>;
};

export type CustomerChallengeUpdateOneWithoutReceivedPointNestedInput = {
  connect?: InputMaybe<CustomerChallengeWhereUniqueInput>;
  delete?: InputMaybe<CustomerChallengeWhereInput>;
  disconnect?: InputMaybe<CustomerChallengeWhereInput>;
};

export type CustomerChallengeWhereInput = {
  AND?: InputMaybe<Array<CustomerChallengeWhereInput>>;
  NOT?: InputMaybe<Array<CustomerChallengeWhereInput>>;
  OR?: InputMaybe<Array<CustomerChallengeWhereInput>>;
  challenge?: InputMaybe<ChallengeRelationFilter>;
  challengeId?: InputMaybe<UuidFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  customer?: InputMaybe<CustomerRelationFilter>;
  customerId?: InputMaybe<StringFilter>;
  id?: InputMaybe<UuidFilter>;
  isFinished?: InputMaybe<BoolFilter>;
  period?: InputMaybe<ChallengePeriodRelationFilter>;
  periodId?: InputMaybe<UuidFilter>;
  progress?: InputMaybe<IntFilter>;
  receivedPoint?: InputMaybe<ReceivedPointNullableRelationFilter>;
  status?: InputMaybe<EnumChallengeStatusFilter>;
};

export type CustomerChallengeWhereUniqueInput = {
  AND?: InputMaybe<Array<CustomerChallengeWhereInput>>;
  NOT?: InputMaybe<Array<CustomerChallengeWhereInput>>;
  OR?: InputMaybe<Array<CustomerChallengeWhereInput>>;
  challenge?: InputMaybe<ChallengeRelationFilter>;
  challengeId?: InputMaybe<UuidFilter>;
  challengeId_customerId_periodId?: InputMaybe<CustomerChallengeChallengeIdCustomerIdPeriodIdCompoundUniqueInput>;
  createdAt?: InputMaybe<DateTimeFilter>;
  customer?: InputMaybe<CustomerRelationFilter>;
  customerId?: InputMaybe<StringFilter>;
  id?: InputMaybe<Scalars['UUID']['input']>;
  isFinished?: InputMaybe<BoolFilter>;
  period?: InputMaybe<ChallengePeriodRelationFilter>;
  periodId?: InputMaybe<UuidFilter>;
  progress?: InputMaybe<IntFilter>;
  receivedPoint?: InputMaybe<ReceivedPointNullableRelationFilter>;
  status?: InputMaybe<EnumChallengeStatusFilter>;
};

export type CustomerCount = {
  __typename: 'CustomerCount';
  balances: Scalars['Int']['output'];
  challenges: Scalars['Int']['output'];
  receivedPoints: Scalars['Int']['output'];
  redeemedCoupons: Scalars['Int']['output'];
  redeemedPoints: Scalars['Int']['output'];
};

export type CustomerCountAggregate = {
  __typename: 'CustomerCountAggregate';
  _all: Scalars['Int']['output'];
  birthDate: Scalars['Int']['output'];
  city: Scalars['Int']['output'];
  countryCode: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  deletedAt: Scalars['Int']['output'];
  email: Scalars['Int']['output'];
  firstName: Scalars['Int']['output'];
  houseNumber: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  lastName: Scalars['Int']['output'];
  postalCode: Scalars['Int']['output'];
  pushToken: Scalars['Int']['output'];
  state: Scalars['Int']['output'];
  street: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
};

export type CustomerCreateInput = {
  balances?: InputMaybe<BalanceCreateNestedManyWithoutCustomerInput>;
  birthDate?: InputMaybe<Scalars['DateTime']['input']>;
  challenges?: InputMaybe<CustomerChallengeCreateNestedManyWithoutCustomerInput>;
  city?: InputMaybe<Scalars['String']['input']>;
  company: CompanyCreateNestedOneWithoutCustomersInput;
  countryCode?: InputMaybe<Scalars['String']['input']>;
  crmConfig?: InputMaybe<CustomerCrmConfigCreateNestedOneWithoutCustomerInput>;
  deletedAt?: InputMaybe<Scalars['DateTime']['input']>;
  email: Scalars['EmailAddress']['input'];
  firstName?: InputMaybe<Scalars['String']['input']>;
  houseNumber?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  lastName?: InputMaybe<Scalars['String']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  pushToken?: InputMaybe<Scalars['String']['input']>;
  receivedPoints?: InputMaybe<ReceivedPointCreateNestedManyWithoutCustomerInput>;
  redeemedCoupons?: InputMaybe<RedeemedCouponCreateNestedManyWithoutCustomerInput>;
  redeemedPoints?: InputMaybe<RedeemedPointCreateNestedManyWithoutCustomerInput>;
  state?: InputMaybe<Scalars['String']['input']>;
  statistic?: InputMaybe<CustomerStatisticCreateNestedOneWithoutCustomerInput>;
  street?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Non nullable customer connect type */
export type CustomerCreateNestedOneWithoutCrmConfigInput = {
  connect: CustomerWhereUniqueInput;
};

export type CustomerCreateNestedOneWithoutRedeemedPointsInput = {
  connect?: InputMaybe<CustomerWhereUniqueInput>;
};

export type CustomerCrmConfig = {
  __typename: 'CustomerCrmConfig';
  /** The unique identifier in the crm system */
  crmCustomerId: Scalars['ID']['output'];
  /** The identifier of studio the customer is registered at */
  crmStudioId: Scalars['String']['output'];
  customer: Customer;
  customerId: Scalars['String']['output'];
};

export type CustomerCrmConfigCountAggregate = {
  __typename: 'CustomerCrmConfigCountAggregate';
  _all: Scalars['Int']['output'];
  crmCustomerId: Scalars['Int']['output'];
  crmStudioId: Scalars['Int']['output'];
  customerId: Scalars['Int']['output'];
};

/** Create input for customer crm config */
export type CustomerCrmConfigCreateInput = {
  crmCustomerId: Scalars['String']['input'];
  crmStudioId: Scalars['String']['input'];
  /** Connect a customer */
  customer: CustomerCreateNestedOneWithoutCrmConfigInput;
};

export type CustomerCrmConfigCreateNestedOneWithoutCustomerInput = {
  connect?: InputMaybe<CustomerCrmConfigWhereUniqueInput>;
};

export type CustomerCrmConfigMaxAggregate = {
  __typename: 'CustomerCrmConfigMaxAggregate';
  crmCustomerId?: Maybe<Scalars['String']['output']>;
  crmStudioId?: Maybe<Scalars['String']['output']>;
  customerId?: Maybe<Scalars['String']['output']>;
};

export type CustomerCrmConfigMinAggregate = {
  __typename: 'CustomerCrmConfigMinAggregate';
  crmCustomerId?: Maybe<Scalars['String']['output']>;
  crmStudioId?: Maybe<Scalars['String']['output']>;
  customerId?: Maybe<Scalars['String']['output']>;
};

export type CustomerCrmConfigNullableRelationFilter = {
  is?: InputMaybe<CustomerCrmConfigWhereInput>;
  isNot?: InputMaybe<CustomerCrmConfigWhereInput>;
};

export type CustomerCrmConfigOrderByWithRelationInput = {
  crmCustomerId?: InputMaybe<SortOrder>;
  crmStudioId?: InputMaybe<SortOrder>;
  customer?: InputMaybe<CustomerOrderByWithRelationInput>;
  customerId?: InputMaybe<SortOrder>;
};

export enum CustomerCrmConfigScalarFieldEnum {
  CrmCustomerId = 'crmCustomerId',
  CrmStudioId = 'crmStudioId',
  CustomerId = 'customerId'
}

/** Input for updating a customer crm config */
export type CustomerCrmConfigUpdateInput = {
  crmCustomerId?: InputMaybe<Scalars['String']['input']>;
  crmStudioId?: InputMaybe<Scalars['String']['input']>;
};

export type CustomerCrmConfigUpdateOneWithoutCustomerNestedInput = {
  connect?: InputMaybe<CustomerCrmConfigWhereUniqueInput>;
  delete?: InputMaybe<CustomerCrmConfigWhereInput>;
  disconnect?: InputMaybe<CustomerCrmConfigWhereInput>;
};

export type CustomerCrmConfigWhereInput = {
  AND?: InputMaybe<Array<CustomerCrmConfigWhereInput>>;
  NOT?: InputMaybe<Array<CustomerCrmConfigWhereInput>>;
  OR?: InputMaybe<Array<CustomerCrmConfigWhereInput>>;
  crmCustomerId?: InputMaybe<StringFilter>;
  crmStudioId?: InputMaybe<StringFilter>;
  customer?: InputMaybe<CustomerRelationFilter>;
  customerId?: InputMaybe<StringFilter>;
};

export type CustomerCrmConfigWhereUniqueInput = {
  AND?: InputMaybe<Array<CustomerCrmConfigWhereInput>>;
  NOT?: InputMaybe<Array<CustomerCrmConfigWhereInput>>;
  OR?: InputMaybe<Array<CustomerCrmConfigWhereInput>>;
  crmCustomerId?: InputMaybe<Scalars['String']['input']>;
  crmStudioId?: InputMaybe<StringFilter>;
  customer?: InputMaybe<CustomerRelationFilter>;
  customerId?: InputMaybe<Scalars['String']['input']>;
};

export type CustomerEdge = {
  __typename: 'CustomerEdge';
  cursor: Scalars['String']['output'];
  node: Customer;
};

export type CustomerListRelationFilter = {
  every?: InputMaybe<CustomerWhereInput>;
  none?: InputMaybe<CustomerWhereInput>;
  some?: InputMaybe<CustomerWhereInput>;
};

export type CustomerMaxAggregate = {
  __typename: 'CustomerMaxAggregate';
  birthDate?: Maybe<Scalars['DateTime']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  countryCode?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  email?: Maybe<Scalars['EmailAddress']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  houseNumber?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  postalCode?: Maybe<Scalars['String']['output']>;
  pushToken?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  street?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type CustomerMinAggregate = {
  __typename: 'CustomerMinAggregate';
  birthDate?: Maybe<Scalars['DateTime']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  countryCode?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  email?: Maybe<Scalars['EmailAddress']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  houseNumber?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  postalCode?: Maybe<Scalars['String']['output']>;
  pushToken?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  street?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type CustomerOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type CustomerOrderByWithRelationInput = {
  balances?: InputMaybe<BalanceOrderByRelationAggregateInput>;
  birthDate?: InputMaybe<SortOrderInput>;
  challenges?: InputMaybe<CustomerChallengeOrderByRelationAggregateInput>;
  city?: InputMaybe<SortOrderInput>;
  company?: InputMaybe<CompanyOrderByWithRelationInput>;
  companyId?: InputMaybe<SortOrder>;
  countryCode?: InputMaybe<SortOrderInput>;
  createdAt?: InputMaybe<SortOrder>;
  crmConfig?: InputMaybe<CustomerCrmConfigOrderByWithRelationInput>;
  deletedAt?: InputMaybe<SortOrderInput>;
  email?: InputMaybe<SortOrder>;
  firstName?: InputMaybe<SortOrderInput>;
  houseNumber?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  lastName?: InputMaybe<SortOrderInput>;
  postalCode?: InputMaybe<SortOrderInput>;
  pushToken?: InputMaybe<SortOrderInput>;
  receivedPoints?: InputMaybe<ReceivedPointOrderByRelationAggregateInput>;
  redeemedCoupons?: InputMaybe<RedeemedCouponOrderByRelationAggregateInput>;
  redeemedPoints?: InputMaybe<RedeemedPointOrderByRelationAggregateInput>;
  state?: InputMaybe<SortOrderInput>;
  statistic?: InputMaybe<CustomerStatisticOrderByWithRelationInput>;
  street?: InputMaybe<SortOrderInput>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type CustomerRelationFilter = {
  is?: InputMaybe<CustomerWhereInput>;
  isNot?: InputMaybe<CustomerWhereInput>;
};

export enum CustomerScalarFieldEnum {
  BirthDate = 'birthDate',
  City = 'city',
  CompanyId = 'companyId',
  CountryCode = 'countryCode',
  CreatedAt = 'createdAt',
  DeletedAt = 'deletedAt',
  Email = 'email',
  FirstName = 'firstName',
  HouseNumber = 'houseNumber',
  Id = 'id',
  LastName = 'lastName',
  PostalCode = 'postalCode',
  PushToken = 'pushToken',
  State = 'state',
  Street = 'street',
  UpdatedAt = 'updatedAt'
}

/** Possible states of customer */
export enum CustomerStateEnum {
  Disabled = 'DISABLED',
  Enabled = 'ENABLED'
}

export type CustomerStatistic = {
  __typename: 'CustomerStatistic';
  challengePoints: Scalars['Int']['output'];
  couponPoints: Scalars['Int']['output'];
  createdAt: Scalars['DateTime']['output'];
  customer: Customer;
  customerId: Scalars['String']['output'];
  id: Scalars['UUID']['output'];
  savedMoney: Scalars['Float']['output'];
  totalPoints: Scalars['Int']['output'];
  trainingPoints: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type CustomerStatisticAvgAggregate = {
  __typename: 'CustomerStatisticAvgAggregate';
  challengePoints?: Maybe<Scalars['Float']['output']>;
  couponPoints?: Maybe<Scalars['Float']['output']>;
  savedMoney?: Maybe<Scalars['Float']['output']>;
  totalPoints?: Maybe<Scalars['Float']['output']>;
  trainingPoints?: Maybe<Scalars['Float']['output']>;
};

export type CustomerStatisticCountAggregate = {
  __typename: 'CustomerStatisticCountAggregate';
  _all: Scalars['Int']['output'];
  challengePoints: Scalars['Int']['output'];
  couponPoints: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  customerId: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  savedMoney: Scalars['Int']['output'];
  totalPoints: Scalars['Int']['output'];
  trainingPoints: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
};

export type CustomerStatisticCreateNestedOneWithoutCustomerInput = {
  connect?: InputMaybe<CustomerStatisticWhereUniqueInput>;
};

export type CustomerStatisticMaxAggregate = {
  __typename: 'CustomerStatisticMaxAggregate';
  challengePoints?: Maybe<Scalars['Int']['output']>;
  couponPoints?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  customerId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['UUID']['output']>;
  savedMoney?: Maybe<Scalars['Float']['output']>;
  totalPoints?: Maybe<Scalars['Int']['output']>;
  trainingPoints?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type CustomerStatisticMinAggregate = {
  __typename: 'CustomerStatisticMinAggregate';
  challengePoints?: Maybe<Scalars['Int']['output']>;
  couponPoints?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  customerId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['UUID']['output']>;
  savedMoney?: Maybe<Scalars['Float']['output']>;
  totalPoints?: Maybe<Scalars['Int']['output']>;
  trainingPoints?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type CustomerStatisticNullableRelationFilter = {
  is?: InputMaybe<CustomerStatisticWhereInput>;
  isNot?: InputMaybe<CustomerStatisticWhereInput>;
};

export type CustomerStatisticOrderByWithRelationInput = {
  challengePoints?: InputMaybe<SortOrder>;
  couponPoints?: InputMaybe<SortOrder>;
  customer?: InputMaybe<CustomerOrderByWithRelationInput>;
  id?: InputMaybe<SortOrder>;
  savedMoney?: InputMaybe<SortOrder>;
  totalPoints?: InputMaybe<SortOrder>;
  trainingPoints?: InputMaybe<SortOrder>;
};

export enum CustomerStatisticScalarFieldEnum {
  ChallengePoints = 'challengePoints',
  CouponPoints = 'couponPoints',
  CreatedAt = 'createdAt',
  CustomerId = 'customerId',
  Id = 'id',
  SavedMoney = 'savedMoney',
  TotalPoints = 'totalPoints',
  TrainingPoints = 'trainingPoints',
  UpdatedAt = 'updatedAt'
}

export type CustomerStatisticSumAggregate = {
  __typename: 'CustomerStatisticSumAggregate';
  challengePoints?: Maybe<Scalars['Int']['output']>;
  couponPoints?: Maybe<Scalars['Int']['output']>;
  savedMoney?: Maybe<Scalars['Float']['output']>;
  totalPoints?: Maybe<Scalars['Int']['output']>;
  trainingPoints?: Maybe<Scalars['Int']['output']>;
};

export type CustomerStatisticUpdateOneWithoutCustomerNestedInput = {
  connect?: InputMaybe<CustomerStatisticWhereUniqueInput>;
  delete?: InputMaybe<CustomerStatisticWhereInput>;
  disconnect?: InputMaybe<CustomerStatisticWhereInput>;
};

export type CustomerStatisticWhereInput = {
  AND?: InputMaybe<Array<CustomerStatisticWhereInput>>;
  NOT?: InputMaybe<Array<CustomerStatisticWhereInput>>;
  OR?: InputMaybe<Array<CustomerStatisticWhereInput>>;
  challengePoints?: InputMaybe<IntFilter>;
  couponPoints?: InputMaybe<IntFilter>;
  customer?: InputMaybe<CustomerRelationFilter>;
  id?: InputMaybe<UuidFilter>;
  savedMoney?: InputMaybe<FloatFilter>;
  totalPoints?: InputMaybe<IntFilter>;
  trainingPoints?: InputMaybe<IntFilter>;
};

export type CustomerStatisticWhereUniqueInput = {
  AND?: InputMaybe<Array<CustomerStatisticWhereInput>>;
  NOT?: InputMaybe<Array<CustomerStatisticWhereInput>>;
  OR?: InputMaybe<Array<CustomerStatisticWhereInput>>;
  challengePoints?: InputMaybe<IntFilter>;
  couponPoints?: InputMaybe<IntFilter>;
  customer?: InputMaybe<CustomerRelationFilter>;
  id?: InputMaybe<Scalars['UUID']['input']>;
  savedMoney?: InputMaybe<FloatFilter>;
  totalPoints?: InputMaybe<IntFilter>;
  trainingPoints?: InputMaybe<IntFilter>;
};

export type CustomerTopRanking = {
  __typename: 'CustomerTopRanking';
  email: Scalars['EmailAddress']['output'];
  firstName?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  receivedPointsTotal: Scalars['Float']['output'];
  redeemedPointsTotal: Scalars['Float']['output'];
};

/** Information about customers training overview */
export type CustomerTrainingOverview = {
  __typename: 'CustomerTrainingOverview';
  label?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

/** Information about customers series */
export type CustomerTrainingSeries = {
  __typename: 'CustomerTrainingSeries';
  trainingsCount?: Maybe<Scalars['Float']['output']>;
  weekCount?: Maybe<Scalars['Float']['output']>;
};

export type CustomerUpdateInput = {
  balances?: InputMaybe<BalanceUpdateManyWithoutCustomerNestedInput>;
  birthDate?: InputMaybe<Scalars['DateTime']['input']>;
  challenges?: InputMaybe<CustomerChallengeUpdateManyWithoutCustomerNestedInput>;
  city?: InputMaybe<Scalars['String']['input']>;
  company?: InputMaybe<CompanyUpdateOneRequiredWithoutCustomersNestedInput>;
  countryCode?: InputMaybe<Scalars['String']['input']>;
  crmConfig?: InputMaybe<CustomerCrmConfigUpdateOneWithoutCustomerNestedInput>;
  deletedAt?: InputMaybe<Scalars['DateTime']['input']>;
  email?: InputMaybe<Scalars['EmailAddress']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  houseNumber?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  pushToken?: InputMaybe<Scalars['String']['input']>;
  receivedPoints?: InputMaybe<ReceivedPointUpdateManyWithoutCustomerNestedInput>;
  redeemedCoupons?: InputMaybe<RedeemedCouponUpdateManyWithoutCustomerNestedInput>;
  redeemedPoints?: InputMaybe<RedeemedPointUpdateManyWithoutCustomerNestedInput>;
  state?: InputMaybe<Scalars['String']['input']>;
  statistic?: InputMaybe<CustomerStatisticUpdateOneWithoutCustomerNestedInput>;
  street?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type CustomerUpdateOneRequiredWithoutReceivedPointsNestedInput = {
  connect?: InputMaybe<CustomerWhereUniqueInput>;
};

export type CustomerUpdateOneRequiredWithoutRedeemedPointsNestedInput = {
  connect?: InputMaybe<CustomerWhereUniqueInput>;
};

export type CustomerWhereInput = {
  AND?: InputMaybe<Array<CustomerWhereInput>>;
  NOT?: InputMaybe<Array<CustomerWhereInput>>;
  OR?: InputMaybe<Array<CustomerWhereInput>>;
  balances?: InputMaybe<BalanceListRelationFilter>;
  birthDate?: InputMaybe<DateTimeNullableFilter>;
  challenges?: InputMaybe<CustomerChallengeListRelationFilter>;
  city?: InputMaybe<StringNullableFilter>;
  company?: InputMaybe<CompanyRelationFilter>;
  companyId?: InputMaybe<StringFilter>;
  countryCode?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  crmConfig?: InputMaybe<CustomerCrmConfigNullableRelationFilter>;
  deletedAt?: InputMaybe<DateTimeNullableFilter>;
  email?: InputMaybe<StringFilter>;
  firstName?: InputMaybe<StringNullableFilter>;
  houseNumber?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  lastName?: InputMaybe<StringNullableFilter>;
  postalCode?: InputMaybe<StringNullableFilter>;
  pushToken?: InputMaybe<StringNullableFilter>;
  receivedPoints?: InputMaybe<ReceivedPointListRelationFilter>;
  redeemedCoupons?: InputMaybe<RedeemedCouponListRelationFilter>;
  redeemedPoints?: InputMaybe<RedeemedPointListRelationFilter>;
  state?: InputMaybe<StringNullableFilter>;
  statistic?: InputMaybe<CustomerStatisticNullableRelationFilter>;
  street?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type CustomerWhereUniqueInput = {
  AND?: InputMaybe<Array<CustomerWhereInput>>;
  NOT?: InputMaybe<Array<CustomerWhereInput>>;
  OR?: InputMaybe<Array<CustomerWhereInput>>;
  balances?: InputMaybe<BalanceListRelationFilter>;
  birthDate?: InputMaybe<DateTimeNullableFilter>;
  challenges?: InputMaybe<CustomerChallengeListRelationFilter>;
  city?: InputMaybe<StringNullableFilter>;
  company?: InputMaybe<CompanyRelationFilter>;
  companyId?: InputMaybe<StringFilter>;
  countryCode?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  crmConfig?: InputMaybe<CustomerCrmConfigNullableRelationFilter>;
  deletedAt?: InputMaybe<DateTimeNullableFilter>;
  email?: InputMaybe<StringFilter>;
  firstName?: InputMaybe<StringNullableFilter>;
  houseNumber?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<StringNullableFilter>;
  postalCode?: InputMaybe<StringNullableFilter>;
  pushToken?: InputMaybe<StringNullableFilter>;
  receivedPoints?: InputMaybe<ReceivedPointListRelationFilter>;
  redeemedCoupons?: InputMaybe<RedeemedCouponListRelationFilter>;
  redeemedPoints?: InputMaybe<RedeemedPointListRelationFilter>;
  state?: InputMaybe<StringNullableFilter>;
  statistic?: InputMaybe<CustomerStatisticNullableRelationFilter>;
  street?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type DateGroupedCount = {
  __typename: 'DateGroupedCount';
  count: Scalars['Float']['output'];
  date: Scalars['DateTime']['output'];
};

export type DateTimeFilter = {
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  not?: InputMaybe<NestedDateTimeFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

export type DateTimeNullableFilter = {
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  not?: InputMaybe<NestedDateTimeNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

export type DecimalFilter = {
  equals?: InputMaybe<Scalars['Decimal']['input']>;
  gt?: InputMaybe<Scalars['Decimal']['input']>;
  gte?: InputMaybe<Scalars['Decimal']['input']>;
  in?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  lt?: InputMaybe<Scalars['Decimal']['input']>;
  lte?: InputMaybe<Scalars['Decimal']['input']>;
  not?: InputMaybe<NestedDecimalFilter>;
  notIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
};

export type DecimalNullableFilter = {
  equals?: InputMaybe<Scalars['Decimal']['input']>;
  gt?: InputMaybe<Scalars['Decimal']['input']>;
  gte?: InputMaybe<Scalars['Decimal']['input']>;
  in?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  lt?: InputMaybe<Scalars['Decimal']['input']>;
  lte?: InputMaybe<Scalars['Decimal']['input']>;
  not?: InputMaybe<NestedDecimalNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
};

/** Input for disable challenges */
export type DisableChallengeInput = {
  /** Only needs to be set to finally disable a passive points challenge */
  finalDisable?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Employee = {
  __typename: 'Employee';
  company: Company;
  companyId: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  email: Scalars['EmailAddress']['output'];
  firstName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  invitationAcceptedAt?: Maybe<Scalars['DateTime']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  status: EmployeeStatus;
  updatedAt: Scalars['DateTime']['output'];
  userType: CompanyUserType;
};

export type EmployeeCountAggregate = {
  __typename: 'EmployeeCountAggregate';
  _all: Scalars['Int']['output'];
  companyId: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  email: Scalars['Int']['output'];
  firstName: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  invitationAcceptedAt: Scalars['Int']['output'];
  lastName: Scalars['Int']['output'];
  status: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
  userType: Scalars['Int']['output'];
};

export type EmployeeEmployeeIdentifierCompoundUniqueInput = {
  companyId: Scalars['String']['input'];
  email: Scalars['EmailAddress']['input'];
};

/** Input of employee identifier with optional field companyId */
export type EmployeeIdentifierInput = {
  /** The ID of the company the employee is member of */
  companyId?: InputMaybe<Scalars['String']['input']>;
  /** The EMail address of the employee to be delted */
  email: Scalars['EmailAddress']['input'];
};

export type EmployeeListRelationFilter = {
  every?: InputMaybe<EmployeeWhereInput>;
  none?: InputMaybe<EmployeeWhereInput>;
  some?: InputMaybe<EmployeeWhereInput>;
};

export type EmployeeMaxAggregate = {
  __typename: 'EmployeeMaxAggregate';
  companyId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  email?: Maybe<Scalars['EmailAddress']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  invitationAcceptedAt?: Maybe<Scalars['DateTime']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  status?: Maybe<EmployeeStatus>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  userType?: Maybe<CompanyUserType>;
};

export type EmployeeMinAggregate = {
  __typename: 'EmployeeMinAggregate';
  companyId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  email?: Maybe<Scalars['EmailAddress']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  invitationAcceptedAt?: Maybe<Scalars['DateTime']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  status?: Maybe<EmployeeStatus>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  userType?: Maybe<CompanyUserType>;
};

export type EmployeeOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type EmployeeOrderByWithRelationInput = {
  company?: InputMaybe<CompanyOrderByWithRelationInput>;
  companyId?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  firstName?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrderInput>;
  lastName?: InputMaybe<SortOrderInput>;
  status?: InputMaybe<SortOrder>;
  userType?: InputMaybe<SortOrder>;
};

export enum EmployeeScalarFieldEnum {
  CompanyId = 'companyId',
  CreatedAt = 'createdAt',
  Email = 'email',
  FirstName = 'firstName',
  Id = 'id',
  InvitationAcceptedAt = 'invitationAcceptedAt',
  LastName = 'lastName',
  Status = 'status',
  UpdatedAt = 'updatedAt',
  UserType = 'userType'
}

export enum EmployeeStatus {
  Invited = 'INVITED',
  Registered = 'REGISTERED'
}

/** Unique input to identify an employee */
export type EmployeeUniqueInput = {
  /** The unique combination of email address and company id */
  employeeIdentifier: EmployeeEmployeeIdentifierCompoundUniqueInput;
};

export type EmployeeWhereInput = {
  AND?: InputMaybe<Array<EmployeeWhereInput>>;
  NOT?: InputMaybe<Array<EmployeeWhereInput>>;
  OR?: InputMaybe<Array<EmployeeWhereInput>>;
  company?: InputMaybe<CompanyRelationFilter>;
  companyId?: InputMaybe<StringFilter>;
  email?: InputMaybe<StringFilter>;
  firstName?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringNullableFilter>;
  lastName?: InputMaybe<StringNullableFilter>;
  status?: InputMaybe<EnumEmployeeStatusFilter>;
  userType?: InputMaybe<EnumCompanyUserTypeFilter>;
};

export type EmployeeWhereUniqueInput = {
  AND?: InputMaybe<Array<EmployeeWhereInput>>;
  NOT?: InputMaybe<Array<EmployeeWhereInput>>;
  OR?: InputMaybe<Array<EmployeeWhereInput>>;
  company?: InputMaybe<CompanyRelationFilter>;
  companyId?: InputMaybe<StringFilter>;
  email?: InputMaybe<StringFilter>;
  employeeIdentifier?: InputMaybe<EmployeeEmployeeIdentifierCompoundUniqueInput>;
  firstName?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<StringNullableFilter>;
  status?: InputMaybe<EnumEmployeeStatusFilter>;
  userType?: InputMaybe<EnumCompanyUserTypeFilter>;
};

export type EnumChallengeStatusFilter = {
  equals?: InputMaybe<ChallengeStatus>;
  in?: InputMaybe<Array<ChallengeStatus>>;
  not?: InputMaybe<NestedEnumChallengeStatusFilter>;
  notIn?: InputMaybe<Array<ChallengeStatus>>;
};

export type EnumChallengeTypeFilter = {
  equals?: InputMaybe<ChallengeType>;
  in?: InputMaybe<Array<ChallengeType>>;
  not?: InputMaybe<NestedEnumChallengeTypeFilter>;
  notIn?: InputMaybe<Array<ChallengeType>>;
};

export type EnumCompanyUserTypeFilter = {
  equals?: InputMaybe<CompanyUserType>;
  in?: InputMaybe<Array<CompanyUserType>>;
  not?: InputMaybe<NestedEnumCompanyUserTypeFilter>;
  notIn?: InputMaybe<Array<CompanyUserType>>;
};

export type EnumCouponStatusFilter = {
  equals?: InputMaybe<CouponStatus>;
  in?: InputMaybe<Array<CouponStatus>>;
  not?: InputMaybe<NestedEnumCouponStatusFilter>;
  notIn?: InputMaybe<Array<CouponStatus>>;
};

export type EnumCouponTypeFilter = {
  equals?: InputMaybe<CouponType>;
  in?: InputMaybe<Array<CouponType>>;
  not?: InputMaybe<NestedEnumCouponTypeFilter>;
  notIn?: InputMaybe<Array<CouponType>>;
};

export type EnumCronIntervalNullableFilter = {
  equals?: InputMaybe<CronInterval>;
  in?: InputMaybe<Array<CronInterval>>;
  not?: InputMaybe<NestedEnumCronIntervalNullableFilter>;
  notIn?: InputMaybe<Array<CronInterval>>;
};

export type EnumEmployeeStatusFilter = {
  equals?: InputMaybe<EmployeeStatus>;
  in?: InputMaybe<Array<EmployeeStatus>>;
  not?: InputMaybe<NestedEnumEmployeeStatusFilter>;
  notIn?: InputMaybe<Array<EmployeeStatus>>;
};

export type EnumPushNotificationSendTypeFilter = {
  equals?: InputMaybe<PushNotificationSendType>;
  in?: InputMaybe<Array<PushNotificationSendType>>;
  not?: InputMaybe<NestedEnumPushNotificationSendTypeFilter>;
  notIn?: InputMaybe<Array<PushNotificationSendType>>;
};

export type EnumPushNotificationStatusFilter = {
  equals?: InputMaybe<PushNotificationStatus>;
  in?: InputMaybe<Array<PushNotificationStatus>>;
  not?: InputMaybe<NestedEnumPushNotificationStatusFilter>;
  notIn?: InputMaybe<Array<PushNotificationStatus>>;
};

export type EnumReceivedPointSourceTypeFilter = {
  equals?: InputMaybe<ReceivedPointSourceType>;
  in?: InputMaybe<Array<ReceivedPointSourceType>>;
  not?: InputMaybe<NestedEnumReceivedPointSourceTypeFilter>;
  notIn?: InputMaybe<Array<ReceivedPointSourceType>>;
};

export type EnumSystemTypeFilter = {
  equals?: InputMaybe<SystemType>;
  in?: InputMaybe<Array<SystemType>>;
  not?: InputMaybe<NestedEnumSystemTypeFilter>;
  notIn?: InputMaybe<Array<SystemType>>;
};

export type EnumTransmissionMethodFilter = {
  equals?: InputMaybe<TransmissionMethod>;
  in?: InputMaybe<Array<TransmissionMethod>>;
  not?: InputMaybe<NestedEnumTransmissionMethodFilter>;
  notIn?: InputMaybe<Array<TransmissionMethod>>;
};

export type EnumTransmissionMethodNullableFilter = {
  equals?: InputMaybe<TransmissionMethod>;
  in?: InputMaybe<Array<TransmissionMethod>>;
  not?: InputMaybe<NestedEnumTransmissionMethodNullableFilter>;
  notIn?: InputMaybe<Array<TransmissionMethod>>;
};

export type Fee = {
  __typename: 'Fee';
  amount: Scalars['Float']['output'];
  currency: Scalars['String']['output'];
};

/** Unique input to identify an customer */
export type FindUniqueMagiclineCustomerInput = {
  dateOfBirth?: InputMaybe<Scalars['DateTime']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  studioId?: InputMaybe<Scalars['String']['input']>;
};

export type FloatFilter = {
  equals?: InputMaybe<Scalars['Float']['input']>;
  gt?: InputMaybe<Scalars['Float']['input']>;
  gte?: InputMaybe<Scalars['Float']['input']>;
  in?: InputMaybe<Array<Scalars['Float']['input']>>;
  lt?: InputMaybe<Scalars['Float']['input']>;
  lte?: InputMaybe<Scalars['Float']['input']>;
  not?: InputMaybe<NestedFloatFilter>;
  notIn?: InputMaybe<Array<Scalars['Float']['input']>>;
};

export type IdlePeriodsEntity = {
  __typename: 'IdlePeriodsEntity';
  documentUrl: Scalars['String']['output'];
  endDate: Scalars['String']['output'];
  fee: Fee;
  id: Scalars['Float']['output'];
  reason: Scalars['String']['output'];
  startDate: Scalars['String']['output'];
  state: Scalars['String']['output'];
};

export type ImageTemplate = {
  __typename: 'ImageTemplate';
  _count: ImageTemplateCount;
  coupons?: Maybe<Array<Coupon>>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['UUID']['output'];
  imageKey: Scalars['String']['output'];
  name: Scalars['String']['output'];
  rewards?: Maybe<Array<Reward>>;
  updatedAt: Scalars['DateTime']['output'];
};

export type ImageTemplateCount = {
  __typename: 'ImageTemplateCount';
  coupons: Scalars['Int']['output'];
  rewards: Scalars['Int']['output'];
};

export type ImageTemplateCountAggregate = {
  __typename: 'ImageTemplateCountAggregate';
  _all: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  imageKey: Scalars['Int']['output'];
  name: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
};

export type ImageTemplateCreateInput = {
  coupons?: InputMaybe<CouponCreateNestedManyWithoutImageTemplateInput>;
  id?: InputMaybe<Scalars['UUID']['input']>;
  imageKey: Scalars['String']['input'];
  name: Scalars['String']['input'];
  rewards?: InputMaybe<RewardCreateNestedManyWithoutImageTemplateInput>;
};

export type ImageTemplateCreateNestedOneWithoutCouponsInput = {
  connect?: InputMaybe<ImageTemplateWhereUniqueInput>;
};

export type ImageTemplateCreateNestedOneWithoutRewardsInput = {
  connect?: InputMaybe<ImageTemplateWhereUniqueInput>;
};

export type ImageTemplateMaxAggregate = {
  __typename: 'ImageTemplateMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['UUID']['output']>;
  imageKey?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type ImageTemplateMinAggregate = {
  __typename: 'ImageTemplateMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['UUID']['output']>;
  imageKey?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type ImageTemplateNullableRelationFilter = {
  is?: InputMaybe<ImageTemplateWhereInput>;
  isNot?: InputMaybe<ImageTemplateWhereInput>;
};

export type ImageTemplateOrderByWithRelationInput = {
  coupons?: InputMaybe<CouponOrderByRelationAggregateInput>;
  id?: InputMaybe<SortOrder>;
  imageKey?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  rewards?: InputMaybe<RewardOrderByRelationAggregateInput>;
};

export enum ImageTemplateScalarFieldEnum {
  CreatedAt = 'createdAt',
  Id = 'id',
  ImageKey = 'imageKey',
  Name = 'name',
  UpdatedAt = 'updatedAt'
}

export type ImageTemplateUpdateInput = {
  coupons?: InputMaybe<CouponUpdateManyWithoutImageTemplateNestedInput>;
  id?: InputMaybe<Scalars['UUID']['input']>;
  imageKey?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  rewards?: InputMaybe<RewardUpdateManyWithoutImageTemplateNestedInput>;
};

export type ImageTemplateUpdateOneWithoutCouponsNestedInput = {
  connect?: InputMaybe<ImageTemplateWhereUniqueInput>;
  delete?: InputMaybe<ImageTemplateWhereInput>;
  disconnect?: InputMaybe<ImageTemplateWhereInput>;
};

export type ImageTemplateUpdateOneWithoutRewardsNestedInput = {
  connect?: InputMaybe<ImageTemplateWhereUniqueInput>;
  delete?: InputMaybe<ImageTemplateWhereInput>;
  disconnect?: InputMaybe<ImageTemplateWhereInput>;
};

export type ImageTemplateWhereInput = {
  AND?: InputMaybe<Array<ImageTemplateWhereInput>>;
  NOT?: InputMaybe<Array<ImageTemplateWhereInput>>;
  OR?: InputMaybe<Array<ImageTemplateWhereInput>>;
  coupons?: InputMaybe<CouponListRelationFilter>;
  id?: InputMaybe<UuidFilter>;
  imageKey?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  rewards?: InputMaybe<RewardListRelationFilter>;
};

export type ImageTemplateWhereUniqueInput = {
  AND?: InputMaybe<Array<ImageTemplateWhereInput>>;
  NOT?: InputMaybe<Array<ImageTemplateWhereInput>>;
  OR?: InputMaybe<Array<ImageTemplateWhereInput>>;
  coupons?: InputMaybe<CouponListRelationFilter>;
  id?: InputMaybe<Scalars['UUID']['input']>;
  imageKey?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  rewards?: InputMaybe<RewardListRelationFilter>;
};

export type IntFilter = {
  equals?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<Scalars['Int']['input']>>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  not?: InputMaybe<NestedIntFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type IntNullableFilter = {
  equals?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<Scalars['Int']['input']>>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  not?: InputMaybe<NestedIntNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']['input']>>;
};

/** A subset of CouponType (ADDITION, MULTIPLICATION) */
export enum LimitedCouponType {
  Addition = 'ADDITION',
  Multiplication = 'MULTIPLICATION'
}

/** Custom input to create received points from local stores */
export type LocalStoreReceivedPointCreateInput = {
  valueBasedInput?: InputMaybe<LocalStoreValueBasedInput>;
  visitBasedInput?: InputMaybe<VisitBasedInput>;
};

export type LocalStoreValueBasedInput = {
  amount: Scalars['Float']['input'];
  transactionNumber: Scalars['Float']['input'];
};

/** Custom input type to filter by current location */
export type LocationFilterInput = {
  /** Latitude of current position */
  latitude: Scalars['Latitude']['input'];
  /** Longitude of current position */
  longitude: Scalars['Longitude']['input'];
  /** The radius promotions should be found in. given in kilometers */
  radius: Scalars['Float']['input'];
};

/** Model for Customer of Magicline */
export type MagiclineCustomer = {
  __typename: 'MagiclineCustomer';
  accessRefusal: Scalars['Boolean']['output'];
  bankAccount: BankAccount;
  cardNumbers?: Maybe<Scalars['String']['output']>;
  city: Scalars['String']['output'];
  country: Scalars['String']['output'];
  customerNumber: Scalars['String']['output'];
  dateOfBirth: Scalars['String']['output'];
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  gender: Scalars['String']['output'];
  houseNumber: Scalars['String']['output'];
  id: Scalars['Float']['output'];
  idlePeriods?: Maybe<Array<IdlePeriodsEntity>>;
  imageUrl: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  phoneBusiness: Scalars['String']['output'];
  phoneBusinessMobile: Scalars['String']['output'];
  phonePrivate: Scalars['String']['output'];
  phonePrivateMobile: Scalars['String']['output'];
  preferredLanguage: PreferredLanguage;
  referralCode: Scalars['String']['output'];
  status: Scalars['String']['output'];
  street: Scalars['String']['output'];
  studioId: Scalars['Float']['output'];
  uuid: Scalars['String']['output'];
  zipCode: Scalars['String']['output'];
};

/** Model for created Jobs of management client */
export type ManagementJobModel = {
  __typename: 'ManagementJobModel';
  created_at?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  status: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type Mutation = {
  __typename: 'Mutation';
  changeCustomerState: Customer;
  changePointSystem: PointSystem;
  changeVisibility: Company;
  contactSupport: Contact;
  createBranchOffice: BranchOffice;
  /** Use this endpoint to create a challenge */
  createChallenge: Challenge;
  createChallengePeriod: ChallengePeriod;
  createCompanyCrmConfig: CompanyCrmConfig;
  /** Use this endpoint to create coupons of type MULTIPLICATION and ADDITION */
  createCoupon: Coupon;
  createCouponPeriod: CouponPeriod;
  createCustomer: Customer;
  createCustomerCrmConfig: CustomerCrmConfig;
  createImageTemplate: ImageTemplate;
  createLocalStorePoint: ReceivedPoint;
  createManuallyReceivedPoint: ReceivedPoint;
  createOrChangeCalculationRule: Array<CalculationRule>;
  createPointSystem: PointSystem;
  createPointSystemType: PointSystemType;
  createPromotion: Promotion;
  createPromotionPeriod: PromotionPeriod;
  /** Create a promotional coupon */
  createPromotionalCoupon: Coupon;
  /**
   * Push notifications of type IMMEDIATE will be sent out immediately after creation.
   *     For Push notifications of type RECURRING and SCHEDULED_ONCE, scheduler will be created to sent out the notification at expression date
   */
  createPushNotification: PushNotification;
  createRedeemedPoint: RedeemedPoint;
  createReward: Reward;
  /** Use this endpoint only for creating coupons of type WELCOME. For other coupons use the 'createCoupon' endpoint */
  createWelcomeCoupon: Coupon;
  deleteBranchOffice: BranchOffice;
  deleteCalculationRule: CalculationRule;
  deleteChallenge: Challenge;
  deleteChallengePeriod: ChallengePeriod;
  deleteCompany: Company;
  deleteCompanyCrmConfig: CompanyCrmConfig;
  deleteCoupon: Coupon;
  deleteCouponPeriod: CouponPeriod;
  deleteCustomerCrmConfig: CustomerCrmConfig;
  /** Triggered every day by the customer-handler lambda function to delete all customers that marked as deleted for 30 days */
  deleteCustomers?: Maybe<AffectedRows>;
  deleteEmployee: Employee;
  deleteImageTemplate: ImageTemplate;
  deletePointSystem: PointSystem;
  deletePointSystemType: PointSystemType;
  deletePromotion: Promotion;
  deletePromotionPeriod: PromotionPeriod;
  deletePushNotification: PushNotification;
  deleteReceivedPoint: ReceivedPoint;
  deleteRedeemedPoint: RedeemedPoint;
  deleteReward: Reward;
  /** Only used from the schedule-handler-lambda to disable challenges at the specified period end */
  disableChallenge: Challenge;
  disableCoupon: Coupon;
  disablePushNotification: PushNotification;
  /**
   * Enable a challenge of type COMMUNITY_CHALLENGE at the period begin.
   *      Only used from schedule-handler-lambda and only used for challenges of type COMMUNITY_CHALLENGE
   */
  enableChallenge: Challenge;
  enableCoupon: Coupon;
  /** Invite a employee into a organization */
  inviteEmployee: Employee;
  redeemChallenge: CustomerChallenge;
  redeemCoupon: RedeemedCoupon;
  registerCompany: Company;
  registerInvitedEmployee: Employee;
  resendVerificationMail: ManagementJobModel;
  sendPasswordChangeEmail: Employee;
  sendPushNotification: PushNotification;
  startRegistrationFlow: RegistrationFlowModel;
  /**
   * Use this mutation to stop a challenge of type PASSIVE_POINTS_CHALLENGE.
   *      The latest disable scheduler will be updated with the flag 'finalDisable' = true, to finally disable this challenge at the next execution of the disable scheduler at the end of the month.
   *      The challenge will not be disabled immediately, but will be disabled the next time the updated schedule runs!
   */
  stopPassivePointsChallenge: Challenge;
  switchCouponStatus: Coupon;
  /** Switch the status of reward to deleted (set deletedAt date) or active (set deletedAt to null) */
  switchRewardStatus: Reward;
  updateBranchOffice: BranchOffice;
  updateChallenge: Challenge;
  updateChallengeImage: Challenge;
  updateChallengePeriod: ChallengePeriod;
  updateChallenges: Array<CustomerChallenge>;
  updateCompany: Company;
  updateCompanyCrmConfig: CompanyCrmConfig;
  updateCompanyLogo: Company;
  updateCoupon: Coupon;
  updateCouponPeriod: CouponPeriod;
  updateCustomer: Customer;
  updateCustomerCrmConfig: CustomerCrmConfig;
  updateEmployee: Employee;
  updateImageTemplate: ImageTemplate;
  updatePointSystem: PointSystem;
  updatePointSystemType: PointSystemType;
  updatePromotionPeriod: PromotionPeriod;
  updateReceivedPoint: ReceivedPoint;
  updateRedeemedPoint: RedeemedPoint;
  updateReward: Reward;
};


export type MutationChangeCustomerStateArgs = {
  customerId: Scalars['String']['input'];
  state: CustomerStateEnum;
};


export type MutationChangePointSystemArgs = {
  input: ChangePointSystemInput;
};


export type MutationChangeVisibilityArgs = {
  visible: Scalars['Boolean']['input'];
};


export type MutationContactSupportArgs = {
  data: ContactSupportInput;
};


export type MutationCreateBranchOfficeArgs = {
  data: BranchOfficeCreateInput;
};


export type MutationCreateChallengeArgs = {
  data: ChallengeCreateInput;
};


export type MutationCreateChallengePeriodArgs = {
  data: ChallengePeriodCreateInput;
};


export type MutationCreateCompanyCrmConfigArgs = {
  data: CompanyCrmConfigCreateInput;
};


export type MutationCreateCouponArgs = {
  data: CouponCreateInput;
};


export type MutationCreateCouponPeriodArgs = {
  data: CouponPeriodCreateInput;
};


export type MutationCreateCustomerArgs = {
  data: CustomerCreateInput;
};


export type MutationCreateCustomerCrmConfigArgs = {
  data: CustomerCrmConfigCreateInput;
};


export type MutationCreateImageTemplateArgs = {
  data: ImageTemplateCreateInput;
};


export type MutationCreateLocalStorePointArgs = {
  createInput: LocalStoreReceivedPointCreateInput;
};


export type MutationCreateManuallyReceivedPointArgs = {
  count: Scalars['Float']['input'];
  customer: CustomerWhereUniqueInput;
};


export type MutationCreateOrChangeCalculationRuleArgs = {
  data: CreateOrChangeCalculationRuleInput;
};


export type MutationCreatePointSystemArgs = {
  createInput: PointSystemCreateInput;
};


export type MutationCreatePointSystemTypeArgs = {
  data: PointSystemTypeCreateInput;
};


export type MutationCreatePromotionArgs = {
  data: PromotionCreateInput;
};


export type MutationCreatePromotionPeriodArgs = {
  data: PromotionPeriodCreateInput;
};


export type MutationCreatePromotionalCouponArgs = {
  data: PromotionalCouponCreateInput;
};


export type MutationCreatePushNotificationArgs = {
  data: PushNotificationCreateInput;
};


export type MutationCreateRedeemedPointArgs = {
  data: RedeemedPointCreateInput;
};


export type MutationCreateRewardArgs = {
  data: CreateRewardInput;
};


export type MutationCreateWelcomeCouponArgs = {
  data: WelcomeCouponCreateInput;
};


export type MutationDeleteBranchOfficeArgs = {
  where: BranchOfficeUniqueInput;
};


export type MutationDeleteCalculationRuleArgs = {
  where: CalculationRuleWhereUniqueInput;
};


export type MutationDeleteChallengeArgs = {
  where: ChallengeWhereUniqueInput;
};


export type MutationDeleteChallengePeriodArgs = {
  where: ChallengePeriodWhereUniqueInput;
};


export type MutationDeleteCompanyArgs = {
  where: CompanyWhereUniqueInput;
};


export type MutationDeleteCompanyCrmConfigArgs = {
  where: CompanyCrmConfigWhereUniqueInput;
};


export type MutationDeleteCouponArgs = {
  where: CouponWhereUniqueInput;
};


export type MutationDeleteCouponPeriodArgs = {
  where: CouponPeriodWhereUniqueInput;
};


export type MutationDeleteCustomerCrmConfigArgs = {
  where: CustomerCrmConfigWhereUniqueInput;
};


export type MutationDeleteCustomersArgs = {
  customerIds: Array<Scalars['String']['input']>;
};


export type MutationDeleteEmployeeArgs = {
  where: EmployeeIdentifierInput;
};


export type MutationDeleteImageTemplateArgs = {
  where: ImageTemplateWhereUniqueInput;
};


export type MutationDeletePointSystemArgs = {
  where: PointSystemWhereUniqueInput;
};


export type MutationDeletePointSystemTypeArgs = {
  where: PointSystemTypeWhereUniqueInput;
};


export type MutationDeletePromotionArgs = {
  where: PromotionWhereUniqueInput;
};


export type MutationDeletePromotionPeriodArgs = {
  where: PromotionPeriodWhereUniqueInput;
};


export type MutationDeletePushNotificationArgs = {
  where: PushNotificationWhereUniqueInput;
};


export type MutationDeleteReceivedPointArgs = {
  where: ReceivedPointWhereUniqueInput;
};


export type MutationDeleteRedeemedPointArgs = {
  where: RedeemedPointWhereUniqueInput;
};


export type MutationDeleteRewardArgs = {
  where: RewardWhereUniqueInput;
};


export type MutationDisableChallengeArgs = {
  data?: InputMaybe<DisableChallengeInput>;
  where: ChallengeWhereUniqueInput;
};


export type MutationDisableCouponArgs = {
  where: CouponWhereUniqueInput;
};


export type MutationDisablePushNotificationArgs = {
  pushNotificationId: Scalars['String']['input'];
};


export type MutationEnableChallengeArgs = {
  where: ChallengeWhereUniqueInput;
};


export type MutationEnableCouponArgs = {
  where: CouponWhereUniqueInput;
};


export type MutationInviteEmployeeArgs = {
  email: Scalars['String']['input'];
};


export type MutationRedeemChallengeArgs = {
  challengeId: Scalars['String']['input'];
};


export type MutationRedeemCouponArgs = {
  couponId: Scalars['String']['input'];
};


export type MutationRegisterCompanyArgs = {
  registerInput: RegisterCompanyInput;
};


export type MutationRegisterInvitedEmployeeArgs = {
  data: RegisterEmployeeInput;
  where: EmployeeUniqueInput;
};


export type MutationResendVerificationMailArgs = {
  userId: Scalars['String']['input'];
};


export type MutationSendPushNotificationArgs = {
  pushNotificationId: Scalars['String']['input'];
};


export type MutationStartRegistrationFlowArgs = {
  accountInformation: AccountInformationInput;
  companyInformation: CompanyInformationInput;
};


export type MutationStopPassivePointsChallengeArgs = {
  where: ChallengeWhereUniqueInput;
};


export type MutationSwitchCouponStatusArgs = {
  data: SwitchCouponStatusInput;
  where: CouponUniqueInput;
};


export type MutationSwitchRewardStatusArgs = {
  input: SwitchRewardStatusInput;
};


export type MutationUpdateBranchOfficeArgs = {
  data: BranchOfficeUpdateInput;
  where: BranchOfficeWhereUniqueInput;
};


export type MutationUpdateChallengeArgs = {
  data: ChallengeUpdateInput;
  where: ChallengeWhereUniqueInput;
};


export type MutationUpdateChallengeImageArgs = {
  data: ChallengeImageInput;
  where: ChallengeWhereUniqueInput;
};


export type MutationUpdateChallengePeriodArgs = {
  data: ChallengePeriodUpdateInput;
  where: ChallengePeriodWhereUniqueInput;
};


export type MutationUpdateChallengesArgs = {
  customerId: Scalars['String']['input'];
};


export type MutationUpdateCompanyArgs = {
  data: UpdateCompanyInput;
  where?: InputMaybe<CompanyUniqueInput>;
};


export type MutationUpdateCompanyCrmConfigArgs = {
  data: CompanyCrmConfigUpdateInput;
  where: CompanyCrmConfigWhereUniqueInput;
};


export type MutationUpdateCompanyLogoArgs = {
  input: CompanyLogoInput;
};


export type MutationUpdateCouponArgs = {
  data: CouponUpdateInput;
  where: CouponWhereUniqueInput;
};


export type MutationUpdateCouponPeriodArgs = {
  data: CouponPeriodUpdateInput;
  where: CouponPeriodWhereUniqueInput;
};


export type MutationUpdateCustomerArgs = {
  data: CustomerUpdateInput;
  where: CustomerWhereUniqueInput;
};


export type MutationUpdateCustomerCrmConfigArgs = {
  data: CustomerCrmConfigUpdateInput;
  where: CustomerCrmConfigWhereUniqueInput;
};


export type MutationUpdateEmployeeArgs = {
  data: UpdateEmployeeInput;
  where?: InputMaybe<EmployeeUniqueInput>;
};


export type MutationUpdateImageTemplateArgs = {
  data: ImageTemplateUpdateInput;
  where: ImageTemplateWhereUniqueInput;
};


export type MutationUpdatePointSystemArgs = {
  data: PointSystemUpdateInput;
  where: PointSystemWhereUniqueInput;
};


export type MutationUpdatePointSystemTypeArgs = {
  data: PointSystemTypeUpdateInput;
  where: PointSystemTypeWhereUniqueInput;
};


export type MutationUpdatePromotionPeriodArgs = {
  data: PromotionPeriodUpdateInput;
  where: PromotionPeriodUniqueInput;
};


export type MutationUpdateReceivedPointArgs = {
  data: ReceivedPointUpdateInput;
  where: ReceivedPointWhereUniqueInput;
};


export type MutationUpdateRedeemedPointArgs = {
  data: RedeemedPointUpdateInput;
  where: RedeemedPointWhereUniqueInput;
};


export type MutationUpdateRewardArgs = {
  data: RewardUpdateInput;
  where: RewardWhereUniqueInput;
};

export type NestedBoolFilter = {
  equals?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<NestedBoolFilter>;
};

export type NestedDateTimeFilter = {
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  not?: InputMaybe<NestedDateTimeFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

export type NestedDateTimeNullableFilter = {
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  not?: InputMaybe<NestedDateTimeNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

export type NestedDecimalFilter = {
  equals?: InputMaybe<Scalars['Decimal']['input']>;
  gt?: InputMaybe<Scalars['Decimal']['input']>;
  gte?: InputMaybe<Scalars['Decimal']['input']>;
  in?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  lt?: InputMaybe<Scalars['Decimal']['input']>;
  lte?: InputMaybe<Scalars['Decimal']['input']>;
  not?: InputMaybe<NestedDecimalFilter>;
  notIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
};

export type NestedDecimalNullableFilter = {
  equals?: InputMaybe<Scalars['Decimal']['input']>;
  gt?: InputMaybe<Scalars['Decimal']['input']>;
  gte?: InputMaybe<Scalars['Decimal']['input']>;
  in?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  lt?: InputMaybe<Scalars['Decimal']['input']>;
  lte?: InputMaybe<Scalars['Decimal']['input']>;
  not?: InputMaybe<NestedDecimalNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
};

/** Custom input for nested creation of employee by registering a company */
export type NestedEmployeeCreateWithoutCompanyInput = {
  email: Scalars['EmailAddress']['input'];
  firstName?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
};

export type NestedEnumChallengeStatusFilter = {
  equals?: InputMaybe<ChallengeStatus>;
  in?: InputMaybe<Array<ChallengeStatus>>;
  not?: InputMaybe<NestedEnumChallengeStatusFilter>;
  notIn?: InputMaybe<Array<ChallengeStatus>>;
};

export type NestedEnumChallengeTypeFilter = {
  equals?: InputMaybe<ChallengeType>;
  in?: InputMaybe<Array<ChallengeType>>;
  not?: InputMaybe<NestedEnumChallengeTypeFilter>;
  notIn?: InputMaybe<Array<ChallengeType>>;
};

export type NestedEnumCompanyUserTypeFilter = {
  equals?: InputMaybe<CompanyUserType>;
  in?: InputMaybe<Array<CompanyUserType>>;
  not?: InputMaybe<NestedEnumCompanyUserTypeFilter>;
  notIn?: InputMaybe<Array<CompanyUserType>>;
};

export type NestedEnumCouponStatusFilter = {
  equals?: InputMaybe<CouponStatus>;
  in?: InputMaybe<Array<CouponStatus>>;
  not?: InputMaybe<NestedEnumCouponStatusFilter>;
  notIn?: InputMaybe<Array<CouponStatus>>;
};

export type NestedEnumCouponTypeFilter = {
  equals?: InputMaybe<CouponType>;
  in?: InputMaybe<Array<CouponType>>;
  not?: InputMaybe<NestedEnumCouponTypeFilter>;
  notIn?: InputMaybe<Array<CouponType>>;
};

export type NestedEnumCronIntervalNullableFilter = {
  equals?: InputMaybe<CronInterval>;
  in?: InputMaybe<Array<CronInterval>>;
  not?: InputMaybe<NestedEnumCronIntervalNullableFilter>;
  notIn?: InputMaybe<Array<CronInterval>>;
};

export type NestedEnumEmployeeStatusFilter = {
  equals?: InputMaybe<EmployeeStatus>;
  in?: InputMaybe<Array<EmployeeStatus>>;
  not?: InputMaybe<NestedEnumEmployeeStatusFilter>;
  notIn?: InputMaybe<Array<EmployeeStatus>>;
};

export type NestedEnumPushNotificationSendTypeFilter = {
  equals?: InputMaybe<PushNotificationSendType>;
  in?: InputMaybe<Array<PushNotificationSendType>>;
  not?: InputMaybe<NestedEnumPushNotificationSendTypeFilter>;
  notIn?: InputMaybe<Array<PushNotificationSendType>>;
};

export type NestedEnumPushNotificationStatusFilter = {
  equals?: InputMaybe<PushNotificationStatus>;
  in?: InputMaybe<Array<PushNotificationStatus>>;
  not?: InputMaybe<NestedEnumPushNotificationStatusFilter>;
  notIn?: InputMaybe<Array<PushNotificationStatus>>;
};

export type NestedEnumReceivedPointSourceTypeFilter = {
  equals?: InputMaybe<ReceivedPointSourceType>;
  in?: InputMaybe<Array<ReceivedPointSourceType>>;
  not?: InputMaybe<NestedEnumReceivedPointSourceTypeFilter>;
  notIn?: InputMaybe<Array<ReceivedPointSourceType>>;
};

export type NestedEnumSystemTypeFilter = {
  equals?: InputMaybe<SystemType>;
  in?: InputMaybe<Array<SystemType>>;
  not?: InputMaybe<NestedEnumSystemTypeFilter>;
  notIn?: InputMaybe<Array<SystemType>>;
};

export type NestedEnumTransmissionMethodFilter = {
  equals?: InputMaybe<TransmissionMethod>;
  in?: InputMaybe<Array<TransmissionMethod>>;
  not?: InputMaybe<NestedEnumTransmissionMethodFilter>;
  notIn?: InputMaybe<Array<TransmissionMethod>>;
};

export type NestedEnumTransmissionMethodNullableFilter = {
  equals?: InputMaybe<TransmissionMethod>;
  in?: InputMaybe<Array<TransmissionMethod>>;
  not?: InputMaybe<NestedEnumTransmissionMethodNullableFilter>;
  notIn?: InputMaybe<Array<TransmissionMethod>>;
};

export type NestedFloatFilter = {
  equals?: InputMaybe<Scalars['Float']['input']>;
  gt?: InputMaybe<Scalars['Float']['input']>;
  gte?: InputMaybe<Scalars['Float']['input']>;
  in?: InputMaybe<Array<Scalars['Float']['input']>>;
  lt?: InputMaybe<Scalars['Float']['input']>;
  lte?: InputMaybe<Scalars['Float']['input']>;
  not?: InputMaybe<NestedFloatFilter>;
  notIn?: InputMaybe<Array<Scalars['Float']['input']>>;
};

export type NestedIntFilter = {
  equals?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<Scalars['Int']['input']>>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  not?: InputMaybe<NestedIntFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type NestedIntNullableFilter = {
  equals?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<Scalars['Int']['input']>>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  not?: InputMaybe<NestedIntNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type NestedStringFilter = {
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  not?: InputMaybe<NestedStringFilter>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type NestedStringNullableFilter = {
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  not?: InputMaybe<NestedStringNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type NestedUuidFilter = {
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  not?: InputMaybe<NestedUuidFilter>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type NestedUuidNullableFilter = {
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  not?: InputMaybe<NestedUuidNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
};

export enum NullsOrder {
  First = 'first',
  Last = 'last'
}

export type PaginatedCustomer = {
  __typename: 'PaginatedCustomer';
  edges?: Maybe<Array<CustomerEdge>>;
  hasNextPage: Scalars['Boolean']['output'];
  nodes: Array<Customer>;
  totalCount: Scalars['Int']['output'];
};

export type PointSystem = {
  __typename: 'PointSystem';
  calculationRuleEntries: Scalars['Int']['output'];
  company: Company;
  companyId: Scalars['String']['output'];
  id: Scalars['UUID']['output'];
  pointSystemType: PointSystemType;
  pointSystemTypeId: Scalars['UUID']['output'];
  scanLimit: Scalars['Int']['output'];
};

export type PointSystemAvgAggregate = {
  __typename: 'PointSystemAvgAggregate';
  calculationRuleEntries?: Maybe<Scalars['Float']['output']>;
  scanLimit?: Maybe<Scalars['Float']['output']>;
};

export type PointSystemCountAggregate = {
  __typename: 'PointSystemCountAggregate';
  _all: Scalars['Int']['output'];
  calculationRuleEntries: Scalars['Int']['output'];
  companyId: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  pointSystemTypeId: Scalars['Int']['output'];
  scanLimit: Scalars['Int']['output'];
};

/** Input for creation of point system */
export type PointSystemCreateInput = {
  pointSystemType: PointSystemTypeCreateNestedOneWithoutPointSystemInput;
  /** Only allowed for system type VISIT_BASED. In combination with other system types, an error will be thrown */
  scanLimit?: InputMaybe<Scalars['Float']['input']>;
};

export type PointSystemCreateNestedManyWithoutPointSystemTypeInput = {
  connect?: InputMaybe<Array<PointSystemWhereUniqueInput>>;
};

export type PointSystemListRelationFilter = {
  every?: InputMaybe<PointSystemWhereInput>;
  none?: InputMaybe<PointSystemWhereInput>;
  some?: InputMaybe<PointSystemWhereInput>;
};

export type PointSystemMaxAggregate = {
  __typename: 'PointSystemMaxAggregate';
  calculationRuleEntries?: Maybe<Scalars['Int']['output']>;
  companyId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['UUID']['output']>;
  pointSystemTypeId?: Maybe<Scalars['UUID']['output']>;
  scanLimit?: Maybe<Scalars['Int']['output']>;
};

export type PointSystemMinAggregate = {
  __typename: 'PointSystemMinAggregate';
  calculationRuleEntries?: Maybe<Scalars['Int']['output']>;
  companyId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['UUID']['output']>;
  pointSystemTypeId?: Maybe<Scalars['UUID']['output']>;
  scanLimit?: Maybe<Scalars['Int']['output']>;
};

export type PointSystemNullableRelationFilter = {
  is?: InputMaybe<PointSystemWhereInput>;
  isNot?: InputMaybe<PointSystemWhereInput>;
};

export type PointSystemOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type PointSystemOrderByWithRelationInput = {
  calculationRuleEntries?: InputMaybe<SortOrder>;
  company?: InputMaybe<CompanyOrderByWithRelationInput>;
  id?: InputMaybe<SortOrder>;
  pointSystemType?: InputMaybe<PointSystemTypeOrderByWithRelationInput>;
  scanLimit?: InputMaybe<SortOrder>;
};

export enum PointSystemScalarFieldEnum {
  CalculationRuleEntries = 'calculationRuleEntries',
  CompanyId = 'companyId',
  Id = 'id',
  PointSystemTypeId = 'pointSystemTypeId',
  ScanLimit = 'scanLimit'
}

export type PointSystemScalarWhereInput = {
  AND?: InputMaybe<Array<PointSystemScalarWhereInput>>;
  NOT?: InputMaybe<Array<PointSystemScalarWhereInput>>;
  OR?: InputMaybe<Array<PointSystemScalarWhereInput>>;
  calculationRuleEntries?: InputMaybe<IntFilter>;
  id?: InputMaybe<UuidFilter>;
  scanLimit?: InputMaybe<IntFilter>;
};

export type PointSystemSumAggregate = {
  __typename: 'PointSystemSumAggregate';
  calculationRuleEntries?: Maybe<Scalars['Int']['output']>;
  scanLimit?: Maybe<Scalars['Int']['output']>;
};

export type PointSystemType = {
  __typename: 'PointSystemType';
  _count: PointSystemTypeCount;
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['UUID']['output'];
  pointSystem?: Maybe<Array<PointSystem>>;
  type: SystemType;
  updatedAt: Scalars['DateTime']['output'];
};

export type PointSystemTypeCount = {
  __typename: 'PointSystemTypeCount';
  pointSystem: Scalars['Int']['output'];
};

export type PointSystemTypeCountAggregate = {
  __typename: 'PointSystemTypeCountAggregate';
  _all: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  description: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  type: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
};

export type PointSystemTypeCreateInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['UUID']['input']>;
  pointSystem?: InputMaybe<PointSystemCreateNestedManyWithoutPointSystemTypeInput>;
  type?: InputMaybe<SystemType>;
};

export type PointSystemTypeCreateNestedOneWithoutPointSystemInput = {
  connect?: InputMaybe<PointSystemTypeWhereUniqueInput>;
};

export type PointSystemTypeMaxAggregate = {
  __typename: 'PointSystemTypeMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['UUID']['output']>;
  type?: Maybe<SystemType>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type PointSystemTypeMinAggregate = {
  __typename: 'PointSystemTypeMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['UUID']['output']>;
  type?: Maybe<SystemType>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type PointSystemTypeOrderByWithRelationInput = {
  description?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  pointSystem?: InputMaybe<PointSystemOrderByRelationAggregateInput>;
  type?: InputMaybe<SortOrder>;
};

export type PointSystemTypeRelationFilter = {
  is?: InputMaybe<PointSystemTypeWhereInput>;
  isNot?: InputMaybe<PointSystemTypeWhereInput>;
};

export enum PointSystemTypeScalarFieldEnum {
  CreatedAt = 'createdAt',
  Description = 'description',
  Id = 'id',
  Type = 'type',
  UpdatedAt = 'updatedAt'
}

export type PointSystemTypeUpdateInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['UUID']['input']>;
  pointSystem?: InputMaybe<PointSystemUpdateManyWithoutPointSystemTypeNestedInput>;
  type?: InputMaybe<SystemType>;
};

export type PointSystemTypeUpdateOneRequiredWithoutPointSystemNestedInput = {
  connect?: InputMaybe<PointSystemTypeWhereUniqueInput>;
};

export type PointSystemTypeWhereInput = {
  AND?: InputMaybe<Array<PointSystemTypeWhereInput>>;
  NOT?: InputMaybe<Array<PointSystemTypeWhereInput>>;
  OR?: InputMaybe<Array<PointSystemTypeWhereInput>>;
  description?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<UuidFilter>;
  pointSystem?: InputMaybe<PointSystemListRelationFilter>;
  type?: InputMaybe<EnumSystemTypeFilter>;
};

export type PointSystemTypeWhereUniqueInput = {
  AND?: InputMaybe<Array<PointSystemTypeWhereInput>>;
  NOT?: InputMaybe<Array<PointSystemTypeWhereInput>>;
  OR?: InputMaybe<Array<PointSystemTypeWhereInput>>;
  description?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<Scalars['UUID']['input']>;
  pointSystem?: InputMaybe<PointSystemListRelationFilter>;
  type?: InputMaybe<SystemType>;
};

export type PointSystemUpdateInput = {
  calculationRuleEntries?: InputMaybe<Scalars['Int']['input']>;
  company?: InputMaybe<CompanyUpdateOneRequiredWithoutPointSystemNestedInput>;
  id?: InputMaybe<Scalars['UUID']['input']>;
  pointSystemType?: InputMaybe<PointSystemTypeUpdateOneRequiredWithoutPointSystemNestedInput>;
  scanLimit?: InputMaybe<Scalars['Int']['input']>;
};

export type PointSystemUpdateManyMutationInput = {
  calculationRuleEntries?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['UUID']['input']>;
  scanLimit?: InputMaybe<Scalars['Int']['input']>;
};

export type PointSystemUpdateManyWithWhereWithoutPointSystemTypeInput = {
  data: PointSystemUpdateManyMutationInput;
  where: PointSystemScalarWhereInput;
};

export type PointSystemUpdateManyWithoutPointSystemTypeNestedInput = {
  connect?: InputMaybe<Array<PointSystemWhereUniqueInput>>;
  delete?: InputMaybe<Array<PointSystemWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<PointSystemScalarWhereInput>>;
  disconnect?: InputMaybe<Array<PointSystemWhereUniqueInput>>;
  set?: InputMaybe<Array<PointSystemWhereUniqueInput>>;
  updateMany?: InputMaybe<Array<PointSystemUpdateManyWithWhereWithoutPointSystemTypeInput>>;
};

export type PointSystemWhereInput = {
  AND?: InputMaybe<Array<PointSystemWhereInput>>;
  NOT?: InputMaybe<Array<PointSystemWhereInput>>;
  OR?: InputMaybe<Array<PointSystemWhereInput>>;
  calculationRuleEntries?: InputMaybe<IntFilter>;
  company?: InputMaybe<CompanyRelationFilter>;
  id?: InputMaybe<UuidFilter>;
  pointSystemType?: InputMaybe<PointSystemTypeRelationFilter>;
  scanLimit?: InputMaybe<IntFilter>;
};

export type PointSystemWhereUniqueInput = {
  AND?: InputMaybe<Array<PointSystemWhereInput>>;
  NOT?: InputMaybe<Array<PointSystemWhereInput>>;
  OR?: InputMaybe<Array<PointSystemWhereInput>>;
  calculationRuleEntries?: InputMaybe<IntFilter>;
  company?: InputMaybe<CompanyRelationFilter>;
  id?: InputMaybe<Scalars['UUID']['input']>;
  pointSystemType?: InputMaybe<PointSystemTypeRelationFilter>;
  scanLimit?: InputMaybe<IntFilter>;
};

export type PreferredLanguage = {
  __typename: 'PreferredLanguage';
  countryCode: Scalars['String']['output'];
  languageCode: Scalars['String']['output'];
};

export type Promotion = {
  __typename: 'Promotion';
  _count: PromotionCount;
  company: Company;
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['UUID']['output'];
  periods?: Maybe<Array<PromotionPeriod>>;
  promotionCode?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type PromotionCount = {
  __typename: 'PromotionCount';
  periods: Scalars['Int']['output'];
};

export type PromotionCountAggregate = {
  __typename: 'PromotionCountAggregate';
  _all: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  description: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  promotionCode: Scalars['Int']['output'];
  title: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
};

/** Input for creation a promotion */
export type PromotionCreateInput = {
  /** The description for the promotion */
  description?: InputMaybe<Scalars['String']['input']>;
  /** The period for the promotion */
  period?: InputMaybe<CreatePromotionPeriodInput>;
  promotionCode?: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
};

export type PromotionCreateNestedOneWithoutPeriodsInput = {
  connect?: InputMaybe<PromotionWhereUniqueInput>;
};

export type PromotionListRelationFilter = {
  every?: InputMaybe<PromotionWhereInput>;
  none?: InputMaybe<PromotionWhereInput>;
  some?: InputMaybe<PromotionWhereInput>;
};

export type PromotionMaxAggregate = {
  __typename: 'PromotionMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['UUID']['output']>;
  promotionCode?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type PromotionMinAggregate = {
  __typename: 'PromotionMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['UUID']['output']>;
  promotionCode?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type PromotionOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type PromotionOrderByWithRelationInput = {
  company?: InputMaybe<CompanyOrderByWithRelationInput>;
  companyId?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  periods?: InputMaybe<PromotionPeriodOrderByRelationAggregateInput>;
  promotionCode?: InputMaybe<SortOrderInput>;
  title?: InputMaybe<SortOrder>;
};

export type PromotionPeriod = {
  __typename: 'PromotionPeriod';
  activeFrom: Scalars['DateTime']['output'];
  activeUntil: Scalars['DateTime']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['UUID']['output'];
  promotion: Promotion;
  promotionId: Scalars['UUID']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type PromotionPeriodCountAggregate = {
  __typename: 'PromotionPeriodCountAggregate';
  _all: Scalars['Int']['output'];
  activeFrom: Scalars['Int']['output'];
  activeUntil: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  promotionId: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
};

export type PromotionPeriodCreateInput = {
  activeFrom: Scalars['DateTime']['input'];
  activeUntil: Scalars['DateTime']['input'];
  id?: InputMaybe<Scalars['UUID']['input']>;
  promotion: PromotionCreateNestedOneWithoutPeriodsInput;
};

export type PromotionPeriodListRelationFilter = {
  every?: InputMaybe<PromotionPeriodWhereInput>;
  none?: InputMaybe<PromotionPeriodWhereInput>;
  some?: InputMaybe<PromotionPeriodWhereInput>;
};

export type PromotionPeriodMaxAggregate = {
  __typename: 'PromotionPeriodMaxAggregate';
  activeFrom?: Maybe<Scalars['DateTime']['output']>;
  activeUntil?: Maybe<Scalars['DateTime']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['UUID']['output']>;
  promotionId?: Maybe<Scalars['UUID']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type PromotionPeriodMinAggregate = {
  __typename: 'PromotionPeriodMinAggregate';
  activeFrom?: Maybe<Scalars['DateTime']['output']>;
  activeUntil?: Maybe<Scalars['DateTime']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['UUID']['output']>;
  promotionId?: Maybe<Scalars['UUID']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type PromotionPeriodOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type PromotionPeriodOrderByWithRelationInput = {
  activeFrom?: InputMaybe<SortOrder>;
  activeUntil?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  promotion?: InputMaybe<PromotionOrderByWithRelationInput>;
  promotionId?: InputMaybe<SortOrder>;
};

export enum PromotionPeriodScalarFieldEnum {
  ActiveFrom = 'activeFrom',
  ActiveUntil = 'activeUntil',
  CreatedAt = 'createdAt',
  Id = 'id',
  PromotionId = 'promotionId',
  UpdatedAt = 'updatedAt'
}

/** Unique input for promotion period */
export type PromotionPeriodUniqueInput = {
  id?: InputMaybe<Scalars['UUID']['input']>;
};

/** Input for updating a promotion period */
export type PromotionPeriodUpdateInput = {
  activeFrom?: InputMaybe<Scalars['DateTime']['input']>;
  activeUntil?: InputMaybe<Scalars['DateTime']['input']>;
};

export type PromotionPeriodWhereInput = {
  AND?: InputMaybe<Array<PromotionPeriodWhereInput>>;
  NOT?: InputMaybe<Array<PromotionPeriodWhereInput>>;
  OR?: InputMaybe<Array<PromotionPeriodWhereInput>>;
  activeFrom?: InputMaybe<DateTimeFilter>;
  activeUntil?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<UuidFilter>;
  promotion?: InputMaybe<PromotionRelationFilter>;
  promotionId?: InputMaybe<UuidFilter>;
};

export type PromotionPeriodWhereUniqueInput = {
  AND?: InputMaybe<Array<PromotionPeriodWhereInput>>;
  NOT?: InputMaybe<Array<PromotionPeriodWhereInput>>;
  OR?: InputMaybe<Array<PromotionPeriodWhereInput>>;
  activeFrom?: InputMaybe<DateTimeFilter>;
  activeUntil?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<Scalars['UUID']['input']>;
  promotion?: InputMaybe<PromotionRelationFilter>;
  promotionId?: InputMaybe<UuidFilter>;
};

export type PromotionRelationFilter = {
  is?: InputMaybe<PromotionWhereInput>;
  isNot?: InputMaybe<PromotionWhereInput>;
};

export enum PromotionScalarFieldEnum {
  CompanyId = 'companyId',
  CreatedAt = 'createdAt',
  Description = 'description',
  Id = 'id',
  PromotionCode = 'promotionCode',
  Title = 'title',
  UpdatedAt = 'updatedAt'
}

export type PromotionWhereInput = {
  AND?: InputMaybe<Array<PromotionWhereInput>>;
  NOT?: InputMaybe<Array<PromotionWhereInput>>;
  OR?: InputMaybe<Array<PromotionWhereInput>>;
  company?: InputMaybe<CompanyRelationFilter>;
  companyId?: InputMaybe<StringFilter>;
  description?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<UuidFilter>;
  periods?: InputMaybe<PromotionPeriodListRelationFilter>;
  promotionCode?: InputMaybe<StringNullableFilter>;
  title?: InputMaybe<StringFilter>;
};

export type PromotionWhereUniqueInput = {
  AND?: InputMaybe<Array<PromotionWhereInput>>;
  NOT?: InputMaybe<Array<PromotionWhereInput>>;
  OR?: InputMaybe<Array<PromotionWhereInput>>;
  company?: InputMaybe<CompanyRelationFilter>;
  companyId?: InputMaybe<StringFilter>;
  description?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<Scalars['UUID']['input']>;
  periods?: InputMaybe<PromotionPeriodListRelationFilter>;
  promotionCode?: InputMaybe<StringNullableFilter>;
  title?: InputMaybe<StringFilter>;
};

/** Input for creating a promotional coupon */
export type PromotionalCouponCreateInput = {
  /** Optionally specify the company of the coupon */
  company?: InputMaybe<CompanyCreateNestedOneWithoutCouponsInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  maxRedemptions?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  /** The period the coupon is active at */
  period: CreateCouponPeriodInput;
  transmissionMethod?: InputMaybe<TransmissionMethod>;
};

export type PushNotification = {
  __typename: 'PushNotification';
  _count: PushNotificationCount;
  company: Company;
  createdAt: Scalars['DateTime']['output'];
  /** The cron expression for the scheduler of notifications of type RECURRING */
  cronExpression?: Maybe<Scalars['String']['output']>;
  cronInterval?: Maybe<CronInterval>;
  /** The date at which the cron should be executed the first time */
  cronStartDate?: Maybe<Scalars['DateTime']['output']>;
  /** The history of sendings of an push notification */
  history?: Maybe<Array<PushNotificationHistory>>;
  id: Scalars['UUID']['output'];
  /** The message of the push notification */
  message: Scalars['String']['output'];
  /** The send date for notifications of type SCHEDULED_ONCE */
  sendAt?: Maybe<Scalars['DateTime']['output']>;
  /** The status of the push notification */
  status: PushNotificationStatus;
  /** The title of the push notification */
  title: Scalars['String']['output'];
  /** The send type for the push notification */
  type: PushNotificationSendType;
};

export type PushNotificationCount = {
  __typename: 'PushNotificationCount';
  history: Scalars['Int']['output'];
};

export type PushNotificationCountAggregate = {
  __typename: 'PushNotificationCountAggregate';
  _all: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  cronExpression: Scalars['Int']['output'];
  cronInterval: Scalars['Int']['output'];
  cronStartDate: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  message: Scalars['Int']['output'];
  sendAt: Scalars['Int']['output'];
  status: Scalars['Int']['output'];
  title: Scalars['Int']['output'];
  type: Scalars['Int']['output'];
};

/** Input for creating a push notification */
export type PushNotificationCreateInput = {
  /** Optionally specify the company this notification should be created for */
  company?: InputMaybe<CompanyCreateNestedOneWithoutPushNotificationsInput>;
  /** The expression for the scheduler. Needs to be in format: 'minutes hours day-of-month month day-of-week year'. Example for a cron that runs each month on the 15th: "0 0 15 * ? *". Only needs to be specified if the type of notification is SCHEDULED_ONCE or RECURRING */
  cronExpression?: InputMaybe<Scalars['CronExpression']['input']>;
  cronInterval?: InputMaybe<CronInterval>;
  cronStartDate?: InputMaybe<Scalars['DateTime']['input']>;
  message: Scalars['String']['input'];
  sendAt?: InputMaybe<Scalars['DateTime']['input']>;
  title: Scalars['String']['input'];
  type: PushNotificationSendType;
};

export type PushNotificationHistory = {
  __typename: 'PushNotificationHistory';
  affectedCustomers: Scalars['Int']['output'];
  id: Scalars['UUID']['output'];
  pushNotification: PushNotification;
  pushNotificationId: Scalars['String']['output'];
  sendAt: Scalars['DateTime']['output'];
};

export type PushNotificationHistoryAvgAggregate = {
  __typename: 'PushNotificationHistoryAvgAggregate';
  affectedCustomers?: Maybe<Scalars['Float']['output']>;
};

export type PushNotificationHistoryCountAggregate = {
  __typename: 'PushNotificationHistoryCountAggregate';
  _all: Scalars['Int']['output'];
  affectedCustomers: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  pushNotificationId: Scalars['Int']['output'];
  sendAt: Scalars['Int']['output'];
};

export type PushNotificationHistoryListRelationFilter = {
  every?: InputMaybe<PushNotificationHistoryWhereInput>;
  none?: InputMaybe<PushNotificationHistoryWhereInput>;
  some?: InputMaybe<PushNotificationHistoryWhereInput>;
};

export type PushNotificationHistoryMaxAggregate = {
  __typename: 'PushNotificationHistoryMaxAggregate';
  affectedCustomers?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['UUID']['output']>;
  pushNotificationId?: Maybe<Scalars['String']['output']>;
  sendAt?: Maybe<Scalars['DateTime']['output']>;
};

export type PushNotificationHistoryMinAggregate = {
  __typename: 'PushNotificationHistoryMinAggregate';
  affectedCustomers?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['UUID']['output']>;
  pushNotificationId?: Maybe<Scalars['String']['output']>;
  sendAt?: Maybe<Scalars['DateTime']['output']>;
};

export type PushNotificationHistoryOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type PushNotificationHistoryOrderByWithRelationInput = {
  id?: InputMaybe<SortOrder>;
  pushNotification?: InputMaybe<PushNotificationOrderByWithRelationInput>;
  pushNotificationId?: InputMaybe<SortOrder>;
};

export enum PushNotificationHistoryScalarFieldEnum {
  AffectedCustomers = 'affectedCustomers',
  Id = 'id',
  PushNotificationId = 'pushNotificationId',
  SendAt = 'sendAt'
}

export type PushNotificationHistorySumAggregate = {
  __typename: 'PushNotificationHistorySumAggregate';
  affectedCustomers?: Maybe<Scalars['Int']['output']>;
};

export type PushNotificationHistoryWhereInput = {
  AND?: InputMaybe<Array<PushNotificationHistoryWhereInput>>;
  NOT?: InputMaybe<Array<PushNotificationHistoryWhereInput>>;
  OR?: InputMaybe<Array<PushNotificationHistoryWhereInput>>;
  id?: InputMaybe<UuidFilter>;
  pushNotification?: InputMaybe<PushNotificationRelationFilter>;
  pushNotificationId?: InputMaybe<UuidFilter>;
};

export type PushNotificationHistoryWhereUniqueInput = {
  AND?: InputMaybe<Array<PushNotificationHistoryWhereInput>>;
  NOT?: InputMaybe<Array<PushNotificationHistoryWhereInput>>;
  OR?: InputMaybe<Array<PushNotificationHistoryWhereInput>>;
  id?: InputMaybe<Scalars['UUID']['input']>;
  pushNotification?: InputMaybe<PushNotificationRelationFilter>;
  pushNotificationId?: InputMaybe<UuidFilter>;
};

export type PushNotificationListRelationFilter = {
  every?: InputMaybe<PushNotificationWhereInput>;
  none?: InputMaybe<PushNotificationWhereInput>;
  some?: InputMaybe<PushNotificationWhereInput>;
};

export type PushNotificationMaxAggregate = {
  __typename: 'PushNotificationMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  cronExpression?: Maybe<Scalars['String']['output']>;
  cronInterval?: Maybe<CronInterval>;
  cronStartDate?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['UUID']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  sendAt?: Maybe<Scalars['DateTime']['output']>;
  status?: Maybe<PushNotificationStatus>;
  title?: Maybe<Scalars['String']['output']>;
  type?: Maybe<PushNotificationSendType>;
};

export type PushNotificationMinAggregate = {
  __typename: 'PushNotificationMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  cronExpression?: Maybe<Scalars['String']['output']>;
  cronInterval?: Maybe<CronInterval>;
  cronStartDate?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['UUID']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  sendAt?: Maybe<Scalars['DateTime']['output']>;
  status?: Maybe<PushNotificationStatus>;
  title?: Maybe<Scalars['String']['output']>;
  type?: Maybe<PushNotificationSendType>;
};

export type PushNotificationOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type PushNotificationOrderByWithRelationInput = {
  company?: InputMaybe<CompanyOrderByWithRelationInput>;
  companyId?: InputMaybe<SortOrder>;
  cronExpression?: InputMaybe<SortOrderInput>;
  cronInterval?: InputMaybe<SortOrderInput>;
  cronStartDate?: InputMaybe<SortOrderInput>;
  history?: InputMaybe<PushNotificationHistoryOrderByRelationAggregateInput>;
  id?: InputMaybe<SortOrder>;
  message?: InputMaybe<SortOrder>;
  sendAt?: InputMaybe<SortOrderInput>;
  status?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
};

export type PushNotificationRelationFilter = {
  is?: InputMaybe<PushNotificationWhereInput>;
  isNot?: InputMaybe<PushNotificationWhereInput>;
};

export enum PushNotificationScalarFieldEnum {
  CompanyId = 'companyId',
  CreatedAt = 'createdAt',
  CronExpression = 'cronExpression',
  CronInterval = 'cronInterval',
  CronStartDate = 'cronStartDate',
  Id = 'id',
  Message = 'message',
  SendAt = 'sendAt',
  Status = 'status',
  Title = 'title',
  Type = 'type'
}

/** The available types of push notifications. */
export enum PushNotificationSendType {
  Immediate = 'IMMEDIATE',
  Recurring = 'RECURRING',
  ScheduledOnce = 'SCHEDULED_ONCE'
}

/** The different status of push notifications based on their types */
export enum PushNotificationStatus {
  Active = 'ACTIVE',
  Pending = 'PENDING',
  Scheduled = 'SCHEDULED',
  Sent = 'SENT'
}

export type PushNotificationWhereInput = {
  AND?: InputMaybe<Array<PushNotificationWhereInput>>;
  NOT?: InputMaybe<Array<PushNotificationWhereInput>>;
  OR?: InputMaybe<Array<PushNotificationWhereInput>>;
  company?: InputMaybe<CompanyRelationFilter>;
  companyId?: InputMaybe<StringFilter>;
  cronExpression?: InputMaybe<StringNullableFilter>;
  cronInterval?: InputMaybe<EnumCronIntervalNullableFilter>;
  cronStartDate?: InputMaybe<DateTimeNullableFilter>;
  history?: InputMaybe<PushNotificationHistoryListRelationFilter>;
  id?: InputMaybe<UuidFilter>;
  message?: InputMaybe<StringFilter>;
  sendAt?: InputMaybe<DateTimeNullableFilter>;
  status?: InputMaybe<EnumPushNotificationStatusFilter>;
  title?: InputMaybe<StringFilter>;
  type?: InputMaybe<EnumPushNotificationSendTypeFilter>;
};

export type PushNotificationWhereUniqueInput = {
  AND?: InputMaybe<Array<PushNotificationWhereInput>>;
  NOT?: InputMaybe<Array<PushNotificationWhereInput>>;
  OR?: InputMaybe<Array<PushNotificationWhereInput>>;
  company?: InputMaybe<CompanyRelationFilter>;
  companyId?: InputMaybe<StringFilter>;
  cronExpression?: InputMaybe<StringNullableFilter>;
  cronInterval?: InputMaybe<EnumCronIntervalNullableFilter>;
  cronStartDate?: InputMaybe<DateTimeNullableFilter>;
  history?: InputMaybe<PushNotificationHistoryListRelationFilter>;
  id?: InputMaybe<Scalars['UUID']['input']>;
  message?: InputMaybe<StringFilter>;
  sendAt?: InputMaybe<DateTimeNullableFilter>;
  status?: InputMaybe<EnumPushNotificationStatusFilter>;
  title?: InputMaybe<StringFilter>;
  type?: InputMaybe<EnumPushNotificationSendTypeFilter>;
};

export type Query = {
  __typename: 'Query';
  about: AboutModel;
  achievements: Array<CustomerAchievement>;
  aggregateReceivedPoint: AggregateReceivedPoint;
  balance: Balance;
  balances: Array<Balance>;
  branchOffice: BranchOffice;
  branchOffices: Array<BranchOffice>;
  calculationRule: CalculationRule;
  calculationRules: Array<CalculationRule>;
  challenge: Challenge;
  challengePeriod: ChallengePeriod;
  challengePeriods: Array<ChallengePeriod>;
  challenges: Array<Challenge>;
  companies: Array<Company>;
  company: Company;
  companyCrmConfig: CompanyCrmConfig;
  companyCrmConfigs: Array<CompanyCrmConfig>;
  countCustomer: Scalars['Float']['output'];
  countRedeemedPoint: Scalars['Float']['output'];
  coupon: Coupon;
  couponPeriod: CouponPeriod;
  couponPeriods: Array<CouponPeriod>;
  coupons: Array<Coupon>;
  customer: Customer;
  customerChallenge: CustomerChallenge;
  customerChallenges: Array<CustomerChallenge>;
  customerCrmConfig: CustomerCrmConfig;
  customerCrmConfigs: Array<CustomerCrmConfig>;
  customerSeries: CustomerTrainingSeries;
  customerStatistic: CustomerStatistic;
  customerStatistics: Array<CustomerStatistic>;
  customerTopRanking: Array<CustomerTopRanking>;
  customerTrainingOverview: Array<CustomerTrainingOverview>;
  customerTrend: TrendMetric;
  customers: Array<Customer>;
  employee: Employee;
  employees: Array<Employee>;
  filteredCoupons: ActiveCouponModel;
  imageTemplate: ImageTemplate;
  imageTemplates: Array<ImageTemplate>;
  paginatedCustomers: PaginatedCustomer;
  pointSystem?: Maybe<PointSystem>;
  pointSystemType: PointSystemType;
  pointSystemTypes: Array<PointSystemType>;
  pointSystems: Array<PointSystem>;
  promotion: Promotion;
  promotionPeriod: PromotionPeriod;
  promotionPeriods: Array<PromotionPeriod>;
  promotions: Array<Promotion>;
  /** Promotions are only returned for companies that are visible!! If filtered on a location, only active promotions in this location will be returned. If no location filter is configured, the active flag can be used to either return only active promotions, or all promotions. Setting of active flag in combination with the location filter does not take any effect. Running this without any input will return all promotions */
  promotionsByLocation: Array<Promotion>;
  publicBranchOffices: Array<BranchOffice>;
  pushNotification: PushNotification;
  pushNotificationHistories: Array<PushNotificationHistory>;
  pushNotificationHistory: PushNotificationHistory;
  pushNotifications: Array<PushNotification>;
  receivedPoint: ReceivedPoint;
  receivedPointTrend: TrendMetric;
  receivedPoints: Array<ReceivedPoint>;
  receivedVisitBasedPointsPerDay: Array<DateGroupedCount>;
  redeemedCoupon: RedeemedCoupon;
  redeemedCouponByTypeCount: Array<RedeemedCouponByTypeCount>;
  redeemedCoupons: Array<RedeemedCoupon>;
  redeemedPoint: RedeemedPoint;
  redeemedPointTrend: TrendMetric;
  redeemedPoints: Array<RedeemedPoint>;
  reward: Reward;
  rewards: Array<Reward>;
  searchForCustomerInMagicline: Array<MagiclineCustomer>;
  /** @deprecated Use rewards query instead */
  summarizedRedeemedPoints: Array<SummarizedRedeemedPointModel>;
};


export type QueryAchievementsArgs = {
  limit?: InputMaybe<Scalars['Float']['input']>;
};


export type QueryAggregateReceivedPointArgs = {
  _avg?: InputMaybe<ReceivedPointAvgAggregateInput>;
  _count?: InputMaybe<ReceivedPointCountAggregateInput>;
  _max?: InputMaybe<ReceivedPointMaxAggregateInput>;
  _min?: InputMaybe<ReceivedPointMinAggregateInput>;
  _sum?: InputMaybe<ReceivedPointSumAggregateInput>;
  cursor?: InputMaybe<ReceivedPointWhereUniqueInput>;
  orderBy?: InputMaybe<Array<ReceivedPointOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ReceivedPointWhereInput>;
};


export type QueryBalanceArgs = {
  where: BalanceWhereUniqueInput;
};


export type QueryBalancesArgs = {
  cursor?: InputMaybe<BalanceWhereUniqueInput>;
  distinct?: InputMaybe<Array<BalanceScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<BalanceOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<BalanceWhereInput>;
};


export type QueryBranchOfficeArgs = {
  where: BranchOfficeWhereUniqueInput;
};


export type QueryBranchOfficesArgs = {
  cursor?: InputMaybe<BranchOfficeWhereUniqueInput>;
  distinct?: InputMaybe<Array<BranchOfficeScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<BranchOfficeOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<BranchOfficeWhereInput>;
};


export type QueryCalculationRuleArgs = {
  where: CalculationRuleWhereUniqueInput;
};


export type QueryCalculationRulesArgs = {
  cursor?: InputMaybe<CalculationRuleWhereUniqueInput>;
  distinct?: InputMaybe<Array<CalculationRuleScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CalculationRuleOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CalculationRuleWhereInput>;
};


export type QueryChallengeArgs = {
  where: ChallengeWhereUniqueInput;
};


export type QueryChallengePeriodArgs = {
  where: ChallengePeriodWhereUniqueInput;
};


export type QueryChallengePeriodsArgs = {
  cursor?: InputMaybe<ChallengePeriodWhereUniqueInput>;
  distinct?: InputMaybe<Array<ChallengePeriodScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ChallengePeriodOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ChallengePeriodWhereInput>;
};


export type QueryChallengesArgs = {
  cursor?: InputMaybe<ChallengeWhereUniqueInput>;
  distinct?: InputMaybe<Array<ChallengeScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ChallengeOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ChallengeWhereInput>;
};


export type QueryCompaniesArgs = {
  cursor?: InputMaybe<CompanyWhereUniqueInput>;
  distinct?: InputMaybe<Array<CompanyScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CompanyOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CompanyWhereInput>;
};


export type QueryCompanyArgs = {
  where?: InputMaybe<CompanyUniqueInput>;
};


export type QueryCompanyCrmConfigArgs = {
  where: CompanyCrmConfigWhereUniqueInput;
};


export type QueryCompanyCrmConfigsArgs = {
  cursor?: InputMaybe<CompanyCrmConfigWhereUniqueInput>;
  distinct?: InputMaybe<Array<CompanyCrmConfigScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CompanyCrmConfigOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CompanyCrmConfigWhereInput>;
};


export type QueryCountCustomerArgs = {
  cursor?: InputMaybe<CustomerWhereUniqueInput>;
  orderBy?: InputMaybe<Array<CustomerOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CustomerWhereInput>;
};


export type QueryCountRedeemedPointArgs = {
  cursor?: InputMaybe<RedeemedPointWhereUniqueInput>;
  orderBy?: InputMaybe<Array<RedeemedPointOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<RedeemedPointWhereInput>;
};


export type QueryCouponArgs = {
  where: CouponWhereUniqueInput;
};


export type QueryCouponPeriodArgs = {
  where: CouponPeriodWhereUniqueInput;
};


export type QueryCouponPeriodsArgs = {
  cursor?: InputMaybe<CouponPeriodWhereUniqueInput>;
  distinct?: InputMaybe<Array<CouponPeriodScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CouponPeriodOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CouponPeriodWhereInput>;
};


export type QueryCouponsArgs = {
  cursor?: InputMaybe<CouponWhereUniqueInput>;
  distinct?: InputMaybe<Array<CouponScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CouponOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CouponWhereInput>;
};


export type QueryCustomerArgs = {
  where: CustomerWhereUniqueInput;
};


export type QueryCustomerChallengeArgs = {
  where: CustomerChallengeWhereUniqueInput;
};


export type QueryCustomerChallengesArgs = {
  cursor?: InputMaybe<CustomerChallengeWhereUniqueInput>;
  distinct?: InputMaybe<Array<CustomerChallengeScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CustomerChallengeOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CustomerChallengeWhereInput>;
};


export type QueryCustomerCrmConfigArgs = {
  where: CustomerCrmConfigWhereUniqueInput;
};


export type QueryCustomerCrmConfigsArgs = {
  cursor?: InputMaybe<CustomerCrmConfigWhereUniqueInput>;
  distinct?: InputMaybe<Array<CustomerCrmConfigScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CustomerCrmConfigOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CustomerCrmConfigWhereInput>;
};


export type QueryCustomerSeriesArgs = {
  where: CustomerWhereUniqueInput;
};


export type QueryCustomerStatisticArgs = {
  where: CustomerStatisticWhereUniqueInput;
};


export type QueryCustomerStatisticsArgs = {
  cursor?: InputMaybe<CustomerStatisticWhereUniqueInput>;
  distinct?: InputMaybe<Array<CustomerStatisticScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CustomerStatisticOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CustomerStatisticWhereInput>;
};


export type QueryCustomerTopRankingArgs = {
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
};


export type QueryCustomerTrainingOverviewArgs = {
  where: CustomerWhereUniqueInput;
};


export type QueryCustomerTrendArgs = {
  endDate: Scalars['DateTime']['input'];
  startDate: Scalars['DateTime']['input'];
  timezone?: Scalars['TimeZone']['input'];
};


export type QueryCustomersArgs = {
  cursor?: InputMaybe<CustomerWhereUniqueInput>;
  distinct?: InputMaybe<Array<CustomerScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CustomerOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CustomerWhereInput>;
};


export type QueryEmployeeArgs = {
  where: EmployeeWhereUniqueInput;
};


export type QueryEmployeesArgs = {
  cursor?: InputMaybe<EmployeeWhereUniqueInput>;
  distinct?: InputMaybe<Array<EmployeeScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<EmployeeOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<EmployeeWhereInput>;
};


export type QueryFilteredCouponsArgs = {
  data: ActiveCouponFilterInput;
};


export type QueryImageTemplateArgs = {
  where: ImageTemplateWhereUniqueInput;
};


export type QueryImageTemplatesArgs = {
  cursor?: InputMaybe<ImageTemplateWhereUniqueInput>;
  distinct?: InputMaybe<Array<ImageTemplateScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ImageTemplateOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ImageTemplateWhereInput>;
};


export type QueryPaginatedCustomersArgs = {
  cursor?: InputMaybe<CustomerWhereUniqueInput>;
  distinct?: InputMaybe<Array<CustomerScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CustomerOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CustomerWhereInput>;
};


export type QueryPointSystemTypeArgs = {
  where: PointSystemTypeWhereUniqueInput;
};


export type QueryPointSystemTypesArgs = {
  cursor?: InputMaybe<PointSystemTypeWhereUniqueInput>;
  distinct?: InputMaybe<Array<PointSystemTypeScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PointSystemTypeOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PointSystemTypeWhereInput>;
};


export type QueryPointSystemsArgs = {
  cursor?: InputMaybe<PointSystemWhereUniqueInput>;
  distinct?: InputMaybe<Array<PointSystemScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PointSystemOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PointSystemWhereInput>;
};


export type QueryPromotionArgs = {
  where: PromotionWhereUniqueInput;
};


export type QueryPromotionPeriodArgs = {
  where: PromotionPeriodWhereUniqueInput;
};


export type QueryPromotionPeriodsArgs = {
  cursor?: InputMaybe<PromotionPeriodWhereUniqueInput>;
  distinct?: InputMaybe<Array<PromotionPeriodScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PromotionPeriodOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PromotionPeriodWhereInput>;
};


export type QueryPromotionsArgs = {
  cursor?: InputMaybe<PromotionWhereUniqueInput>;
  distinct?: InputMaybe<Array<PromotionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PromotionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PromotionWhereInput>;
};


export type QueryPromotionsByLocationArgs = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  companyId: Scalars['String']['input'];
  filterInput?: InputMaybe<LocationFilterInput>;
};


export type QueryPublicBranchOfficesArgs = {
  cursor?: InputMaybe<BranchOfficeWhereUniqueInput>;
  distinct?: InputMaybe<Array<BranchOfficeScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<BranchOfficeOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<BranchOfficeWhereInput>;
};


export type QueryPushNotificationArgs = {
  where: PushNotificationWhereUniqueInput;
};


export type QueryPushNotificationHistoriesArgs = {
  cursor?: InputMaybe<PushNotificationHistoryWhereUniqueInput>;
  distinct?: InputMaybe<Array<PushNotificationHistoryScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PushNotificationHistoryOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PushNotificationHistoryWhereInput>;
};


export type QueryPushNotificationHistoryArgs = {
  where: PushNotificationHistoryWhereUniqueInput;
};


export type QueryPushNotificationsArgs = {
  cursor?: InputMaybe<PushNotificationWhereUniqueInput>;
  distinct?: InputMaybe<Array<PushNotificationScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PushNotificationOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PushNotificationWhereInput>;
};


export type QueryReceivedPointArgs = {
  where: ReceivedPointWhereUniqueInput;
};


export type QueryReceivedPointTrendArgs = {
  endDate: Scalars['DateTime']['input'];
  startDate: Scalars['DateTime']['input'];
  timezone?: Scalars['TimeZone']['input'];
};


export type QueryReceivedPointsArgs = {
  cursor?: InputMaybe<ReceivedPointWhereUniqueInput>;
  distinct?: InputMaybe<Array<ReceivedPointScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ReceivedPointOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ReceivedPointWhereInput>;
};


export type QueryReceivedVisitBasedPointsPerDayArgs = {
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
};


export type QueryRedeemedCouponArgs = {
  where: RedeemedCouponWhereUniqueInput;
};


export type QueryRedeemedCouponsArgs = {
  cursor?: InputMaybe<RedeemedCouponWhereUniqueInput>;
  distinct?: InputMaybe<Array<RedeemedCouponScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<RedeemedCouponOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<RedeemedCouponWhereInput>;
};


export type QueryRedeemedPointArgs = {
  where: RedeemedPointWhereUniqueInput;
};


export type QueryRedeemedPointTrendArgs = {
  endDate: Scalars['DateTime']['input'];
  startDate: Scalars['DateTime']['input'];
  timezone?: Scalars['TimeZone']['input'];
};


export type QueryRedeemedPointsArgs = {
  cursor?: InputMaybe<RedeemedPointWhereUniqueInput>;
  distinct?: InputMaybe<Array<RedeemedPointScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<RedeemedPointOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<RedeemedPointWhereInput>;
};


export type QueryRewardArgs = {
  where: RewardWhereUniqueInput;
};


export type QueryRewardsArgs = {
  cursor?: InputMaybe<RewardWhereUniqueInput>;
  distinct?: InputMaybe<Array<RewardScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<RewardOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<RewardWhereInput>;
};


export type QuerySearchForCustomerInMagiclineArgs = {
  companyId?: InputMaybe<Scalars['String']['input']>;
  uniqueInput: FindUniqueMagiclineCustomerInput;
};


export type QuerySummarizedRedeemedPointsArgs = {
  cursor?: InputMaybe<RedeemedPointWhereUniqueInput>;
  distinct?: InputMaybe<Array<RedeemedPointScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<RedeemedPointOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<RedeemedPointWhereInput>;
};

export enum QueryMode {
  Default = 'default',
  Insensitive = 'insensitive'
}

export type ReceivedPoint = {
  __typename: 'ReceivedPoint';
  company: Company;
  companyId: Scalars['String']['output'];
  count?: Maybe<Scalars['Int']['output']>;
  createdAt: Scalars['DateTime']['output'];
  customer: Customer;
  customerChallenge?: Maybe<CustomerChallenge>;
  customerChallengeId?: Maybe<Scalars['UUID']['output']>;
  customerId: Scalars['String']['output'];
  id: Scalars['UUID']['output'];
  redeemedCoupon?: Maybe<RedeemedCoupon>;
  redeemedCouponId?: Maybe<Scalars['UUID']['output']>;
  sourceType: ReceivedPointSourceType;
  updatedAt: Scalars['DateTime']['output'];
};

export type ReceivedPointAvgAggregate = {
  __typename: 'ReceivedPointAvgAggregate';
  count?: Maybe<Scalars['Float']['output']>;
};

export type ReceivedPointAvgAggregateInput = {
  count?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ReceivedPointCountAggregate = {
  __typename: 'ReceivedPointCountAggregate';
  _all: Scalars['Int']['output'];
  companyId: Scalars['Int']['output'];
  count: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  customerChallengeId: Scalars['Int']['output'];
  customerId: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  redeemedCouponId: Scalars['Int']['output'];
  sourceType: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
};

export type ReceivedPointCountAggregateInput = {
  _all?: InputMaybe<Scalars['Boolean']['input']>;
  count?: InputMaybe<Scalars['Boolean']['input']>;
  createdAt?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Boolean']['input']>;
  sourceType?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ReceivedPointCreateNestedManyWithoutCustomerInput = {
  connect?: InputMaybe<Array<ReceivedPointWhereUniqueInput>>;
};

export type ReceivedPointListRelationFilter = {
  every?: InputMaybe<ReceivedPointWhereInput>;
  none?: InputMaybe<ReceivedPointWhereInput>;
  some?: InputMaybe<ReceivedPointWhereInput>;
};

export type ReceivedPointMaxAggregate = {
  __typename: 'ReceivedPointMaxAggregate';
  companyId?: Maybe<Scalars['String']['output']>;
  count?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  customerChallengeId?: Maybe<Scalars['UUID']['output']>;
  customerId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['UUID']['output']>;
  redeemedCouponId?: Maybe<Scalars['UUID']['output']>;
  sourceType?: Maybe<ReceivedPointSourceType>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type ReceivedPointMaxAggregateInput = {
  count?: InputMaybe<Scalars['Boolean']['input']>;
  createdAt?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Boolean']['input']>;
  sourceType?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ReceivedPointMinAggregate = {
  __typename: 'ReceivedPointMinAggregate';
  companyId?: Maybe<Scalars['String']['output']>;
  count?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  customerChallengeId?: Maybe<Scalars['UUID']['output']>;
  customerId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['UUID']['output']>;
  redeemedCouponId?: Maybe<Scalars['UUID']['output']>;
  sourceType?: Maybe<ReceivedPointSourceType>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type ReceivedPointMinAggregateInput = {
  count?: InputMaybe<Scalars['Boolean']['input']>;
  createdAt?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Boolean']['input']>;
  sourceType?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ReceivedPointNullableRelationFilter = {
  is?: InputMaybe<ReceivedPointWhereInput>;
  isNot?: InputMaybe<ReceivedPointWhereInput>;
};

export type ReceivedPointOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type ReceivedPointOrderByWithRelationInput = {
  company?: InputMaybe<CompanyOrderByWithRelationInput>;
  count?: InputMaybe<SortOrderInput>;
  createdAt?: InputMaybe<SortOrder>;
  customer?: InputMaybe<CustomerOrderByWithRelationInput>;
  customerChallenge?: InputMaybe<CustomerChallengeOrderByWithRelationInput>;
  id?: InputMaybe<SortOrder>;
  redeemedCoupon?: InputMaybe<RedeemedCouponOrderByWithRelationInput>;
  sourceType?: InputMaybe<SortOrder>;
};

export enum ReceivedPointScalarFieldEnum {
  CompanyId = 'companyId',
  Count = 'count',
  CreatedAt = 'createdAt',
  CustomerChallengeId = 'customerChallengeId',
  CustomerId = 'customerId',
  Id = 'id',
  RedeemedCouponId = 'redeemedCouponId',
  SourceType = 'sourceType',
  UpdatedAt = 'updatedAt'
}

export type ReceivedPointScalarWhereInput = {
  AND?: InputMaybe<Array<ReceivedPointScalarWhereInput>>;
  NOT?: InputMaybe<Array<ReceivedPointScalarWhereInput>>;
  OR?: InputMaybe<Array<ReceivedPointScalarWhereInput>>;
  count?: InputMaybe<IntNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<UuidFilter>;
  sourceType?: InputMaybe<EnumReceivedPointSourceTypeFilter>;
};

export enum ReceivedPointSourceType {
  Challenge = 'CHALLENGE',
  Coupon = 'COUPON',
  Manually = 'MANUALLY',
  ValueBased = 'VALUE_BASED',
  VisitBased = 'VISIT_BASED'
}

export type ReceivedPointSumAggregate = {
  __typename: 'ReceivedPointSumAggregate';
  count?: Maybe<Scalars['Int']['output']>;
};

export type ReceivedPointSumAggregateInput = {
  count?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ReceivedPointUpdateInput = {
  company?: InputMaybe<CompanyUpdateOneRequiredWithoutReceivedPointsNestedInput>;
  count?: InputMaybe<Scalars['Int']['input']>;
  customer?: InputMaybe<CustomerUpdateOneRequiredWithoutReceivedPointsNestedInput>;
  customerChallenge?: InputMaybe<CustomerChallengeUpdateOneWithoutReceivedPointNestedInput>;
  id?: InputMaybe<Scalars['UUID']['input']>;
  redeemedCoupon?: InputMaybe<RedeemedCouponUpdateOneWithoutReceivedPointNestedInput>;
  sourceType?: InputMaybe<ReceivedPointSourceType>;
};

export type ReceivedPointUpdateManyMutationInput = {
  count?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['UUID']['input']>;
  sourceType?: InputMaybe<ReceivedPointSourceType>;
};

export type ReceivedPointUpdateManyWithWhereWithoutCustomerInput = {
  data: ReceivedPointUpdateManyMutationInput;
  where: ReceivedPointScalarWhereInput;
};

export type ReceivedPointUpdateManyWithoutCustomerNestedInput = {
  connect?: InputMaybe<Array<ReceivedPointWhereUniqueInput>>;
  delete?: InputMaybe<Array<ReceivedPointWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ReceivedPointScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ReceivedPointWhereUniqueInput>>;
  set?: InputMaybe<Array<ReceivedPointWhereUniqueInput>>;
  updateMany?: InputMaybe<Array<ReceivedPointUpdateManyWithWhereWithoutCustomerInput>>;
};

export type ReceivedPointWhereInput = {
  AND?: InputMaybe<Array<ReceivedPointWhereInput>>;
  NOT?: InputMaybe<Array<ReceivedPointWhereInput>>;
  OR?: InputMaybe<Array<ReceivedPointWhereInput>>;
  company?: InputMaybe<CompanyRelationFilter>;
  count?: InputMaybe<IntNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  customer?: InputMaybe<CustomerRelationFilter>;
  customerChallenge?: InputMaybe<CustomerChallengeNullableRelationFilter>;
  id?: InputMaybe<UuidFilter>;
  redeemedCoupon?: InputMaybe<RedeemedCouponNullableRelationFilter>;
  sourceType?: InputMaybe<EnumReceivedPointSourceTypeFilter>;
};

export type ReceivedPointWhereUniqueInput = {
  AND?: InputMaybe<Array<ReceivedPointWhereInput>>;
  NOT?: InputMaybe<Array<ReceivedPointWhereInput>>;
  OR?: InputMaybe<Array<ReceivedPointWhereInput>>;
  company?: InputMaybe<CompanyRelationFilter>;
  count?: InputMaybe<IntNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  customer?: InputMaybe<CustomerRelationFilter>;
  customerChallenge?: InputMaybe<CustomerChallengeNullableRelationFilter>;
  id?: InputMaybe<Scalars['UUID']['input']>;
  redeemedCoupon?: InputMaybe<RedeemedCouponNullableRelationFilter>;
  sourceType?: InputMaybe<EnumReceivedPointSourceTypeFilter>;
};

export type RedeemedCoupon = {
  __typename: 'RedeemedCoupon';
  _count: RedeemedCouponCount;
  coupon: Coupon;
  couponId: Scalars['UUID']['output'];
  createdAt: Scalars['DateTime']['output'];
  customer: Customer;
  customerId: Scalars['String']['output'];
  id: Scalars['UUID']['output'];
  period?: Maybe<CouponPeriod>;
  periodId?: Maybe<Scalars['String']['output']>;
  receivedPoint?: Maybe<Array<ReceivedPoint>>;
  status: CouponStatus;
  updatedAt: Scalars['DateTime']['output'];
};

export type RedeemedCouponByTypeCount = {
  __typename: 'RedeemedCouponByTypeCount';
  count: Scalars['Float']['output'];
  type: CouponType;
};

export type RedeemedCouponCount = {
  __typename: 'RedeemedCouponCount';
  receivedPoint: Scalars['Int']['output'];
};

export type RedeemedCouponCountAggregate = {
  __typename: 'RedeemedCouponCountAggregate';
  _all: Scalars['Int']['output'];
  couponId: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  customerId: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  periodId: Scalars['Int']['output'];
  status: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
};

export type RedeemedCouponCouponIdCustomerIdPeriodIdCompoundUniqueInput = {
  couponId: Scalars['UUID']['input'];
  customerId: Scalars['String']['input'];
  periodId: Scalars['String']['input'];
};

export type RedeemedCouponCreateNestedManyWithoutCustomerInput = {
  connect?: InputMaybe<Array<RedeemedCouponWhereUniqueInput>>;
};

export type RedeemedCouponCreateNestedManyWithoutPeriodInput = {
  connect?: InputMaybe<Array<RedeemedCouponWhereUniqueInput>>;
};

export type RedeemedCouponListRelationFilter = {
  every?: InputMaybe<RedeemedCouponWhereInput>;
  none?: InputMaybe<RedeemedCouponWhereInput>;
  some?: InputMaybe<RedeemedCouponWhereInput>;
};

export type RedeemedCouponMaxAggregate = {
  __typename: 'RedeemedCouponMaxAggregate';
  couponId?: Maybe<Scalars['UUID']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  customerId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['UUID']['output']>;
  periodId?: Maybe<Scalars['String']['output']>;
  status?: Maybe<CouponStatus>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type RedeemedCouponMinAggregate = {
  __typename: 'RedeemedCouponMinAggregate';
  couponId?: Maybe<Scalars['UUID']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  customerId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['UUID']['output']>;
  periodId?: Maybe<Scalars['String']['output']>;
  status?: Maybe<CouponStatus>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type RedeemedCouponNullableRelationFilter = {
  is?: InputMaybe<RedeemedCouponWhereInput>;
  isNot?: InputMaybe<RedeemedCouponWhereInput>;
};

export type RedeemedCouponOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type RedeemedCouponOrderByWithRelationInput = {
  coupon?: InputMaybe<CouponOrderByWithRelationInput>;
  couponId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  customer?: InputMaybe<CustomerOrderByWithRelationInput>;
  customerId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  period?: InputMaybe<CouponPeriodOrderByWithRelationInput>;
  periodId?: InputMaybe<SortOrderInput>;
  receivedPoint?: InputMaybe<ReceivedPointOrderByRelationAggregateInput>;
  status?: InputMaybe<SortOrder>;
};

export enum RedeemedCouponScalarFieldEnum {
  CouponId = 'couponId',
  CreatedAt = 'createdAt',
  CustomerId = 'customerId',
  Id = 'id',
  PeriodId = 'periodId',
  Status = 'status',
  UpdatedAt = 'updatedAt'
}

export type RedeemedCouponScalarWhereInput = {
  AND?: InputMaybe<Array<RedeemedCouponScalarWhereInput>>;
  NOT?: InputMaybe<Array<RedeemedCouponScalarWhereInput>>;
  OR?: InputMaybe<Array<RedeemedCouponScalarWhereInput>>;
  couponId?: InputMaybe<UuidFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  customerId?: InputMaybe<StringFilter>;
  id?: InputMaybe<UuidFilter>;
  periodId?: InputMaybe<UuidNullableFilter>;
  status?: InputMaybe<EnumCouponStatusFilter>;
};

export type RedeemedCouponUpdateManyMutationInput = {
  id?: InputMaybe<Scalars['UUID']['input']>;
  status?: InputMaybe<CouponStatus>;
};

export type RedeemedCouponUpdateManyWithWhereWithoutCouponInput = {
  data: RedeemedCouponUpdateManyMutationInput;
  where: RedeemedCouponScalarWhereInput;
};

export type RedeemedCouponUpdateManyWithWhereWithoutCustomerInput = {
  data: RedeemedCouponUpdateManyMutationInput;
  where: RedeemedCouponScalarWhereInput;
};

export type RedeemedCouponUpdateManyWithWhereWithoutPeriodInput = {
  data: RedeemedCouponUpdateManyMutationInput;
  where: RedeemedCouponScalarWhereInput;
};

export type RedeemedCouponUpdateManyWithoutCouponNestedInput = {
  connect?: InputMaybe<Array<RedeemedCouponWhereUniqueInput>>;
  delete?: InputMaybe<Array<RedeemedCouponWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<RedeemedCouponScalarWhereInput>>;
  disconnect?: InputMaybe<Array<RedeemedCouponWhereUniqueInput>>;
  set?: InputMaybe<Array<RedeemedCouponWhereUniqueInput>>;
  updateMany?: InputMaybe<Array<RedeemedCouponUpdateManyWithWhereWithoutCouponInput>>;
};

export type RedeemedCouponUpdateManyWithoutCustomerNestedInput = {
  connect?: InputMaybe<Array<RedeemedCouponWhereUniqueInput>>;
  delete?: InputMaybe<Array<RedeemedCouponWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<RedeemedCouponScalarWhereInput>>;
  disconnect?: InputMaybe<Array<RedeemedCouponWhereUniqueInput>>;
  set?: InputMaybe<Array<RedeemedCouponWhereUniqueInput>>;
  updateMany?: InputMaybe<Array<RedeemedCouponUpdateManyWithWhereWithoutCustomerInput>>;
};

export type RedeemedCouponUpdateManyWithoutPeriodNestedInput = {
  connect?: InputMaybe<Array<RedeemedCouponWhereUniqueInput>>;
  delete?: InputMaybe<Array<RedeemedCouponWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<RedeemedCouponScalarWhereInput>>;
  disconnect?: InputMaybe<Array<RedeemedCouponWhereUniqueInput>>;
  set?: InputMaybe<Array<RedeemedCouponWhereUniqueInput>>;
  updateMany?: InputMaybe<Array<RedeemedCouponUpdateManyWithWhereWithoutPeriodInput>>;
};

export type RedeemedCouponUpdateOneWithoutReceivedPointNestedInput = {
  connect?: InputMaybe<RedeemedCouponWhereUniqueInput>;
  delete?: InputMaybe<RedeemedCouponWhereInput>;
  disconnect?: InputMaybe<RedeemedCouponWhereInput>;
};

export type RedeemedCouponWhereInput = {
  AND?: InputMaybe<Array<RedeemedCouponWhereInput>>;
  NOT?: InputMaybe<Array<RedeemedCouponWhereInput>>;
  OR?: InputMaybe<Array<RedeemedCouponWhereInput>>;
  coupon?: InputMaybe<CouponRelationFilter>;
  couponId?: InputMaybe<UuidFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  customer?: InputMaybe<CustomerRelationFilter>;
  customerId?: InputMaybe<StringFilter>;
  id?: InputMaybe<UuidFilter>;
  period?: InputMaybe<CouponPeriodNullableRelationFilter>;
  periodId?: InputMaybe<UuidNullableFilter>;
  receivedPoint?: InputMaybe<ReceivedPointListRelationFilter>;
  status?: InputMaybe<EnumCouponStatusFilter>;
};

export type RedeemedCouponWhereUniqueInput = {
  AND?: InputMaybe<Array<RedeemedCouponWhereInput>>;
  NOT?: InputMaybe<Array<RedeemedCouponWhereInput>>;
  OR?: InputMaybe<Array<RedeemedCouponWhereInput>>;
  coupon?: InputMaybe<CouponRelationFilter>;
  couponId?: InputMaybe<UuidFilter>;
  couponId_customerId_periodId?: InputMaybe<RedeemedCouponCouponIdCustomerIdPeriodIdCompoundUniqueInput>;
  createdAt?: InputMaybe<DateTimeFilter>;
  customer?: InputMaybe<CustomerRelationFilter>;
  customerId?: InputMaybe<StringFilter>;
  id?: InputMaybe<Scalars['UUID']['input']>;
  period?: InputMaybe<CouponPeriodNullableRelationFilter>;
  periodId?: InputMaybe<UuidNullableFilter>;
  receivedPoint?: InputMaybe<ReceivedPointListRelationFilter>;
  status?: InputMaybe<EnumCouponStatusFilter>;
};

export type RedeemedPoint = {
  __typename: 'RedeemedPoint';
  createdAt: Scalars['DateTime']['output'];
  customer: Customer;
  customerId: Scalars['String']['output'];
  id: Scalars['UUID']['output'];
  reward: Reward;
  rewardId: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type RedeemedPointCountAggregate = {
  __typename: 'RedeemedPointCountAggregate';
  _all: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  customerId: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  rewardId: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
};

/** Custom input for creating redeemed points */
export type RedeemedPointCreateInput = {
  /** Optionally create redeemed point for a specific customer */
  customer?: InputMaybe<CustomerCreateNestedOneWithoutRedeemedPointsInput>;
  reward: RewardCreateNestedOneWithoutRedeemedPointsInput;
};

export type RedeemedPointCreateNestedManyWithoutCustomerInput = {
  connect?: InputMaybe<Array<RedeemedPointWhereUniqueInput>>;
};

export type RedeemedPointListRelationFilter = {
  every?: InputMaybe<RedeemedPointWhereInput>;
  none?: InputMaybe<RedeemedPointWhereInput>;
  some?: InputMaybe<RedeemedPointWhereInput>;
};

export type RedeemedPointMaxAggregate = {
  __typename: 'RedeemedPointMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  customerId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['UUID']['output']>;
  rewardId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type RedeemedPointMinAggregate = {
  __typename: 'RedeemedPointMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  customerId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['UUID']['output']>;
  rewardId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type RedeemedPointOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type RedeemedPointOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  customer?: InputMaybe<CustomerOrderByWithRelationInput>;
  id?: InputMaybe<SortOrder>;
  reward?: InputMaybe<RewardOrderByWithRelationInput>;
};

export enum RedeemedPointScalarFieldEnum {
  CreatedAt = 'createdAt',
  CustomerId = 'customerId',
  Id = 'id',
  RewardId = 'rewardId',
  UpdatedAt = 'updatedAt'
}

export type RedeemedPointScalarWhereInput = {
  AND?: InputMaybe<Array<RedeemedPointScalarWhereInput>>;
  NOT?: InputMaybe<Array<RedeemedPointScalarWhereInput>>;
  OR?: InputMaybe<Array<RedeemedPointScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<UuidFilter>;
};

export type RedeemedPointUpdateInput = {
  customer?: InputMaybe<CustomerUpdateOneRequiredWithoutRedeemedPointsNestedInput>;
  id?: InputMaybe<Scalars['UUID']['input']>;
  reward?: InputMaybe<RewardUpdateOneRequiredWithoutRedeemedPointsNestedInput>;
};

export type RedeemedPointUpdateManyMutationInput = {
  id?: InputMaybe<Scalars['UUID']['input']>;
};

export type RedeemedPointUpdateManyWithWhereWithoutCustomerInput = {
  data: RedeemedPointUpdateManyMutationInput;
  where: RedeemedPointScalarWhereInput;
};

export type RedeemedPointUpdateManyWithWhereWithoutRewardInput = {
  data: RedeemedPointUpdateManyMutationInput;
  where: RedeemedPointScalarWhereInput;
};

export type RedeemedPointUpdateManyWithoutCustomerNestedInput = {
  connect?: InputMaybe<Array<RedeemedPointWhereUniqueInput>>;
  delete?: InputMaybe<Array<RedeemedPointWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<RedeemedPointScalarWhereInput>>;
  disconnect?: InputMaybe<Array<RedeemedPointWhereUniqueInput>>;
  set?: InputMaybe<Array<RedeemedPointWhereUniqueInput>>;
  updateMany?: InputMaybe<Array<RedeemedPointUpdateManyWithWhereWithoutCustomerInput>>;
};

export type RedeemedPointUpdateManyWithoutRewardNestedInput = {
  connect?: InputMaybe<Array<RedeemedPointWhereUniqueInput>>;
  delete?: InputMaybe<Array<RedeemedPointWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<RedeemedPointScalarWhereInput>>;
  disconnect?: InputMaybe<Array<RedeemedPointWhereUniqueInput>>;
  set?: InputMaybe<Array<RedeemedPointWhereUniqueInput>>;
  updateMany?: InputMaybe<Array<RedeemedPointUpdateManyWithWhereWithoutRewardInput>>;
};

export type RedeemedPointWhereInput = {
  AND?: InputMaybe<Array<RedeemedPointWhereInput>>;
  NOT?: InputMaybe<Array<RedeemedPointWhereInput>>;
  OR?: InputMaybe<Array<RedeemedPointWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  customer?: InputMaybe<CustomerRelationFilter>;
  id?: InputMaybe<UuidFilter>;
  reward?: InputMaybe<RewardRelationFilter>;
};

export type RedeemedPointWhereUniqueInput = {
  AND?: InputMaybe<Array<RedeemedPointWhereInput>>;
  NOT?: InputMaybe<Array<RedeemedPointWhereInput>>;
  OR?: InputMaybe<Array<RedeemedPointWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  customer?: InputMaybe<CustomerRelationFilter>;
  id?: InputMaybe<Scalars['UUID']['input']>;
  reward?: InputMaybe<RewardRelationFilter>;
};

/** Input registering a company with crm config */
export type RegisterCompanyCrmConfigCreateInput = {
  /** The api key for the crm system */
  apiKey: Scalars['String']['input'];
  apiUrl: Scalars['String']['input'];
  crmTenant: Scalars['String']['input'];
};

/** Custom input for registration of companies */
export type RegisterCompanyInput = {
  city: Scalars['String']['input'];
  countryCode: Scalars['CountryCode']['input'];
  /** Optional crm configuration */
  crmConfig?: InputMaybe<RegisterCompanyCrmConfigCreateInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['EmailAddress']['input'];
  houseNumber: Scalars['String']['input'];
  id: Scalars['String']['input'];
  name: Scalars['String']['input'];
  organizationName: Scalars['String']['input'];
  owner: NestedEmployeeCreateWithoutCompanyInput;
  postalCode: Scalars['PostalCode']['input'];
  state: Scalars['String']['input'];
  street: Scalars['String']['input'];
};

/** Input for registering an invited employee */
export type RegisterEmployeeInput = {
  firstName?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the employees auth0 account */
  id: Scalars['String']['input'];
  lastName?: InputMaybe<Scalars['String']['input']>;
};

/** Result of starting registration flow */
export type RegistrationFlowModel = {
  __typename: 'RegistrationFlowModel';
  /** The arn of executed state machine */
  executionArn: Scalars['String']['output'];
  /** Start date of execution */
  startDate: Scalars['DateTime']['output'];
};

export type Reward = {
  __typename: 'Reward';
  _count: RewardCount;
  company: Company;
  companyId: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['UUID']['output'];
  imageKey?: Maybe<Scalars['String']['output']>;
  imageTemplate?: Maybe<ImageTemplate>;
  imageTemplateId?: Maybe<Scalars['UUID']['output']>;
  name: Scalars['String']['output'];
  redeemedPoints?: Maybe<Array<RedeemedPoint>>;
  requiredPoints: Scalars['Int']['output'];
  saving: Scalars['Float']['output'];
  /** The method that is used to transmission the reward redemtion to the company  */
  transmissionMethod: TransmissionMethod;
  updatedAt: Scalars['DateTime']['output'];
};

export type RewardAvgAggregate = {
  __typename: 'RewardAvgAggregate';
  requiredPoints?: Maybe<Scalars['Float']['output']>;
  saving?: Maybe<Scalars['Float']['output']>;
};

export type RewardCount = {
  __typename: 'RewardCount';
  redeemedPoints: Scalars['Int']['output'];
};

export type RewardCountAggregate = {
  __typename: 'RewardCountAggregate';
  _all: Scalars['Int']['output'];
  companyId: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  deletedAt: Scalars['Int']['output'];
  description: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  imageKey: Scalars['Int']['output'];
  imageTemplateId: Scalars['Int']['output'];
  name: Scalars['Int']['output'];
  requiredPoints: Scalars['Int']['output'];
  saving: Scalars['Int']['output'];
  transmissionMethod: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
};

export type RewardCreateNestedManyWithoutImageTemplateInput = {
  connect?: InputMaybe<Array<RewardWhereUniqueInput>>;
};

export type RewardCreateNestedOneWithoutRedeemedPointsInput = {
  connect?: InputMaybe<RewardWhereUniqueInput>;
};

export type RewardListRelationFilter = {
  every?: InputMaybe<RewardWhereInput>;
  none?: InputMaybe<RewardWhereInput>;
  some?: InputMaybe<RewardWhereInput>;
};

export type RewardMaxAggregate = {
  __typename: 'RewardMaxAggregate';
  companyId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['UUID']['output']>;
  imageKey?: Maybe<Scalars['String']['output']>;
  imageTemplateId?: Maybe<Scalars['UUID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  requiredPoints?: Maybe<Scalars['Int']['output']>;
  saving?: Maybe<Scalars['Float']['output']>;
  transmissionMethod?: Maybe<TransmissionMethod>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type RewardMinAggregate = {
  __typename: 'RewardMinAggregate';
  companyId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['UUID']['output']>;
  imageKey?: Maybe<Scalars['String']['output']>;
  imageTemplateId?: Maybe<Scalars['UUID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  requiredPoints?: Maybe<Scalars['Int']['output']>;
  saving?: Maybe<Scalars['Float']['output']>;
  transmissionMethod?: Maybe<TransmissionMethod>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type RewardOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type RewardOrderByWithRelationInput = {
  company?: InputMaybe<CompanyOrderByWithRelationInput>;
  deletedAt?: InputMaybe<SortOrderInput>;
  description?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  imageKey?: InputMaybe<SortOrderInput>;
  imageTemplate?: InputMaybe<ImageTemplateOrderByWithRelationInput>;
  name?: InputMaybe<SortOrder>;
  redeemedPoints?: InputMaybe<RedeemedPointOrderByRelationAggregateInput>;
  requiredPoints?: InputMaybe<SortOrder>;
  saving?: InputMaybe<SortOrder>;
  transmissionMethod?: InputMaybe<SortOrder>;
};

export type RewardRelationFilter = {
  is?: InputMaybe<RewardWhereInput>;
  isNot?: InputMaybe<RewardWhereInput>;
};

export enum RewardScalarFieldEnum {
  CompanyId = 'companyId',
  CreatedAt = 'createdAt',
  DeletedAt = 'deletedAt',
  Description = 'description',
  Id = 'id',
  ImageKey = 'imageKey',
  ImageTemplateId = 'imageTemplateId',
  Name = 'name',
  RequiredPoints = 'requiredPoints',
  Saving = 'saving',
  TransmissionMethod = 'transmissionMethod',
  UpdatedAt = 'updatedAt'
}

export type RewardScalarWhereInput = {
  AND?: InputMaybe<Array<RewardScalarWhereInput>>;
  NOT?: InputMaybe<Array<RewardScalarWhereInput>>;
  OR?: InputMaybe<Array<RewardScalarWhereInput>>;
  deletedAt?: InputMaybe<DateTimeNullableFilter>;
  description?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<UuidFilter>;
  imageKey?: InputMaybe<StringNullableFilter>;
  name?: InputMaybe<StringFilter>;
  requiredPoints?: InputMaybe<IntFilter>;
  saving?: InputMaybe<FloatFilter>;
  transmissionMethod?: InputMaybe<EnumTransmissionMethodFilter>;
};

/** Enum of status of rewards */
export enum RewardStatus {
  Active = 'ACTIVE',
  Deleted = 'DELETED'
}

export type RewardSumAggregate = {
  __typename: 'RewardSumAggregate';
  requiredPoints?: Maybe<Scalars['Int']['output']>;
  saving?: Maybe<Scalars['Float']['output']>;
};

export type RewardUpdateInput = {
  company?: InputMaybe<CompanyUpdateOneRequiredWithoutRewardsNestedInput>;
  deletedAt?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['UUID']['input']>;
  imageKey?: InputMaybe<Scalars['String']['input']>;
  imageTemplate?: InputMaybe<ImageTemplateUpdateOneWithoutRewardsNestedInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  redeemedPoints?: InputMaybe<RedeemedPointUpdateManyWithoutRewardNestedInput>;
  requiredPoints?: InputMaybe<Scalars['Int']['input']>;
  saving?: InputMaybe<Scalars['Float']['input']>;
  transmissionMethod?: InputMaybe<TransmissionMethod>;
};

export type RewardUpdateManyMutationInput = {
  deletedAt?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['UUID']['input']>;
  imageKey?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  requiredPoints?: InputMaybe<Scalars['Int']['input']>;
  saving?: InputMaybe<Scalars['Float']['input']>;
  transmissionMethod?: InputMaybe<TransmissionMethod>;
};

export type RewardUpdateManyWithWhereWithoutImageTemplateInput = {
  data: RewardUpdateManyMutationInput;
  where: RewardScalarWhereInput;
};

export type RewardUpdateManyWithoutImageTemplateNestedInput = {
  connect?: InputMaybe<Array<RewardWhereUniqueInput>>;
  delete?: InputMaybe<Array<RewardWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<RewardScalarWhereInput>>;
  disconnect?: InputMaybe<Array<RewardWhereUniqueInput>>;
  set?: InputMaybe<Array<RewardWhereUniqueInput>>;
  updateMany?: InputMaybe<Array<RewardUpdateManyWithWhereWithoutImageTemplateInput>>;
};

export type RewardUpdateOneRequiredWithoutRedeemedPointsNestedInput = {
  connect?: InputMaybe<RewardWhereUniqueInput>;
};

export type RewardWhereInput = {
  AND?: InputMaybe<Array<RewardWhereInput>>;
  NOT?: InputMaybe<Array<RewardWhereInput>>;
  OR?: InputMaybe<Array<RewardWhereInput>>;
  company?: InputMaybe<CompanyRelationFilter>;
  deletedAt?: InputMaybe<DateTimeNullableFilter>;
  description?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<UuidFilter>;
  imageKey?: InputMaybe<StringNullableFilter>;
  imageTemplate?: InputMaybe<ImageTemplateNullableRelationFilter>;
  name?: InputMaybe<StringFilter>;
  redeemedPoints?: InputMaybe<RedeemedPointListRelationFilter>;
  requiredPoints?: InputMaybe<IntFilter>;
  saving?: InputMaybe<FloatFilter>;
  transmissionMethod?: InputMaybe<EnumTransmissionMethodFilter>;
};

export type RewardWhereUniqueInput = {
  AND?: InputMaybe<Array<RewardWhereInput>>;
  NOT?: InputMaybe<Array<RewardWhereInput>>;
  OR?: InputMaybe<Array<RewardWhereInput>>;
  company?: InputMaybe<CompanyRelationFilter>;
  deletedAt?: InputMaybe<DateTimeNullableFilter>;
  description?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<Scalars['UUID']['input']>;
  imageKey?: InputMaybe<StringNullableFilter>;
  imageTemplate?: InputMaybe<ImageTemplateNullableRelationFilter>;
  name?: InputMaybe<StringFilter>;
  redeemedPoints?: InputMaybe<RedeemedPointListRelationFilter>;
  requiredPoints?: InputMaybe<IntFilter>;
  saving?: InputMaybe<FloatFilter>;
  transmissionMethod?: InputMaybe<EnumTransmissionMethodFilter>;
};

export enum SortOrder {
  Asc = 'asc',
  Desc = 'desc'
}

export type SortOrderInput = {
  nulls?: InputMaybe<NullsOrder>;
  sort: SortOrder;
};

export type StringFilter = {
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedStringFilter>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type StringNullableFilter = {
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedStringNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

/** Model for summarized redeemed points by reward */
export type SummarizedRedeemedPointModel = {
  __typename: 'SummarizedRedeemedPointModel';
  createdAt: Scalars['DateTime']['output'];
  customer: Customer;
  customerId: Scalars['String']['output'];
  id: Scalars['UUID']['output'];
  reward: Reward;
  rewardId: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

/** Input to change coupon status */
export type SwitchCouponStatusInput = {
  /** The new status for the coupon */
  status: CouponState;
};

/** Input type to soft delete or reactivate a reward */
export type SwitchRewardStatusInput = {
  /** The ID of the reward to switch the status of */
  rewardId: Scalars['String']['input'];
  /** The status the reward should be changed to (set deletedAt based on the status) */
  status: RewardStatus;
};

export enum SystemType {
  Percentage = 'PERCENTAGE',
  Staggered = 'STAGGERED',
  VisitBased = 'VISIT_BASED'
}

export enum TransmissionMethod {
  Crm = 'CRM',
  Mail = 'MAIL'
}

export type TrendMetric = {
  __typename: 'TrendMetric';
  dateGroupedCount: Array<DateGroupedCount>;
  periodCount: Scalars['Float']['output'];
  previousPeriodCount: Scalars['Float']['output'];
  trend: Scalars['Float']['output'];
};

/** Input for updating information about a company */
export type UpdateCompanyInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['EmailAddress']['input']>;
  logoUrl?: InputMaybe<Scalars['String']['input']>;
};

/** Input for updating information of employee */
export type UpdateEmployeeInput = {
  email?: InputMaybe<Scalars['EmailAddress']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
};

export type UuidFilter = {
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedUuidFilter>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type UuidNullableFilter = {
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedUuidNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type VisitBasedInput = {
  branchOfficeId: Scalars['String']['input'];
  customerId?: InputMaybe<Scalars['String']['input']>;
};

/** Input for creating a coupon */
export type WelcomeCouponCreateInput = {
  /** The ID of the company this coupon should be created for */
  companyId?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  maxRedemptions?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  pointsCredit?: InputMaybe<Scalars['Int']['input']>;
  transmissionMethod?: InputMaybe<TransmissionMethod>;
};

export type BalanceFieldsFragment = { __typename: 'Balance', updatedAt: Date | string, createdAt: Date | string, count: number };

export type BranchOfficeCountFieldsFragment = { __typename: 'BranchOffice', id: string };

export type BranchOfficeFieldsFragment = { __typename: 'BranchOffice', id: string, name: string, companyId: string, description?: string | null, latitude: number, longitude: number, street: string, houseNumber: string, city: string, state: string, postalCode: string, countryCode: string, crmApiId: string, updatedAt: Date | string, createdAt: Date | string };

export type CalculationRuleFieldsFragment = { __typename: 'CalculationRule', id: string, points: number, value: number };

export type ChallengePeriodWithoutChallengeFieldsFragment = { __typename: 'ChallengePeriod', id: string, activeFrom: Date | string, activeUntil: Date | string };

export type ChallengePeriodFieldsFragment = { __typename: 'ChallengePeriod', id: string, activeFrom: Date | string, activeUntil: Date | string, challenge: { __typename: 'Challenge', id: string, pointsCredit: number, trainingGoal: number } };

export type ChallengeFieldsFragment = { __typename: 'Challenge', id: string, imageUrl?: string | null, active: boolean, pointsCredit: number, trainingGoal: number, createdAt: Date | string, updatedAt: Date | string, periods?: Array<{ __typename: 'ChallengePeriod', id: string, activeFrom: Date | string, activeUntil: Date | string }> | null, company: { __typename: 'Company', logoUrl?: string | null } };

export type CompanyAddressFieldsFragment = { __typename: 'Company', street: string, houseNumber: string, city: string, state: string, postalCode: string, countryCode: string };

export type CompanyInformationFieldsFragment = { __typename: 'Company', visible: boolean, name: string, email: string, description?: string | null, id: string, logoUrl?: string | null, street: string, houseNumber: string, city: string, state: string, postalCode: string, countryCode: string };

export type CompanyFieldsFragment = { __typename: 'Company', id: string, logoUrl?: string | null, visible: boolean };

export type ContactFieldsFragment = { __typename: 'Contact', id: string, requestId?: string | null };

export type CouponPeriodFieldsFragment = { __typename: 'CouponPeriod', id: string, activeFrom: Date | string, activeUntil: Date | string, createdAt: Date | string, updatedAt: Date | string, coupon: { __typename: 'Coupon', id: string, name: string, description?: string | null, type: CouponType, active: boolean, createdAt: Date | string, updatedAt: Date | string, pointsCredit?: number | null, branchOffices?: Array<{ __typename: 'BranchOffice', id: string, name: string, companyId: string, description?: string | null, latitude: number, longitude: number, street: string, houseNumber: string, city: string, state: string, postalCode: string, countryCode: string, crmApiId: string, updatedAt: Date | string, createdAt: Date | string }> | null, redemptions?: Array<{ __typename: 'RedeemedCoupon', id: string }> | null } };

export type CouponFieldsFragment = { __typename: 'Coupon', id: string, name: string, description?: string | null, type: CouponType, active: boolean, createdAt: Date | string, updatedAt: Date | string, pointsCredit?: number | null, branchOffices?: Array<{ __typename: 'BranchOffice', id: string, name: string, companyId: string, description?: string | null, latitude: number, longitude: number, street: string, houseNumber: string, city: string, state: string, postalCode: string, countryCode: string, crmApiId: string, updatedAt: Date | string, createdAt: Date | string }> | null, redemptions?: Array<{ __typename: 'RedeemedCoupon', id: string }> | null };

export type CustomerDetailsFieldsFragment = { __typename: 'Customer', firstName?: string | null, lastName?: string | null, email: string, street?: string | null, houseNumber?: string | null, city?: string | null, postalCode?: string | null, birthDate?: Date | string | null, pushToken?: string | null, createdAt: Date | string };

export type CustomerTopRankingFieldsFragment = { __typename: 'CustomerTopRanking', firstName?: string | null, lastName?: string | null, email: string, redeemedPointsTotal: number, receivedPointsTotal: number };

export type CustomerFieldsFragment = { __typename: 'Customer', id: string, firstName?: string | null, lastName?: string | null, email: string };

export type EmployeeFieldsFragment = { __typename: 'Employee', id?: string | null, firstName?: string | null, lastName?: string | null, email: string, userType: CompanyUserType, status: EmployeeStatus, updatedAt: Date | string, createdAt: Date | string };

export type PaginatedCustomerFieldsFragment = { __typename: 'PaginatedCustomer', hasNextPage: boolean, totalCount: number, nodes: Array<{ __typename: 'Customer', id: string, firstName?: string | null, lastName?: string | null, email: string }>, edges?: Array<{ __typename: 'CustomerEdge', cursor: string }> | null };

export type PointSystemCheckFieldsFragment = { __typename: 'PointSystem', pointSystemType: { __typename: 'PointSystemType', type: SystemType } };

export type PointSystemFieldsFragment = { __typename: 'PointSystem', id: string, calculationRuleEntries: number, scanLimit: number, company: { __typename: 'Company', calculationRules?: Array<{ __typename: 'CalculationRule', id: string, points: number, value: number }> | null }, pointSystemType: { __typename: 'PointSystemType', id: string, type: SystemType } };

export type PointSystemTypeFieldsFragment = { __typename: 'PointSystemType', id: string, type: SystemType };

export type PromotionPeriodFieldsFragment = { __typename: 'PromotionPeriod', id: string, updatedAt: Date | string, createdAt: Date | string, activeFrom: Date | string, activeUntil: Date | string, promotion: { __typename: 'Promotion', id: string, title: string, description?: string | null, promotionCode?: string | null, createdAt: Date | string, updatedAt: Date | string } };

export type PromotionFieldsFragment = { __typename: 'Promotion', id: string, title: string, description?: string | null, promotionCode?: string | null, createdAt: Date | string, updatedAt: Date | string };

export type PushNotificationHistoryFieldsFragment = { __typename: 'PushNotificationHistory', id: string, affectedCustomers: number, sendAt: Date | string, pushNotification: { __typename: 'PushNotification', title: string, message: string, type: PushNotificationSendType } };

export type PushNotificationFieldsFragment = { __typename: 'PushNotification', id: string, title: string, message: string, type: PushNotificationSendType, status: PushNotificationStatus, cronExpression?: string | null, cronStartDate?: Date | string | null, cronInterval?: CronInterval | null, sendAt?: Date | string | null, createdAt: Date | string };

export type ReceivedPointFieldsFragment = { __typename: 'ReceivedPoint', id: string, count?: number | null, createdAt: Date | string, updatedAt: Date | string, sourceType: ReceivedPointSourceType };

export type RedeemedPointFieldsFragment = { __typename: 'RedeemedPoint', id: string, createdAt: Date | string, updatedAt: Date | string, reward: { __typename: 'Reward', id: string, name: string, description?: string | null, requiredPoints: number, saving: number, imageKey?: string | null, transmissionMethod: TransmissionMethod, updatedAt: Date | string, createdAt: Date | string } };

export type RewardFieldsFragment = { __typename: 'Reward', id: string, name: string, description?: string | null, requiredPoints: number, saving: number, imageKey?: string | null, transmissionMethod: TransmissionMethod, updatedAt: Date | string, createdAt: Date | string };

export type RewardsChartFieldsFragment = { __typename: 'Reward', id: string, name: string, description?: string | null, requiredPoints: number, saving: number, imageKey?: string | null, transmissionMethod: TransmissionMethod, updatedAt: Date | string, createdAt: Date | string, _count: { __typename: 'RewardCount', redeemedPoints: number } };

export type TrendMetricFieldsFragment = { __typename: 'TrendMetric', periodCount: number, previousPeriodCount: number, trend: number, dateGroupedCount: Array<{ __typename: 'DateGroupedCount', date: Date | string, count: number }> };

export type CreateBranchOfficeMutationVariables = Exact<{
  data: BranchOfficeCreateInput;
}>;


export type CreateBranchOfficeMutation = { __typename: 'Mutation', createBranchOffice: { __typename: 'BranchOffice', id: string, name: string, companyId: string, description?: string | null, latitude: number, longitude: number, street: string, houseNumber: string, city: string, state: string, postalCode: string, countryCode: string, crmApiId: string, updatedAt: Date | string, createdAt: Date | string } };

export type DeleteBranchOfficeMutationVariables = Exact<{
  where: BranchOfficeUniqueInput;
}>;


export type DeleteBranchOfficeMutation = { __typename: 'Mutation', deleteBranchOffice: { __typename: 'BranchOffice', id: string } };

export type UpdateBranchOfficeMutationVariables = Exact<{
  data: BranchOfficeUpdateInput;
  where: BranchOfficeWhereUniqueInput;
}>;


export type UpdateBranchOfficeMutation = { __typename: 'Mutation', updateBranchOffice: { __typename: 'BranchOffice', id: string, name: string, companyId: string, description?: string | null, latitude: number, longitude: number, street: string, houseNumber: string, city: string, state: string, postalCode: string, countryCode: string, crmApiId: string, updatedAt: Date | string, createdAt: Date | string } };

export type CreateOrChangeCalculationRuleMutationVariables = Exact<{
  data: CreateOrChangeCalculationRuleInput;
}>;


export type CreateOrChangeCalculationRuleMutation = { __typename: 'Mutation', createOrChangeCalculationRule: Array<{ __typename: 'CalculationRule', id: string, points: number, value: number }> };

export type CreateChallengePeriodMutationVariables = Exact<{
  data: ChallengePeriodCreateInput;
}>;


export type CreateChallengePeriodMutation = { __typename: 'Mutation', createChallengePeriod: { __typename: 'ChallengePeriod', id: string, activeFrom: Date | string, activeUntil: Date | string, challenge: { __typename: 'Challenge', id: string, pointsCredit: number, trainingGoal: number } } };

export type DeleteChallengePeriodMutationVariables = Exact<{
  where: ChallengePeriodWhereUniqueInput;
}>;


export type DeleteChallengePeriodMutation = { __typename: 'Mutation', deleteChallengePeriod: { __typename: 'ChallengePeriod', id: string } };

export type UpdateChallengePeriodMutationVariables = Exact<{
  where: ChallengePeriodWhereUniqueInput;
  data: ChallengePeriodUpdateInput;
}>;


export type UpdateChallengePeriodMutation = { __typename: 'Mutation', updateChallengePeriod: { __typename: 'ChallengePeriod', id: string, activeFrom: Date | string, activeUntil: Date | string, challenge: { __typename: 'Challenge', id: string, pointsCredit: number, trainingGoal: number } } };

export type CreateChallengeMutationVariables = Exact<{
  data: ChallengeCreateInput;
}>;


export type CreateChallengeMutation = { __typename: 'Mutation', createChallenge: { __typename: 'Challenge', id: string, imageUrl?: string | null, active: boolean, pointsCredit: number, trainingGoal: number, createdAt: Date | string, updatedAt: Date | string, periods?: Array<{ __typename: 'ChallengePeriod', id: string, activeFrom: Date | string, activeUntil: Date | string }> | null, company: { __typename: 'Company', logoUrl?: string | null } } };

export type DeleteChallengeMutationVariables = Exact<{
  where: ChallengeWhereUniqueInput;
}>;


export type DeleteChallengeMutation = { __typename: 'Mutation', deleteChallenge: { __typename: 'Challenge', id: string } };

export type UpdateChallengeImageMutationVariables = Exact<{
  where: ChallengeWhereUniqueInput;
  data: ChallengeImageInput;
}>;


export type UpdateChallengeImageMutation = { __typename: 'Mutation', updateChallengeImage: { __typename: 'Challenge', id: string } };

export type ChangeVisibilityMutationVariables = Exact<{
  visible: Scalars['Boolean']['input'];
}>;


export type ChangeVisibilityMutation = { __typename: 'Mutation', changeVisibility: { __typename: 'Company', id: string, logoUrl?: string | null, visible: boolean } };

export type UpdateCompanyMutationVariables = Exact<{
  data: UpdateCompanyInput;
  where?: InputMaybe<CompanyUniqueInput>;
}>;


export type UpdateCompanyMutation = { __typename: 'Mutation', updateCompany: { __typename: 'Company', visible: boolean, name: string, email: string, description?: string | null, id: string, logoUrl?: string | null, street: string, houseNumber: string, city: string, state: string, postalCode: string, countryCode: string } };

export type ContactSupportMutationVariables = Exact<{
  data: ContactSupportInput;
}>;


export type ContactSupportMutation = { __typename: 'Mutation', contactSupport: { __typename: 'Contact', id: string, requestId?: string | null } };

export type CreateCouponPeriodMutationVariables = Exact<{
  data: CouponPeriodCreateInput;
}>;


export type CreateCouponPeriodMutation = { __typename: 'Mutation', createCouponPeriod: { __typename: 'CouponPeriod', id: string, activeFrom: Date | string, activeUntil: Date | string, createdAt: Date | string, updatedAt: Date | string, coupon: { __typename: 'Coupon', id: string, name: string, description?: string | null, type: CouponType, active: boolean, createdAt: Date | string, updatedAt: Date | string, pointsCredit?: number | null, branchOffices?: Array<{ __typename: 'BranchOffice', id: string, name: string, companyId: string, description?: string | null, latitude: number, longitude: number, street: string, houseNumber: string, city: string, state: string, postalCode: string, countryCode: string, crmApiId: string, updatedAt: Date | string, createdAt: Date | string }> | null, redemptions?: Array<{ __typename: 'RedeemedCoupon', id: string }> | null } } };

export type DeleteCouponPeriodMutationVariables = Exact<{
  where: CouponPeriodWhereUniqueInput;
}>;


export type DeleteCouponPeriodMutation = { __typename: 'Mutation', deleteCouponPeriod: { __typename: 'CouponPeriod', id: string } };

export type UpdateCouponPeriodMutationVariables = Exact<{
  where: CouponPeriodWhereUniqueInput;
  data: CouponPeriodUpdateInput;
}>;


export type UpdateCouponPeriodMutation = { __typename: 'Mutation', updateCouponPeriod: { __typename: 'CouponPeriod', id: string, activeFrom: Date | string, activeUntil: Date | string, createdAt: Date | string, updatedAt: Date | string, coupon: { __typename: 'Coupon', id: string, name: string, description?: string | null, type: CouponType, active: boolean, createdAt: Date | string, updatedAt: Date | string, pointsCredit?: number | null, branchOffices?: Array<{ __typename: 'BranchOffice', id: string, name: string, companyId: string, description?: string | null, latitude: number, longitude: number, street: string, houseNumber: string, city: string, state: string, postalCode: string, countryCode: string, crmApiId: string, updatedAt: Date | string, createdAt: Date | string }> | null, redemptions?: Array<{ __typename: 'RedeemedCoupon', id: string }> | null } } };

export type CreateCouponMutationVariables = Exact<{
  data: CouponCreateInput;
}>;


export type CreateCouponMutation = { __typename: 'Mutation', createCoupon: { __typename: 'Coupon', id: string, name: string, description?: string | null, type: CouponType, active: boolean, createdAt: Date | string, updatedAt: Date | string, pointsCredit?: number | null, branchOffices?: Array<{ __typename: 'BranchOffice', id: string, name: string, companyId: string, description?: string | null, latitude: number, longitude: number, street: string, houseNumber: string, city: string, state: string, postalCode: string, countryCode: string, crmApiId: string, updatedAt: Date | string, createdAt: Date | string }> | null, redemptions?: Array<{ __typename: 'RedeemedCoupon', id: string }> | null } };

export type CreateWelcomeCouponMutationVariables = Exact<{
  data: WelcomeCouponCreateInput;
}>;


export type CreateWelcomeCouponMutation = { __typename: 'Mutation', createWelcomeCoupon: { __typename: 'Coupon', id: string, name: string, description?: string | null, type: CouponType, active: boolean, createdAt: Date | string, updatedAt: Date | string, pointsCredit?: number | null, branchOffices?: Array<{ __typename: 'BranchOffice', id: string, name: string, companyId: string, description?: string | null, latitude: number, longitude: number, street: string, houseNumber: string, city: string, state: string, postalCode: string, countryCode: string, crmApiId: string, updatedAt: Date | string, createdAt: Date | string }> | null, redemptions?: Array<{ __typename: 'RedeemedCoupon', id: string }> | null } };

export type DeleteCouponMutationVariables = Exact<{
  where: CouponWhereUniqueInput;
}>;


export type DeleteCouponMutation = { __typename: 'Mutation', deleteCoupon: { __typename: 'Coupon', id: string } };

export type SwitchCouponStatusMutationVariables = Exact<{
  where: CouponUniqueInput;
  data: SwitchCouponStatusInput;
}>;


export type SwitchCouponStatusMutation = { __typename: 'Mutation', switchCouponStatus: { __typename: 'Coupon', id: string, name: string, description?: string | null, type: CouponType, active: boolean, createdAt: Date | string, updatedAt: Date | string, pointsCredit?: number | null, branchOffices?: Array<{ __typename: 'BranchOffice', id: string, name: string, companyId: string, description?: string | null, latitude: number, longitude: number, street: string, houseNumber: string, city: string, state: string, postalCode: string, countryCode: string, crmApiId: string, updatedAt: Date | string, createdAt: Date | string }> | null, redemptions?: Array<{ __typename: 'RedeemedCoupon', id: string }> | null } };

export type DeleteEmployeeMutationVariables = Exact<{
  where: EmployeeIdentifierInput;
}>;


export type DeleteEmployeeMutation = { __typename: 'Mutation', deleteEmployee: { __typename: 'Employee', id?: string | null } };

export type InviteEmployeeMutationVariables = Exact<{
  email: Scalars['String']['input'];
}>;


export type InviteEmployeeMutation = { __typename: 'Mutation', inviteEmployee: { __typename: 'Employee', id?: string | null, firstName?: string | null, lastName?: string | null, email: string, userType: CompanyUserType, status: EmployeeStatus, updatedAt: Date | string, createdAt: Date | string } };

export type SendPasswordChangeEmailMutationVariables = Exact<{ [key: string]: never; }>;


export type SendPasswordChangeEmailMutation = { __typename: 'Mutation', sendPasswordChangeEmail: { __typename: 'Employee', id?: string | null, firstName?: string | null, lastName?: string | null, email: string, userType: CompanyUserType, status: EmployeeStatus, updatedAt: Date | string, createdAt: Date | string } };

export type UpdateEmployeeMutationVariables = Exact<{
  data: UpdateEmployeeInput;
  where?: InputMaybe<EmployeeUniqueInput>;
}>;


export type UpdateEmployeeMutation = { __typename: 'Mutation', updateEmployee: { __typename: 'Employee', id?: string | null, firstName?: string | null, lastName?: string | null, email: string, userType: CompanyUserType, status: EmployeeStatus, updatedAt: Date | string, createdAt: Date | string } };

export type ChangePointSystemMutationVariables = Exact<{
  input: ChangePointSystemInput;
}>;


export type ChangePointSystemMutation = { __typename: 'Mutation', changePointSystem: { __typename: 'PointSystem', id: string, calculationRuleEntries: number, scanLimit: number, company: { __typename: 'Company', calculationRules?: Array<{ __typename: 'CalculationRule', id: string, points: number, value: number }> | null }, pointSystemType: { __typename: 'PointSystemType', id: string, type: SystemType } } };

export type CreatePointSystemMutationVariables = Exact<{
  createInput: PointSystemCreateInput;
}>;


export type CreatePointSystemMutation = { __typename: 'Mutation', createPointSystem: { __typename: 'PointSystem', id: string, calculationRuleEntries: number, scanLimit: number, company: { __typename: 'Company', calculationRules?: Array<{ __typename: 'CalculationRule', id: string, points: number, value: number }> | null }, pointSystemType: { __typename: 'PointSystemType', id: string, type: SystemType } } };

export type CreatePromotionPeriodMutationVariables = Exact<{
  data: PromotionPeriodCreateInput;
}>;


export type CreatePromotionPeriodMutation = { __typename: 'Mutation', createPromotionPeriod: { __typename: 'PromotionPeriod', id: string, updatedAt: Date | string, createdAt: Date | string, activeFrom: Date | string, activeUntil: Date | string, promotion: { __typename: 'Promotion', id: string, title: string, description?: string | null, promotionCode?: string | null, createdAt: Date | string, updatedAt: Date | string } } };

export type DeletePromotionPeriodMutationVariables = Exact<{
  where: PromotionPeriodWhereUniqueInput;
}>;


export type DeletePromotionPeriodMutation = { __typename: 'Mutation', deletePromotionPeriod: { __typename: 'PromotionPeriod', id: string } };

export type UpdatePromotionPeriodMutationVariables = Exact<{
  where: PromotionPeriodUniqueInput;
  data: PromotionPeriodUpdateInput;
}>;


export type UpdatePromotionPeriodMutation = { __typename: 'Mutation', updatePromotionPeriod: { __typename: 'PromotionPeriod', id: string, updatedAt: Date | string, createdAt: Date | string, activeFrom: Date | string, activeUntil: Date | string, promotion: { __typename: 'Promotion', id: string, title: string, description?: string | null, promotionCode?: string | null, createdAt: Date | string, updatedAt: Date | string } } };

export type CreatePromotionMutationVariables = Exact<{
  data: PromotionCreateInput;
}>;


export type CreatePromotionMutation = { __typename: 'Mutation', createPromotion: { __typename: 'Promotion', id: string, title: string, description?: string | null, promotionCode?: string | null, createdAt: Date | string, updatedAt: Date | string } };

export type DeletePromotionMutationVariables = Exact<{
  where: PromotionWhereUniqueInput;
}>;


export type DeletePromotionMutation = { __typename: 'Mutation', deletePromotion: { __typename: 'Promotion', id: string } };

export type CreatePushNotificationMutationVariables = Exact<{
  createInput: PushNotificationCreateInput;
}>;


export type CreatePushNotificationMutation = { __typename: 'Mutation', createPushNotification: { __typename: 'PushNotification', id: string, title: string, message: string, type: PushNotificationSendType, status: PushNotificationStatus, cronExpression?: string | null, cronStartDate?: Date | string | null, cronInterval?: CronInterval | null, sendAt?: Date | string | null, createdAt: Date | string } };

export type DeletePushNotificationMutationVariables = Exact<{
  where: PushNotificationWhereUniqueInput;
}>;


export type DeletePushNotificationMutation = { __typename: 'Mutation', deletePushNotification: { __typename: 'PushNotification', id: string } };

export type DisablePushNotificationMutationVariables = Exact<{
  pushNotificationId: Scalars['String']['input'];
}>;


export type DisablePushNotificationMutation = { __typename: 'Mutation', disablePushNotification: { __typename: 'PushNotification', id: string } };

export type CreateManuallyReceivedPointMutationVariables = Exact<{
  customer: CustomerWhereUniqueInput;
  count: Scalars['Float']['input'];
}>;


export type CreateManuallyReceivedPointMutation = { __typename: 'Mutation', createManuallyReceivedPoint: { __typename: 'ReceivedPoint', id: string, count?: number | null, createdAt: Date | string, updatedAt: Date | string, sourceType: ReceivedPointSourceType } };

export type CreateRewardMutationVariables = Exact<{
  data: CreateRewardInput;
}>;


export type CreateRewardMutation = { __typename: 'Mutation', createReward: { __typename: 'Reward', id: string, name: string, description?: string | null, requiredPoints: number, saving: number, imageKey?: string | null, transmissionMethod: TransmissionMethod, updatedAt: Date | string, createdAt: Date | string } };

export type SwitchRewardStatusMutationVariables = Exact<{
  input: SwitchRewardStatusInput;
}>;


export type SwitchRewardStatusMutation = { __typename: 'Mutation', switchRewardStatus: { __typename: 'Reward', id: string } };

export type UpdateRewardMutationVariables = Exact<{
  data: RewardUpdateInput;
  where: RewardWhereUniqueInput;
}>;


export type UpdateRewardMutation = { __typename: 'Mutation', updateReward: { __typename: 'Reward', id: string, name: string, description?: string | null, requiredPoints: number, saving: number, imageKey?: string | null, transmissionMethod: TransmissionMethod, updatedAt: Date | string, createdAt: Date | string } };

export type BranchOfficesQueryVariables = Exact<{
  where?: InputMaybe<BranchOfficeWhereInput>;
  oderBy?: InputMaybe<Array<BranchOfficeOrderByWithRelationInput> | BranchOfficeOrderByWithRelationInput>;
  cursor?: InputMaybe<BranchOfficeWhereUniqueInput>;
  take?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  distinct?: InputMaybe<Array<BranchOfficeScalarFieldEnum> | BranchOfficeScalarFieldEnum>;
}>;


export type BranchOfficesQuery = { __typename: 'Query', branchOffices: Array<{ __typename: 'BranchOffice', id: string, name: string, companyId: string, description?: string | null, latitude: number, longitude: number, street: string, houseNumber: string, city: string, state: string, postalCode: string, countryCode: string, crmApiId: string, updatedAt: Date | string, createdAt: Date | string }> };

export type ChallengePeriodsQueryVariables = Exact<{
  where?: InputMaybe<ChallengePeriodWhereInput>;
  orderBy?: InputMaybe<Array<ChallengePeriodOrderByWithRelationInput> | ChallengePeriodOrderByWithRelationInput>;
  cursor?: InputMaybe<ChallengePeriodWhereUniqueInput>;
  take?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  distinct?: InputMaybe<Array<ChallengePeriodScalarFieldEnum> | ChallengePeriodScalarFieldEnum>;
}>;


export type ChallengePeriodsQuery = { __typename: 'Query', challengePeriods: Array<{ __typename: 'ChallengePeriod', id: string, activeFrom: Date | string, activeUntil: Date | string, challenge: { __typename: 'Challenge', id: string, pointsCredit: number, trainingGoal: number } }> };

export type ChallengesQueryVariables = Exact<{
  where?: InputMaybe<ChallengeWhereInput>;
  orderBy?: InputMaybe<Array<ChallengeOrderByWithRelationInput> | ChallengeOrderByWithRelationInput>;
  cursor?: InputMaybe<ChallengeWhereUniqueInput>;
  take?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  distinct?: InputMaybe<Array<ChallengeScalarFieldEnum> | ChallengeScalarFieldEnum>;
}>;


export type ChallengesQuery = { __typename: 'Query', challenges: Array<{ __typename: 'Challenge', id: string, imageUrl?: string | null, active: boolean, pointsCredit: number, trainingGoal: number, createdAt: Date | string, updatedAt: Date | string, periods?: Array<{ __typename: 'ChallengePeriod', id: string, activeFrom: Date | string, activeUntil: Date | string }> | null, company: { __typename: 'Company', logoUrl?: string | null } }> };

export type CompanyInformationQueryVariables = Exact<{ [key: string]: never; }>;


export type CompanyInformationQuery = { __typename: 'Query', company: { __typename: 'Company', visible: boolean, name: string, email: string, description?: string | null, id: string, logoUrl?: string | null, street: string, houseNumber: string, city: string, state: string, postalCode: string, countryCode: string } };

export type CompanyQueryVariables = Exact<{
  where?: InputMaybe<CompanyUniqueInput>;
}>;


export type CompanyQuery = { __typename: 'Query', company: { __typename: 'Company', id: string, logoUrl?: string | null, visible: boolean } };

export type CurrentUsageAmountQueryVariables = Exact<{ [key: string]: never; }>;


export type CurrentUsageAmountQuery = { __typename: 'Query', company: { __typename: 'Company', id: string, _count: { __typename: 'CompanyCount', branchOffices: number, employees: number, rewards: number, calculationRules: number } } };

export type CouponPeriodsQueryVariables = Exact<{
  where?: InputMaybe<CouponPeriodWhereInput>;
  orderBy?: InputMaybe<Array<CouponPeriodOrderByWithRelationInput> | CouponPeriodOrderByWithRelationInput>;
  cursor?: InputMaybe<CouponPeriodWhereUniqueInput>;
  take?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  distinct?: InputMaybe<Array<CouponPeriodScalarFieldEnum> | CouponPeriodScalarFieldEnum>;
}>;


export type CouponPeriodsQuery = { __typename: 'Query', couponPeriods: Array<{ __typename: 'CouponPeriod', id: string, activeFrom: Date | string, activeUntil: Date | string, createdAt: Date | string, updatedAt: Date | string, coupon: { __typename: 'Coupon', id: string, name: string, description?: string | null, type: CouponType, active: boolean, createdAt: Date | string, updatedAt: Date | string, pointsCredit?: number | null, branchOffices?: Array<{ __typename: 'BranchOffice', id: string, name: string, companyId: string, description?: string | null, latitude: number, longitude: number, street: string, houseNumber: string, city: string, state: string, postalCode: string, countryCode: string, crmApiId: string, updatedAt: Date | string, createdAt: Date | string }> | null, redemptions?: Array<{ __typename: 'RedeemedCoupon', id: string }> | null } }> };

export type CouponsQueryVariables = Exact<{
  where?: InputMaybe<CouponWhereInput>;
  orderBy?: InputMaybe<Array<CouponOrderByWithRelationInput> | CouponOrderByWithRelationInput>;
  cursor?: InputMaybe<CouponWhereUniqueInput>;
  take?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  distinct?: InputMaybe<Array<CouponScalarFieldEnum> | CouponScalarFieldEnum>;
}>;


export type CouponsQuery = { __typename: 'Query', coupons: Array<{ __typename: 'Coupon', id: string, name: string, description?: string | null, type: CouponType, active: boolean, createdAt: Date | string, updatedAt: Date | string, pointsCredit?: number | null, branchOffices?: Array<{ __typename: 'BranchOffice', id: string, name: string, companyId: string, description?: string | null, latitude: number, longitude: number, street: string, houseNumber: string, city: string, state: string, postalCode: string, countryCode: string, crmApiId: string, updatedAt: Date | string, createdAt: Date | string }> | null, redemptions?: Array<{ __typename: 'RedeemedCoupon', id: string }> | null }> };

export type CustomerAgeDistributionQueryVariables = Exact<{
  whereYoungerThan30: CustomerWhereInput;
  whereBetween30And50: CustomerWhereInput;
  whereOlderThan50: CustomerWhereInput;
}>;


export type CustomerAgeDistributionQuery = { __typename: 'Query', youngerThan30: number, between30And50: number, olderThan50: number };

export type CustomerDetailsQueryVariables = Exact<{
  where: CustomerWhereUniqueInput;
}>;


export type CustomerDetailsQuery = { __typename: 'Query', customer: { __typename: 'Customer', firstName?: string | null, lastName?: string | null, email: string, street?: string | null, houseNumber?: string | null, city?: string | null, postalCode?: string | null, birthDate?: Date | string | null, pushToken?: string | null, createdAt: Date | string } };

export type CustomerNewVsReturningQueryVariables = Exact<{
  whereNew: CustomerWhereInput;
  whereReturning: CustomerWhereInput;
}>;


export type CustomerNewVsReturningQuery = { __typename: 'Query', newCustomers: number, returningCustomers: number };

export type CustomerTopRankingQueryVariables = Exact<{
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
}>;


export type CustomerTopRankingQuery = { __typename: 'Query', customerTopRanking: Array<{ __typename: 'CustomerTopRanking', firstName?: string | null, lastName?: string | null, email: string, redeemedPointsTotal: number, receivedPointsTotal: number }> };

export type CustomerTrendQueryVariables = Exact<{
  startDate: Scalars['DateTime']['input'];
  endDate: Scalars['DateTime']['input'];
  timezone: Scalars['TimeZone']['input'];
}>;


export type CustomerTrendQuery = { __typename: 'Query', customerTrend: { __typename: 'TrendMetric', periodCount: number, previousPeriodCount: number, trend: number, dateGroupedCount: Array<{ __typename: 'DateGroupedCount', date: Date | string, count: number }> } };

export type PaginatedCustomersQueryVariables = Exact<{
  where?: InputMaybe<CustomerWhereInput>;
  orderBy?: InputMaybe<Array<CustomerOrderByWithRelationInput> | CustomerOrderByWithRelationInput>;
  cursor?: InputMaybe<CustomerWhereUniqueInput>;
  take?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  distinct?: InputMaybe<Array<CustomerScalarFieldEnum> | CustomerScalarFieldEnum>;
}>;


export type PaginatedCustomersQuery = { __typename: 'Query', paginatedCustomers: { __typename: 'PaginatedCustomer', hasNextPage: boolean, totalCount: number, nodes: Array<{ __typename: 'Customer', id: string, firstName?: string | null, lastName?: string | null, email: string }>, edges?: Array<{ __typename: 'CustomerEdge', cursor: string }> | null } };

export type DashboardOverviewQueryVariables = Exact<{
  startDate: Scalars['DateTime']['input'];
  endDate: Scalars['DateTime']['input'];
  countCustomerWhere?: InputMaybe<CustomerWhereInput>;
}>;


export type DashboardOverviewQuery = { __typename: 'Query', countCustomer: number, countRedeemedPoint: number, aggregateReceivedPoint: { __typename: 'AggregateReceivedPoint', _sum?: { __typename: 'ReceivedPointSumAggregate', count?: number | null } | null }, redeemedCouponByTypeCount: Array<{ __typename: 'RedeemedCouponByTypeCount', type: CouponType, count: number }>, receivedVisitBasedPointsPerDay: Array<{ __typename: 'DateGroupedCount', date: Date | string, count: number }> };

export type EmployeesQueryVariables = Exact<{
  where?: InputMaybe<EmployeeWhereInput>;
  orderBy?: InputMaybe<Array<EmployeeOrderByWithRelationInput> | EmployeeOrderByWithRelationInput>;
  cursor?: InputMaybe<EmployeeWhereUniqueInput>;
  take?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  distinct?: InputMaybe<Array<EmployeeScalarFieldEnum> | EmployeeScalarFieldEnum>;
}>;


export type EmployeesQuery = { __typename: 'Query', employees: Array<{ __typename: 'Employee', id?: string | null, firstName?: string | null, lastName?: string | null, email: string, userType: CompanyUserType, status: EmployeeStatus, updatedAt: Date | string, createdAt: Date | string }> };

export type PointSystemCheckQueryVariables = Exact<{ [key: string]: never; }>;


export type PointSystemCheckQuery = { __typename: 'Query', pointSystem?: { __typename: 'PointSystem', pointSystemType: { __typename: 'PointSystemType', type: SystemType } } | null };

export type PointSystemQueryVariables = Exact<{ [key: string]: never; }>;


export type PointSystemQuery = { __typename: 'Query', pointSystem?: { __typename: 'PointSystem', id: string, calculationRuleEntries: number, scanLimit: number, company: { __typename: 'Company', calculationRules?: Array<{ __typename: 'CalculationRule', id: string, points: number, value: number }> | null }, pointSystemType: { __typename: 'PointSystemType', id: string, type: SystemType } } | null };

export type PromotionPeriodsQueryVariables = Exact<{
  where?: InputMaybe<PromotionPeriodWhereInput>;
  orderBy?: InputMaybe<Array<PromotionPeriodOrderByWithRelationInput> | PromotionPeriodOrderByWithRelationInput>;
  cursor?: InputMaybe<PromotionPeriodWhereUniqueInput>;
  take?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  distinct?: InputMaybe<Array<PromotionPeriodScalarFieldEnum> | PromotionPeriodScalarFieldEnum>;
}>;


export type PromotionPeriodsQuery = { __typename: 'Query', promotionPeriods: Array<{ __typename: 'PromotionPeriod', id: string, updatedAt: Date | string, createdAt: Date | string, activeFrom: Date | string, activeUntil: Date | string, promotion: { __typename: 'Promotion', id: string, title: string, description?: string | null, promotionCode?: string | null, createdAt: Date | string, updatedAt: Date | string } }> };

export type PromotionsQueryVariables = Exact<{
  where?: InputMaybe<PromotionWhereInput>;
  orderBy?: InputMaybe<Array<PromotionOrderByWithRelationInput> | PromotionOrderByWithRelationInput>;
  cursor?: InputMaybe<PromotionWhereUniqueInput>;
  take?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  distinct?: InputMaybe<Array<PromotionScalarFieldEnum> | PromotionScalarFieldEnum>;
}>;


export type PromotionsQuery = { __typename: 'Query', promotions: Array<{ __typename: 'Promotion', id: string, title: string, description?: string | null, promotionCode?: string | null, createdAt: Date | string, updatedAt: Date | string }> };

export type PushNotificationHistoriesQueryVariables = Exact<{
  where?: InputMaybe<PushNotificationHistoryWhereInput>;
  orderBy?: InputMaybe<Array<PushNotificationHistoryOrderByWithRelationInput> | PushNotificationHistoryOrderByWithRelationInput>;
  cursor?: InputMaybe<PushNotificationHistoryWhereUniqueInput>;
  take?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  distinct?: InputMaybe<Array<PushNotificationHistoryScalarFieldEnum> | PushNotificationHistoryScalarFieldEnum>;
}>;


export type PushNotificationHistoriesQuery = { __typename: 'Query', pushNotificationHistories: Array<{ __typename: 'PushNotificationHistory', id: string, affectedCustomers: number, sendAt: Date | string, pushNotification: { __typename: 'PushNotification', title: string, message: string, type: PushNotificationSendType } }> };

export type PushNotificationsQueryVariables = Exact<{
  where?: InputMaybe<PushNotificationWhereInput>;
  orderBy?: InputMaybe<Array<PushNotificationOrderByWithRelationInput> | PushNotificationOrderByWithRelationInput>;
  cursor?: InputMaybe<PushNotificationWhereUniqueInput>;
  take?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  distinct?: InputMaybe<Array<PushNotificationScalarFieldEnum> | PushNotificationScalarFieldEnum>;
}>;


export type PushNotificationsQuery = { __typename: 'Query', pushNotifications: Array<{ __typename: 'PushNotification', id: string, title: string, message: string, type: PushNotificationSendType, status: PushNotificationStatus, cronExpression?: string | null, cronStartDate?: Date | string | null, cronInterval?: CronInterval | null, sendAt?: Date | string | null, createdAt: Date | string }> };

export type ReceivedPointTrendQueryVariables = Exact<{
  startDate: Scalars['DateTime']['input'];
  endDate: Scalars['DateTime']['input'];
  timezone: Scalars['TimeZone']['input'];
}>;


export type ReceivedPointTrendQuery = { __typename: 'Query', receivedPointTrend: { __typename: 'TrendMetric', periodCount: number, previousPeriodCount: number, trend: number, dateGroupedCount: Array<{ __typename: 'DateGroupedCount', date: Date | string, count: number }> } };

export type ReceivedPointsQueryVariables = Exact<{
  where?: InputMaybe<ReceivedPointWhereInput>;
  orderBy?: InputMaybe<Array<ReceivedPointOrderByWithRelationInput> | ReceivedPointOrderByWithRelationInput>;
  cursor?: InputMaybe<ReceivedPointWhereUniqueInput>;
  take?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  distinct?: InputMaybe<Array<ReceivedPointScalarFieldEnum> | ReceivedPointScalarFieldEnum>;
}>;


export type ReceivedPointsQuery = { __typename: 'Query', receivedPoints: Array<{ __typename: 'ReceivedPoint', id: string, count?: number | null, createdAt: Date | string, updatedAt: Date | string, sourceType: ReceivedPointSourceType }> };

export type RedeemedPointTrendQueryVariables = Exact<{
  startDate: Scalars['DateTime']['input'];
  endDate: Scalars['DateTime']['input'];
  timezone: Scalars['TimeZone']['input'];
}>;


export type RedeemedPointTrendQuery = { __typename: 'Query', redeemedPointTrend: { __typename: 'TrendMetric', periodCount: number, previousPeriodCount: number, trend: number, dateGroupedCount: Array<{ __typename: 'DateGroupedCount', date: Date | string, count: number }> } };

export type RedeemedPointsQueryVariables = Exact<{
  where?: InputMaybe<RedeemedPointWhereInput>;
  orderBy?: InputMaybe<Array<RedeemedPointOrderByWithRelationInput> | RedeemedPointOrderByWithRelationInput>;
  cursor?: InputMaybe<RedeemedPointWhereUniqueInput>;
  take?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  distinct?: InputMaybe<Array<RedeemedPointScalarFieldEnum> | RedeemedPointScalarFieldEnum>;
}>;


export type RedeemedPointsQuery = { __typename: 'Query', redeemedPoints: Array<{ __typename: 'RedeemedPoint', id: string, createdAt: Date | string, updatedAt: Date | string, reward: { __typename: 'Reward', id: string, name: string, description?: string | null, requiredPoints: number, saving: number, imageKey?: string | null, transmissionMethod: TransmissionMethod, updatedAt: Date | string, createdAt: Date | string } }> };

export type RewardsChartQueryVariables = Exact<{
  where?: InputMaybe<RewardWhereInput>;
}>;


export type RewardsChartQuery = { __typename: 'Query', rewards: Array<{ __typename: 'Reward', id: string, name: string, description?: string | null, requiredPoints: number, saving: number, imageKey?: string | null, transmissionMethod: TransmissionMethod, updatedAt: Date | string, createdAt: Date | string, _count: { __typename: 'RewardCount', redeemedPoints: number } }> };

export type RewardsQueryVariables = Exact<{
  where?: InputMaybe<RewardWhereInput>;
  orderBy?: InputMaybe<Array<RewardOrderByWithRelationInput> | RewardOrderByWithRelationInput>;
  cursor?: InputMaybe<RewardWhereUniqueInput>;
  take?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  distinct?: InputMaybe<Array<RewardScalarFieldEnum> | RewardScalarFieldEnum>;
}>;


export type RewardsQuery = { __typename: 'Query', rewards: Array<{ __typename: 'Reward', id: string, name: string, description?: string | null, requiredPoints: number, saving: number, imageKey?: string | null, transmissionMethod: TransmissionMethod, updatedAt: Date | string, createdAt: Date | string }> };

export type ValidateBaseConfigurationQueryVariables = Exact<{ [key: string]: never; }>;


export type ValidateBaseConfigurationQuery = { __typename: 'Query', calculationRules: Array<{ __typename: 'CalculationRule', id: string, points: number, value: number }>, pointSystem?: { __typename: 'PointSystem', pointSystemType: { __typename: 'PointSystemType', type: SystemType } } | null, branchOffices: Array<{ __typename: 'BranchOffice', id: string }> };

export const BalanceFieldsFragmentDoc = gql`
    fragment BalanceFields on Balance {
  updatedAt
  createdAt
  count
}
    `;
export const BranchOfficeCountFieldsFragmentDoc = gql`
    fragment BranchOfficeCountFields on BranchOffice {
  id
}
    `;
export const ChallengePeriodFieldsFragmentDoc = gql`
    fragment ChallengePeriodFields on ChallengePeriod {
  id
  activeFrom
  activeUntil
  challenge {
    id
    pointsCredit
    trainingGoal
  }
}
    `;
export const ChallengePeriodWithoutChallengeFieldsFragmentDoc = gql`
    fragment ChallengePeriodWithoutChallengeFields on ChallengePeriod {
  id
  activeFrom
  activeUntil
}
    `;
export const ChallengeFieldsFragmentDoc = gql`
    fragment ChallengeFields on Challenge {
  id
  imageUrl
  active
  pointsCredit
  trainingGoal
  createdAt
  updatedAt
  periods {
    ...ChallengePeriodWithoutChallengeFields
  }
  company {
    logoUrl
  }
}
    ${ChallengePeriodWithoutChallengeFieldsFragmentDoc}`;
export const CompanyFieldsFragmentDoc = gql`
    fragment CompanyFields on Company {
  id
  logoUrl
  visible
}
    `;
export const CompanyAddressFieldsFragmentDoc = gql`
    fragment CompanyAddressFields on Company {
  street
  houseNumber
  city
  state
  postalCode
  countryCode
}
    `;
export const CompanyInformationFieldsFragmentDoc = gql`
    fragment CompanyInformationFields on Company {
  ...CompanyFields
  ...CompanyAddressFields
  visible
  name
  email
  description
}
    ${CompanyFieldsFragmentDoc}
${CompanyAddressFieldsFragmentDoc}`;
export const ContactFieldsFragmentDoc = gql`
    fragment ContactFields on Contact {
  id
  requestId
}
    `;
export const BranchOfficeFieldsFragmentDoc = gql`
    fragment BranchOfficeFields on BranchOffice {
  id
  name
  companyId
  description
  latitude
  longitude
  street
  houseNumber
  city
  state
  postalCode
  countryCode
  crmApiId
  updatedAt
  createdAt
}
    `;
export const CouponFieldsFragmentDoc = gql`
    fragment CouponFields on Coupon {
  id
  name
  description
  type
  active
  createdAt
  updatedAt
  pointsCredit
  branchOffices {
    ...BranchOfficeFields
  }
  redemptions {
    id
  }
}
    ${BranchOfficeFieldsFragmentDoc}`;
export const CouponPeriodFieldsFragmentDoc = gql`
    fragment CouponPeriodFields on CouponPeriod {
  id
  activeFrom
  activeUntil
  createdAt
  updatedAt
  coupon {
    ...CouponFields
  }
}
    ${CouponFieldsFragmentDoc}`;
export const CustomerDetailsFieldsFragmentDoc = gql`
    fragment CustomerDetailsFields on Customer {
  firstName
  lastName
  email
  street
  houseNumber
  city
  postalCode
  birthDate
  pushToken
  createdAt
}
    `;
export const CustomerTopRankingFieldsFragmentDoc = gql`
    fragment CustomerTopRankingFields on CustomerTopRanking {
  firstName
  lastName
  email
  redeemedPointsTotal
  receivedPointsTotal
}
    `;
export const EmployeeFieldsFragmentDoc = gql`
    fragment EmployeeFields on Employee {
  id
  firstName
  lastName
  email
  userType
  status
  updatedAt
  createdAt
}
    `;
export const CustomerFieldsFragmentDoc = gql`
    fragment CustomerFields on Customer {
  id
  firstName
  lastName
  email
}
    `;
export const PaginatedCustomerFieldsFragmentDoc = gql`
    fragment PaginatedCustomerFields on PaginatedCustomer {
  nodes {
    ...CustomerFields
  }
  hasNextPage
  totalCount
  edges {
    cursor
  }
}
    ${CustomerFieldsFragmentDoc}`;
export const PointSystemCheckFieldsFragmentDoc = gql`
    fragment PointSystemCheckFields on PointSystem {
  pointSystemType {
    type
  }
}
    `;
export const CalculationRuleFieldsFragmentDoc = gql`
    fragment CalculationRuleFields on CalculationRule {
  id
  points
  value
}
    `;
export const PointSystemTypeFieldsFragmentDoc = gql`
    fragment PointSystemTypeFields on PointSystemType {
  id
  type
}
    `;
export const PointSystemFieldsFragmentDoc = gql`
    fragment PointSystemFields on PointSystem {
  id
  company {
    calculationRules {
      ...CalculationRuleFields
    }
  }
  calculationRuleEntries
  pointSystemType {
    ...PointSystemTypeFields
  }
  scanLimit
}
    ${CalculationRuleFieldsFragmentDoc}
${PointSystemTypeFieldsFragmentDoc}`;
export const PromotionFieldsFragmentDoc = gql`
    fragment PromotionFields on Promotion {
  id
  title
  description
  promotionCode
  createdAt
  updatedAt
}
    `;
export const PromotionPeriodFieldsFragmentDoc = gql`
    fragment PromotionPeriodFields on PromotionPeriod {
  id
  updatedAt
  createdAt
  activeFrom
  activeUntil
  promotion {
    ...PromotionFields
  }
}
    ${PromotionFieldsFragmentDoc}`;
export const PushNotificationHistoryFieldsFragmentDoc = gql`
    fragment PushNotificationHistoryFields on PushNotificationHistory {
  id
  affectedCustomers
  pushNotification {
    title
    message
    type
  }
  sendAt
}
    `;
export const PushNotificationFieldsFragmentDoc = gql`
    fragment PushNotificationFields on PushNotification {
  id
  title
  message
  type
  status
  cronExpression
  cronStartDate
  cronInterval
  sendAt
  createdAt
}
    `;
export const ReceivedPointFieldsFragmentDoc = gql`
    fragment ReceivedPointFields on ReceivedPoint {
  id
  count
  createdAt
  updatedAt
  sourceType
}
    `;
export const RewardFieldsFragmentDoc = gql`
    fragment RewardFields on Reward {
  id
  name
  description
  requiredPoints
  saving
  imageKey
  transmissionMethod
  updatedAt
  createdAt
}
    `;
export const RedeemedPointFieldsFragmentDoc = gql`
    fragment RedeemedPointFields on RedeemedPoint {
  id
  createdAt
  updatedAt
  reward {
    ...RewardFields
  }
}
    ${RewardFieldsFragmentDoc}`;
export const RewardsChartFieldsFragmentDoc = gql`
    fragment RewardsChartFields on Reward {
  ...RewardFields
  _count {
    redeemedPoints
  }
}
    ${RewardFieldsFragmentDoc}`;
export const TrendMetricFieldsFragmentDoc = gql`
    fragment TrendMetricFields on TrendMetric {
  periodCount
  previousPeriodCount
  dateGroupedCount {
    date
    count
  }
  trend
}
    `;
export const CreateBranchOfficeDocument = gql`
    mutation CreateBranchOffice($data: BranchOfficeCreateInput!) {
  createBranchOffice(data: $data) {
    ...BranchOfficeFields
  }
}
    ${BranchOfficeFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateBranchOfficeGQL extends Apollo.Mutation<CreateBranchOfficeMutation, CreateBranchOfficeMutationVariables> {
    override document = CreateBranchOfficeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteBranchOfficeDocument = gql`
    mutation DeleteBranchOffice($where: BranchOfficeUniqueInput!) {
  deleteBranchOffice(where: $where) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteBranchOfficeGQL extends Apollo.Mutation<DeleteBranchOfficeMutation, DeleteBranchOfficeMutationVariables> {
    override document = DeleteBranchOfficeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateBranchOfficeDocument = gql`
    mutation UpdateBranchOffice($data: BranchOfficeUpdateInput!, $where: BranchOfficeWhereUniqueInput!) {
  updateBranchOffice(where: $where, data: $data) {
    ...BranchOfficeFields
  }
}
    ${BranchOfficeFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateBranchOfficeGQL extends Apollo.Mutation<UpdateBranchOfficeMutation, UpdateBranchOfficeMutationVariables> {
    override document = UpdateBranchOfficeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateOrChangeCalculationRuleDocument = gql`
    mutation CreateOrChangeCalculationRule($data: CreateOrChangeCalculationRuleInput!) {
  createOrChangeCalculationRule(data: $data) {
    ...CalculationRuleFields
  }
}
    ${CalculationRuleFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateOrChangeCalculationRuleGQL extends Apollo.Mutation<CreateOrChangeCalculationRuleMutation, CreateOrChangeCalculationRuleMutationVariables> {
    override document = CreateOrChangeCalculationRuleDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateChallengePeriodDocument = gql`
    mutation CreateChallengePeriod($data: ChallengePeriodCreateInput!) {
  createChallengePeriod(data: $data) {
    ...ChallengePeriodFields
  }
}
    ${ChallengePeriodFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateChallengePeriodGQL extends Apollo.Mutation<CreateChallengePeriodMutation, CreateChallengePeriodMutationVariables> {
    override document = CreateChallengePeriodDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteChallengePeriodDocument = gql`
    mutation DeleteChallengePeriod($where: ChallengePeriodWhereUniqueInput!) {
  deleteChallengePeriod(where: $where) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteChallengePeriodGQL extends Apollo.Mutation<DeleteChallengePeriodMutation, DeleteChallengePeriodMutationVariables> {
    override document = DeleteChallengePeriodDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateChallengePeriodDocument = gql`
    mutation UpdateChallengePeriod($where: ChallengePeriodWhereUniqueInput!, $data: ChallengePeriodUpdateInput!) {
  updateChallengePeriod(where: $where, data: $data) {
    ...ChallengePeriodFields
  }
}
    ${ChallengePeriodFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateChallengePeriodGQL extends Apollo.Mutation<UpdateChallengePeriodMutation, UpdateChallengePeriodMutationVariables> {
    override document = UpdateChallengePeriodDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateChallengeDocument = gql`
    mutation CreateChallenge($data: ChallengeCreateInput!) {
  createChallenge(data: $data) {
    ...ChallengeFields
  }
}
    ${ChallengeFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateChallengeGQL extends Apollo.Mutation<CreateChallengeMutation, CreateChallengeMutationVariables> {
    override document = CreateChallengeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteChallengeDocument = gql`
    mutation DeleteChallenge($where: ChallengeWhereUniqueInput!) {
  deleteChallenge(where: $where) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteChallengeGQL extends Apollo.Mutation<DeleteChallengeMutation, DeleteChallengeMutationVariables> {
    override document = DeleteChallengeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateChallengeImageDocument = gql`
    mutation UpdateChallengeImage($where: ChallengeWhereUniqueInput!, $data: ChallengeImageInput!) {
  updateChallengeImage(where: $where, data: $data) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateChallengeImageGQL extends Apollo.Mutation<UpdateChallengeImageMutation, UpdateChallengeImageMutationVariables> {
    override document = UpdateChallengeImageDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ChangeVisibilityDocument = gql`
    mutation ChangeVisibility($visible: Boolean!) {
  changeVisibility(visible: $visible) {
    ...CompanyFields
  }
}
    ${CompanyFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ChangeVisibilityGQL extends Apollo.Mutation<ChangeVisibilityMutation, ChangeVisibilityMutationVariables> {
    override document = ChangeVisibilityDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateCompanyDocument = gql`
    mutation UpdateCompany($data: UpdateCompanyInput!, $where: CompanyUniqueInput) {
  updateCompany(data: $data, where: $where) {
    ...CompanyInformationFields
  }
}
    ${CompanyInformationFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateCompanyGQL extends Apollo.Mutation<UpdateCompanyMutation, UpdateCompanyMutationVariables> {
    override document = UpdateCompanyDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ContactSupportDocument = gql`
    mutation ContactSupport($data: ContactSupportInput!) {
  contactSupport(data: $data) {
    ...ContactFields
  }
}
    ${ContactFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ContactSupportGQL extends Apollo.Mutation<ContactSupportMutation, ContactSupportMutationVariables> {
    override document = ContactSupportDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateCouponPeriodDocument = gql`
    mutation CreateCouponPeriod($data: CouponPeriodCreateInput!) {
  createCouponPeriod(data: $data) {
    ...CouponPeriodFields
  }
}
    ${CouponPeriodFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateCouponPeriodGQL extends Apollo.Mutation<CreateCouponPeriodMutation, CreateCouponPeriodMutationVariables> {
    override document = CreateCouponPeriodDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteCouponPeriodDocument = gql`
    mutation DeleteCouponPeriod($where: CouponPeriodWhereUniqueInput!) {
  deleteCouponPeriod(where: $where) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteCouponPeriodGQL extends Apollo.Mutation<DeleteCouponPeriodMutation, DeleteCouponPeriodMutationVariables> {
    override document = DeleteCouponPeriodDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateCouponPeriodDocument = gql`
    mutation UpdateCouponPeriod($where: CouponPeriodWhereUniqueInput!, $data: CouponPeriodUpdateInput!) {
  updateCouponPeriod(where: $where, data: $data) {
    ...CouponPeriodFields
  }
}
    ${CouponPeriodFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateCouponPeriodGQL extends Apollo.Mutation<UpdateCouponPeriodMutation, UpdateCouponPeriodMutationVariables> {
    override document = UpdateCouponPeriodDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateCouponDocument = gql`
    mutation CreateCoupon($data: CouponCreateInput!) {
  createCoupon(data: $data) {
    ...CouponFields
  }
}
    ${CouponFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateCouponGQL extends Apollo.Mutation<CreateCouponMutation, CreateCouponMutationVariables> {
    override document = CreateCouponDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateWelcomeCouponDocument = gql`
    mutation CreateWelcomeCoupon($data: WelcomeCouponCreateInput!) {
  createWelcomeCoupon(data: $data) {
    ...CouponFields
  }
}
    ${CouponFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateWelcomeCouponGQL extends Apollo.Mutation<CreateWelcomeCouponMutation, CreateWelcomeCouponMutationVariables> {
    override document = CreateWelcomeCouponDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteCouponDocument = gql`
    mutation DeleteCoupon($where: CouponWhereUniqueInput!) {
  deleteCoupon(where: $where) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteCouponGQL extends Apollo.Mutation<DeleteCouponMutation, DeleteCouponMutationVariables> {
    override document = DeleteCouponDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SwitchCouponStatusDocument = gql`
    mutation SwitchCouponStatus($where: CouponUniqueInput!, $data: SwitchCouponStatusInput!) {
  switchCouponStatus(where: $where, data: $data) {
    ...CouponFields
  }
}
    ${CouponFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SwitchCouponStatusGQL extends Apollo.Mutation<SwitchCouponStatusMutation, SwitchCouponStatusMutationVariables> {
    override document = SwitchCouponStatusDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteEmployeeDocument = gql`
    mutation DeleteEmployee($where: EmployeeIdentifierInput!) {
  deleteEmployee(where: $where) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteEmployeeGQL extends Apollo.Mutation<DeleteEmployeeMutation, DeleteEmployeeMutationVariables> {
    override document = DeleteEmployeeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const InviteEmployeeDocument = gql`
    mutation InviteEmployee($email: String!) {
  inviteEmployee(email: $email) {
    ...EmployeeFields
  }
}
    ${EmployeeFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class InviteEmployeeGQL extends Apollo.Mutation<InviteEmployeeMutation, InviteEmployeeMutationVariables> {
    override document = InviteEmployeeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SendPasswordChangeEmailDocument = gql`
    mutation SendPasswordChangeEmail {
  sendPasswordChangeEmail {
    ...EmployeeFields
  }
}
    ${EmployeeFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SendPasswordChangeEmailGQL extends Apollo.Mutation<SendPasswordChangeEmailMutation, SendPasswordChangeEmailMutationVariables> {
    override document = SendPasswordChangeEmailDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateEmployeeDocument = gql`
    mutation UpdateEmployee($data: UpdateEmployeeInput!, $where: EmployeeUniqueInput) {
  updateEmployee(data: $data, where: $where) {
    ...EmployeeFields
  }
}
    ${EmployeeFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateEmployeeGQL extends Apollo.Mutation<UpdateEmployeeMutation, UpdateEmployeeMutationVariables> {
    override document = UpdateEmployeeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ChangePointSystemDocument = gql`
    mutation ChangePointSystem($input: ChangePointSystemInput!) {
  changePointSystem(input: $input) {
    ...PointSystemFields
  }
}
    ${PointSystemFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ChangePointSystemGQL extends Apollo.Mutation<ChangePointSystemMutation, ChangePointSystemMutationVariables> {
    override document = ChangePointSystemDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreatePointSystemDocument = gql`
    mutation CreatePointSystem($createInput: PointSystemCreateInput!) {
  createPointSystem(createInput: $createInput) {
    ...PointSystemFields
  }
}
    ${PointSystemFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreatePointSystemGQL extends Apollo.Mutation<CreatePointSystemMutation, CreatePointSystemMutationVariables> {
    override document = CreatePointSystemDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreatePromotionPeriodDocument = gql`
    mutation CreatePromotionPeriod($data: PromotionPeriodCreateInput!) {
  createPromotionPeriod(data: $data) {
    ...PromotionPeriodFields
  }
}
    ${PromotionPeriodFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreatePromotionPeriodGQL extends Apollo.Mutation<CreatePromotionPeriodMutation, CreatePromotionPeriodMutationVariables> {
    override document = CreatePromotionPeriodDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeletePromotionPeriodDocument = gql`
    mutation DeletePromotionPeriod($where: PromotionPeriodWhereUniqueInput!) {
  deletePromotionPeriod(where: $where) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeletePromotionPeriodGQL extends Apollo.Mutation<DeletePromotionPeriodMutation, DeletePromotionPeriodMutationVariables> {
    override document = DeletePromotionPeriodDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdatePromotionPeriodDocument = gql`
    mutation UpdatePromotionPeriod($where: PromotionPeriodUniqueInput!, $data: PromotionPeriodUpdateInput!) {
  updatePromotionPeriod(where: $where, data: $data) {
    ...PromotionPeriodFields
  }
}
    ${PromotionPeriodFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdatePromotionPeriodGQL extends Apollo.Mutation<UpdatePromotionPeriodMutation, UpdatePromotionPeriodMutationVariables> {
    override document = UpdatePromotionPeriodDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreatePromotionDocument = gql`
    mutation CreatePromotion($data: PromotionCreateInput!) {
  createPromotion(data: $data) {
    ...PromotionFields
  }
}
    ${PromotionFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreatePromotionGQL extends Apollo.Mutation<CreatePromotionMutation, CreatePromotionMutationVariables> {
    override document = CreatePromotionDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeletePromotionDocument = gql`
    mutation DeletePromotion($where: PromotionWhereUniqueInput!) {
  deletePromotion(where: $where) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeletePromotionGQL extends Apollo.Mutation<DeletePromotionMutation, DeletePromotionMutationVariables> {
    override document = DeletePromotionDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreatePushNotificationDocument = gql`
    mutation CreatePushNotification($createInput: PushNotificationCreateInput!) {
  createPushNotification(data: $createInput) {
    ...PushNotificationFields
  }
}
    ${PushNotificationFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreatePushNotificationGQL extends Apollo.Mutation<CreatePushNotificationMutation, CreatePushNotificationMutationVariables> {
    override document = CreatePushNotificationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeletePushNotificationDocument = gql`
    mutation DeletePushNotification($where: PushNotificationWhereUniqueInput!) {
  deletePushNotification(where: $where) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeletePushNotificationGQL extends Apollo.Mutation<DeletePushNotificationMutation, DeletePushNotificationMutationVariables> {
    override document = DeletePushNotificationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DisablePushNotificationDocument = gql`
    mutation DisablePushNotification($pushNotificationId: String!) {
  disablePushNotification(pushNotificationId: $pushNotificationId) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DisablePushNotificationGQL extends Apollo.Mutation<DisablePushNotificationMutation, DisablePushNotificationMutationVariables> {
    override document = DisablePushNotificationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateManuallyReceivedPointDocument = gql`
    mutation CreateManuallyReceivedPoint($customer: CustomerWhereUniqueInput!, $count: Float!) {
  createManuallyReceivedPoint(count: $count, customer: $customer) {
    ...ReceivedPointFields
  }
}
    ${ReceivedPointFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateManuallyReceivedPointGQL extends Apollo.Mutation<CreateManuallyReceivedPointMutation, CreateManuallyReceivedPointMutationVariables> {
    override document = CreateManuallyReceivedPointDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateRewardDocument = gql`
    mutation CreateReward($data: CreateRewardInput!) {
  createReward(data: $data) {
    ...RewardFields
  }
}
    ${RewardFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateRewardGQL extends Apollo.Mutation<CreateRewardMutation, CreateRewardMutationVariables> {
    override document = CreateRewardDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SwitchRewardStatusDocument = gql`
    mutation SwitchRewardStatus($input: SwitchRewardStatusInput!) {
  switchRewardStatus(input: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SwitchRewardStatusGQL extends Apollo.Mutation<SwitchRewardStatusMutation, SwitchRewardStatusMutationVariables> {
    override document = SwitchRewardStatusDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateRewardDocument = gql`
    mutation UpdateReward($data: RewardUpdateInput!, $where: RewardWhereUniqueInput!) {
  updateReward(data: $data, where: $where) {
    ...RewardFields
  }
}
    ${RewardFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateRewardGQL extends Apollo.Mutation<UpdateRewardMutation, UpdateRewardMutationVariables> {
    override document = UpdateRewardDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const BranchOfficesDocument = gql`
    query BranchOffices($where: BranchOfficeWhereInput, $oderBy: [BranchOfficeOrderByWithRelationInput!], $cursor: BranchOfficeWhereUniqueInput, $take: Int, $skip: Int, $distinct: [BranchOfficeScalarFieldEnum!]) {
  branchOffices(
    where: $where
    orderBy: $oderBy
    cursor: $cursor
    take: $take
    skip: $skip
    distinct: $distinct
  ) {
    ...BranchOfficeFields
  }
}
    ${BranchOfficeFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class BranchOfficesGQL extends Apollo.Query<BranchOfficesQuery, BranchOfficesQueryVariables> {
    override document = BranchOfficesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ChallengePeriodsDocument = gql`
    query ChallengePeriods($where: ChallengePeriodWhereInput, $orderBy: [ChallengePeriodOrderByWithRelationInput!], $cursor: ChallengePeriodWhereUniqueInput, $take: Int, $skip: Int, $distinct: [ChallengePeriodScalarFieldEnum!]) {
  challengePeriods(
    where: $where
    cursor: $cursor
    distinct: $distinct
    orderBy: $orderBy
    skip: $skip
    take: $take
  ) {
    ...ChallengePeriodFields
  }
}
    ${ChallengePeriodFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ChallengePeriodsGQL extends Apollo.Query<ChallengePeriodsQuery, ChallengePeriodsQueryVariables> {
    override document = ChallengePeriodsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ChallengesDocument = gql`
    query Challenges($where: ChallengeWhereInput, $orderBy: [ChallengeOrderByWithRelationInput!], $cursor: ChallengeWhereUniqueInput, $take: Int, $skip: Int, $distinct: [ChallengeScalarFieldEnum!]) {
  challenges(
    where: $where
    cursor: $cursor
    distinct: $distinct
    orderBy: $orderBy
    skip: $skip
    take: $take
  ) {
    ...ChallengeFields
  }
}
    ${ChallengeFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ChallengesGQL extends Apollo.Query<ChallengesQuery, ChallengesQueryVariables> {
    override document = ChallengesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CompanyInformationDocument = gql`
    query CompanyInformation {
  company {
    ...CompanyInformationFields
  }
}
    ${CompanyInformationFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CompanyInformationGQL extends Apollo.Query<CompanyInformationQuery, CompanyInformationQueryVariables> {
    override document = CompanyInformationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CompanyDocument = gql`
    query Company($where: CompanyUniqueInput) {
  company(where: $where) {
    ...CompanyFields
  }
}
    ${CompanyFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CompanyGQL extends Apollo.Query<CompanyQuery, CompanyQueryVariables> {
    override document = CompanyDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CurrentUsageAmountDocument = gql`
    query CurrentUsageAmount {
  company {
    id
    _count(onlyActiveRewards: true) {
      branchOffices
      employees
      rewards
      calculationRules
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CurrentUsageAmountGQL extends Apollo.Query<CurrentUsageAmountQuery, CurrentUsageAmountQueryVariables> {
    override document = CurrentUsageAmountDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CouponPeriodsDocument = gql`
    query CouponPeriods($where: CouponPeriodWhereInput, $orderBy: [CouponPeriodOrderByWithRelationInput!], $cursor: CouponPeriodWhereUniqueInput, $take: Int, $skip: Int, $distinct: [CouponPeriodScalarFieldEnum!]) {
  couponPeriods(
    where: $where
    orderBy: $orderBy
    cursor: $cursor
    take: $take
    skip: $skip
    distinct: $distinct
  ) {
    ...CouponPeriodFields
  }
}
    ${CouponPeriodFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CouponPeriodsGQL extends Apollo.Query<CouponPeriodsQuery, CouponPeriodsQueryVariables> {
    override document = CouponPeriodsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CouponsDocument = gql`
    query Coupons($where: CouponWhereInput, $orderBy: [CouponOrderByWithRelationInput!], $cursor: CouponWhereUniqueInput, $take: Int, $skip: Int, $distinct: [CouponScalarFieldEnum!]) {
  coupons(
    where: $where
    orderBy: $orderBy
    cursor: $cursor
    take: $take
    skip: $skip
    distinct: $distinct
  ) {
    ...CouponFields
  }
}
    ${CouponFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CouponsGQL extends Apollo.Query<CouponsQuery, CouponsQueryVariables> {
    override document = CouponsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CustomerAgeDistributionDocument = gql`
    query CustomerAgeDistribution($whereYoungerThan30: CustomerWhereInput!, $whereBetween30And50: CustomerWhereInput!, $whereOlderThan50: CustomerWhereInput!) {
  youngerThan30: countCustomer(where: $whereYoungerThan30)
  between30And50: countCustomer(where: $whereBetween30And50)
  olderThan50: countCustomer(where: $whereOlderThan50)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CustomerAgeDistributionGQL extends Apollo.Query<CustomerAgeDistributionQuery, CustomerAgeDistributionQueryVariables> {
    override document = CustomerAgeDistributionDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CustomerDetailsDocument = gql`
    query CustomerDetails($where: CustomerWhereUniqueInput!) {
  customer(where: $where) {
    ...CustomerDetailsFields
  }
}
    ${CustomerDetailsFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CustomerDetailsGQL extends Apollo.Query<CustomerDetailsQuery, CustomerDetailsQueryVariables> {
    override document = CustomerDetailsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CustomerNewVsReturningDocument = gql`
    query CustomerNewVsReturning($whereNew: CustomerWhereInput!, $whereReturning: CustomerWhereInput!) {
  newCustomers: countCustomer(where: $whereNew)
  returningCustomers: countCustomer(where: $whereReturning)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CustomerNewVsReturningGQL extends Apollo.Query<CustomerNewVsReturningQuery, CustomerNewVsReturningQueryVariables> {
    override document = CustomerNewVsReturningDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CustomerTopRankingDocument = gql`
    query CustomerTopRanking($startDate: DateTime, $endDate: DateTime) {
  customerTopRanking(startDate: $startDate, endDate: $endDate) {
    ...CustomerTopRankingFields
  }
}
    ${CustomerTopRankingFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CustomerTopRankingGQL extends Apollo.Query<CustomerTopRankingQuery, CustomerTopRankingQueryVariables> {
    override document = CustomerTopRankingDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CustomerTrendDocument = gql`
    query CustomerTrend($startDate: DateTime!, $endDate: DateTime!, $timezone: TimeZone!) {
  customerTrend(startDate: $startDate, endDate: $endDate, timezone: $timezone) {
    ...TrendMetricFields
  }
}
    ${TrendMetricFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CustomerTrendGQL extends Apollo.Query<CustomerTrendQuery, CustomerTrendQueryVariables> {
    override document = CustomerTrendDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PaginatedCustomersDocument = gql`
    query PaginatedCustomers($where: CustomerWhereInput, $orderBy: [CustomerOrderByWithRelationInput!], $cursor: CustomerWhereUniqueInput, $take: Int, $skip: Int, $distinct: [CustomerScalarFieldEnum!]) {
  paginatedCustomers(
    where: $where
    orderBy: $orderBy
    cursor: $cursor
    take: $take
    skip: $skip
    distinct: $distinct
  ) {
    ...PaginatedCustomerFields
  }
}
    ${PaginatedCustomerFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class PaginatedCustomersGQL extends Apollo.Query<PaginatedCustomersQuery, PaginatedCustomersQueryVariables> {
    override document = PaginatedCustomersDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DashboardOverviewDocument = gql`
    query DashboardOverview($startDate: DateTime!, $endDate: DateTime!, $countCustomerWhere: CustomerWhereInput) {
  countCustomer(where: $countCustomerWhere)
  aggregateReceivedPoint(_sum: {count: true}) {
    _sum {
      count
    }
  }
  countRedeemedPoint
  redeemedCouponByTypeCount {
    type
    count
  }
  receivedVisitBasedPointsPerDay(startDate: $startDate, endDate: $endDate) {
    date
    count
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DashboardOverviewGQL extends Apollo.Query<DashboardOverviewQuery, DashboardOverviewQueryVariables> {
    override document = DashboardOverviewDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const EmployeesDocument = gql`
    query Employees($where: EmployeeWhereInput, $orderBy: [EmployeeOrderByWithRelationInput!], $cursor: EmployeeWhereUniqueInput, $take: Int, $skip: Int, $distinct: [EmployeeScalarFieldEnum!]) {
  employees(
    where: $where
    orderBy: $orderBy
    cursor: $cursor
    take: $take
    skip: $skip
    distinct: $distinct
  ) {
    ...EmployeeFields
  }
}
    ${EmployeeFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class EmployeesGQL extends Apollo.Query<EmployeesQuery, EmployeesQueryVariables> {
    override document = EmployeesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PointSystemCheckDocument = gql`
    query PointSystemCheck {
  pointSystem {
    ...PointSystemCheckFields
  }
}
    ${PointSystemCheckFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class PointSystemCheckGQL extends Apollo.Query<PointSystemCheckQuery, PointSystemCheckQueryVariables> {
    override document = PointSystemCheckDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PointSystemDocument = gql`
    query PointSystem {
  pointSystem {
    ...PointSystemFields
  }
}
    ${PointSystemFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class PointSystemGQL extends Apollo.Query<PointSystemQuery, PointSystemQueryVariables> {
    override document = PointSystemDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PromotionPeriodsDocument = gql`
    query PromotionPeriods($where: PromotionPeriodWhereInput, $orderBy: [PromotionPeriodOrderByWithRelationInput!], $cursor: PromotionPeriodWhereUniqueInput, $take: Int, $skip: Int, $distinct: [PromotionPeriodScalarFieldEnum!]) {
  promotionPeriods(
    where: $where
    orderBy: $orderBy
    cursor: $cursor
    take: $take
    skip: $skip
    distinct: $distinct
  ) {
    ...PromotionPeriodFields
  }
}
    ${PromotionPeriodFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class PromotionPeriodsGQL extends Apollo.Query<PromotionPeriodsQuery, PromotionPeriodsQueryVariables> {
    override document = PromotionPeriodsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PromotionsDocument = gql`
    query Promotions($where: PromotionWhereInput, $orderBy: [PromotionOrderByWithRelationInput!], $cursor: PromotionWhereUniqueInput, $take: Int, $skip: Int, $distinct: [PromotionScalarFieldEnum!]) {
  promotions(
    where: $where
    orderBy: $orderBy
    cursor: $cursor
    take: $take
    skip: $skip
    distinct: $distinct
  ) {
    ...PromotionFields
  }
}
    ${PromotionFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class PromotionsGQL extends Apollo.Query<PromotionsQuery, PromotionsQueryVariables> {
    override document = PromotionsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PushNotificationHistoriesDocument = gql`
    query PushNotificationHistories($where: PushNotificationHistoryWhereInput, $orderBy: [PushNotificationHistoryOrderByWithRelationInput!], $cursor: PushNotificationHistoryWhereUniqueInput, $take: Int, $skip: Int, $distinct: [PushNotificationHistoryScalarFieldEnum!]) {
  pushNotificationHistories(
    where: $where
    cursor: $cursor
    distinct: $distinct
    orderBy: $orderBy
    skip: $skip
    take: $take
  ) {
    ...PushNotificationHistoryFields
  }
}
    ${PushNotificationHistoryFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class PushNotificationHistoriesGQL extends Apollo.Query<PushNotificationHistoriesQuery, PushNotificationHistoriesQueryVariables> {
    override document = PushNotificationHistoriesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PushNotificationsDocument = gql`
    query PushNotifications($where: PushNotificationWhereInput, $orderBy: [PushNotificationOrderByWithRelationInput!], $cursor: PushNotificationWhereUniqueInput, $take: Int, $skip: Int, $distinct: [PushNotificationScalarFieldEnum!]) {
  pushNotifications(
    where: $where
    cursor: $cursor
    distinct: $distinct
    orderBy: $orderBy
    skip: $skip
    take: $take
  ) {
    ...PushNotificationFields
  }
}
    ${PushNotificationFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class PushNotificationsGQL extends Apollo.Query<PushNotificationsQuery, PushNotificationsQueryVariables> {
    override document = PushNotificationsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ReceivedPointTrendDocument = gql`
    query ReceivedPointTrend($startDate: DateTime!, $endDate: DateTime!, $timezone: TimeZone!) {
  receivedPointTrend(
    startDate: $startDate
    endDate: $endDate
    timezone: $timezone
  ) {
    ...TrendMetricFields
  }
}
    ${TrendMetricFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ReceivedPointTrendGQL extends Apollo.Query<ReceivedPointTrendQuery, ReceivedPointTrendQueryVariables> {
    override document = ReceivedPointTrendDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ReceivedPointsDocument = gql`
    query ReceivedPoints($where: ReceivedPointWhereInput, $orderBy: [ReceivedPointOrderByWithRelationInput!], $cursor: ReceivedPointWhereUniqueInput, $take: Int, $skip: Int, $distinct: [ReceivedPointScalarFieldEnum!]) {
  receivedPoints(
    where: $where
    orderBy: $orderBy
    cursor: $cursor
    take: $take
    skip: $skip
    distinct: $distinct
  ) {
    ...ReceivedPointFields
  }
}
    ${ReceivedPointFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ReceivedPointsGQL extends Apollo.Query<ReceivedPointsQuery, ReceivedPointsQueryVariables> {
    override document = ReceivedPointsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RedeemedPointTrendDocument = gql`
    query RedeemedPointTrend($startDate: DateTime!, $endDate: DateTime!, $timezone: TimeZone!) {
  redeemedPointTrend(
    startDate: $startDate
    endDate: $endDate
    timezone: $timezone
  ) {
    ...TrendMetricFields
  }
}
    ${TrendMetricFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class RedeemedPointTrendGQL extends Apollo.Query<RedeemedPointTrendQuery, RedeemedPointTrendQueryVariables> {
    override document = RedeemedPointTrendDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RedeemedPointsDocument = gql`
    query RedeemedPoints($where: RedeemedPointWhereInput, $orderBy: [RedeemedPointOrderByWithRelationInput!], $cursor: RedeemedPointWhereUniqueInput, $take: Int, $skip: Int, $distinct: [RedeemedPointScalarFieldEnum!]) {
  redeemedPoints(
    where: $where
    orderBy: $orderBy
    cursor: $cursor
    take: $take
    skip: $skip
    distinct: $distinct
  ) {
    ...RedeemedPointFields
  }
}
    ${RedeemedPointFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class RedeemedPointsGQL extends Apollo.Query<RedeemedPointsQuery, RedeemedPointsQueryVariables> {
    override document = RedeemedPointsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RewardsChartDocument = gql`
    query RewardsChart($where: RewardWhereInput) {
  rewards(where: $where) {
    ...RewardsChartFields
  }
}
    ${RewardsChartFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class RewardsChartGQL extends Apollo.Query<RewardsChartQuery, RewardsChartQueryVariables> {
    override document = RewardsChartDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RewardsDocument = gql`
    query Rewards($where: RewardWhereInput, $orderBy: [RewardOrderByWithRelationInput!], $cursor: RewardWhereUniqueInput, $take: Int, $skip: Int, $distinct: [RewardScalarFieldEnum!]) {
  rewards(
    where: $where
    orderBy: $orderBy
    cursor: $cursor
    take: $take
    skip: $skip
    distinct: $distinct
  ) {
    ...RewardFields
  }
}
    ${RewardFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class RewardsGQL extends Apollo.Query<RewardsQuery, RewardsQueryVariables> {
    override document = RewardsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ValidateBaseConfigurationDocument = gql`
    query ValidateBaseConfiguration {
  calculationRules {
    ...CalculationRuleFields
  }
  pointSystem {
    ...PointSystemCheckFields
  }
  branchOffices {
    ...BranchOfficeCountFields
  }
}
    ${CalculationRuleFieldsFragmentDoc}
${PointSystemCheckFieldsFragmentDoc}
${BranchOfficeCountFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ValidateBaseConfigurationGQL extends Apollo.Query<ValidateBaseConfigurationQuery, ValidateBaseConfigurationQueryVariables> {
    override document = ValidateBaseConfigurationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }